import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import th from 'date-fns/locale/th';

registerLocale('th', th);

class Datepicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: ''
        }
    }

    getYear = (date) => {
        return date.getFullYear();
    }

    getMonth = (date) => {
        return date.getMonth();
    }

    setStartDate = (date) => {
        this.setState({ startDate: date })
    }

    formatDate = (date) => {
        if (date === '' || date === null) return '';
        var newDate = new Date(date);
        newDate.setFullYear(newDate.getFullYear() + 543);
        return ((newDate.getDate() < 10) ? '0' : '') + newDate.getDate() + '/' +
            (((newDate.getMonth() < 9) ? '0' : '') + (newDate.getMonth() + 1)) + '/' +
            newDate.getFullYear();
    }

    changeDate = (value) => {
        if (value && value.getFullYear() > (2015 + 543))
            value.setFullYear(value.getFullYear() - 543)
        if (value && !(value.getFullYear() < (new Date().getFullYear() + 25)))
            return;
        this.props.setDate(this.props.name, value)
    }

    render() {
        const years = Array(new Date().getFullYear() + 5 - 2016).fill(2016).map((x, i) => x + i);
        const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
        return (
            <DatePicker
                placeholderText={this.props.placeholderText}
                locale='th'
                className='form-control'
                dateFormat='dd/MM/yyyy'
                selected={this.props.dateData}
                value={this.formatDate(this.props.dateData)}
                onChange={date => this.changeDate(date)}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className='btn'>
                                {"<"}
                            </button>
                            <select
                                className='form-control flex-fill pl-1 pr-1 mr-2'
                                value={months[this.getMonth(date)]}
                                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                            >
                                {months.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                className='form-control flex-fill pl-1 pr-1'
                                value={this.getYear(date)}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map(option => (
                                    <option key={option} value={option}>
                                        {option + 543}
                                    </option>
                                ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className='btn'>
                                {">"}
                            </button>
                        </div>
                    )}
            />
        );
    }
};

export default Datepicker;