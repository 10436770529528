import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { checkMonth } from '../../../Utilities/Date';

class MyWindowPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            dataLoaded: [false, false, false, false, false, false, false],
            printed: false,
            data: []
        };
        this.containerEl = null;
        this.externalWindow = null;
    }

    componentDidMount() {
        console.log(window.location);
        this.externalWindow = window.open('');

        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.closeWindowPortal();
        });

        this.containerEl = this.externalWindow.document.createElement("div")
        this.externalWindow.document.body.appendChild(this.containerEl);

        var head = this.externalWindow.document.getElementsByTagName('head')[0];

        this.appendCSS(head, window.location.origin + '/assets/css/froala_editor.pkgd.min.css', 0);
        this.appendCSS(head, window.location.origin + "/assets/css/froala_editor.min.css", 1);
        this.appendCSS(head, "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css", 2);
        this.appendCSS(head, 'https://fonts.googleapis.com/css?family=Pridi:300&display=swap&subset=thai', 3);
        this.appendCSS(head, 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css', 4);
        this.appendCSS(head, window.location.origin + "/assets/css/pdf_landscape.css", 5);


        this.setState({ isOpened: true }, () => {
            console.log('eieiza');
            this.props.getReportEvaluation(this.props.Year, this.props.EvaluationTypeID)
                .then(result => {
                    console.log(result);
                    this.setState({
                        data: (result.data && result.data.GetReportOrganizationType6) ? result.data.GetReportOrganizationType6 : []
                    }, () => {
                        var newState = this.state.dataLoaded;
                        newState[6] = true;
                        this.setState({ dataLoaded: newState });
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    this.props.closeWindowPortal();
                    this.externalWindow.close();
                })
        });
    }

    componentWillUnmount() {
        this.props.closeWindowPortal();
        this.externalWindow.close();
    }

    componentDidUpdate() {
        if (this.state.dataLoaded.every(x => x === true) && !this.state.printed) {
            this.setState({ printed: true }, () => {
                setTimeout(() => this.externalWindow.print(), 500);
            })
        }
    }

    appendCSS = (head, link, index) => {
        var cssnode = this.externalWindow.document.createElement('link');

        cssnode.type = 'text/css';
        cssnode.rel = 'stylesheet';
        cssnode.href = link;

        head.appendChild(cssnode);

        cssnode.onload = this.onloadCSS(index)
    }

    onloadCSS = (index) => {
        console.log(index)
        var newState = this.state.dataLoaded;
        newState[index] = true;
        this.setState({ dataLoaded: newState });
    }

    renderHeader = () => {
        return (
            <div>
                {
                    (this.props.Year) ?
                        <div className='mb-3' align='center'>
                            <h5>สรุปตัวชี้วัดระดับสำนัก กอง ศูนย์ กลุ่ม</h5>
                            <h5>ประจำปีงบประมาน {this.props.Year + 543}</h5>
                        </div>
                        : null
                }
                <div className='content row table-header background-header'>
                    <div className='col-1 border-right d-flex align-items-center justify-content-center'>ที่</div>
                    <div className='col-3 border-right d-flex align-items-center justify-content-center'>ตัวชี้วัด</div>
                    <div className='col-3 p-0 border-right d-flex flex-column'>
                        <div className='pt-2 pb-2 border-bottom' align='center'>
                            ความเชื่อมโยง
                        </div>
                        <div className='row h-100' align='center'>
                            <div className='col-2 no-padding border-right sub-header'>ยุทธฯ</div>
                            <div className='col-2 no-padding border-right sub-header'>ม.44</div>
                            <div className='col-2 no-padding border-right sub-header'>ประเมินผู้บริหารองค์การ</div>
                            <div className='col-2 no-padding border-right sub-header'>แผนพัฒนาองค์การ</div>
                            <div className='col-2 no-padding border-right sub-header'>พรบ.งบประมาณ</div>
                            <div className='col-2 no-padding sub-header'>ภารกิจหลัก</div>
                        </div>
                    </div>
                    <div className='col-5 p-0 d-flex flex-column'>
                        <div className='pt-2 pb-2 border-bottom' align='center'>
                            การดำเนินงาน
                        </div>
                        <div className='row h-100' align='center'>
                            <div className='col-4 no-padding border-right sub-header'>เป้าหมาย (ขั้นสูง)</div>
                            <div className='col-4 no-padding border-right sub-header'>ผลการดำเนินงาน</div>
                            <div className='col-2 no-padding border-right sub-header'>ผ่าน</div>
                            <div className='col-2 no-padding sub-header'>ไม่ผ่าน</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderOrganization = () => {
        if (!this.state.data.length) return null;
        return (
            <>
                {
                    this.state.data.map((obj, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className='content background-hilight border-bottom'>
                                    <div className='section-title'>{obj.OrganizationName}</div>
                                </div>
                                {this.renderKPIs(obj.KPI)}
                            </React.Fragment>
                        )
                    })
                }
            </>
        );
    }

    renderKPIs = (obj, index) => {
        if (!obj) return null;
        return (
            obj.map((object, objectIndex) => {
                let bottomClass = (objectIndex === obj.length - 1) ? "border-bottom-1px" : 'border-bottom';
                return (
                    <React.Fragment key={index + '+' + objectIndex}>
                        <div className='content row no-padding no-border'>
                            <div className={'col-1 border-right border-left ' + bottomClass}>
                                {objectIndex + 1}
                            </div>
                            <div className={'col-3 border-right pl-4 ' + bottomClass}>
                                {object.KPIName}
                            </div>
                            <div className='col-3 row no-padding border-right'>
                                <div className={'col-2 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.PolicyOrganizationID === 1 ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className={'col-2 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.PolicyOrganizationID === 2 ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className={'col-2 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.PolicyOrganizationID === 3 ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className={'col-2 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.PolicyOrganizationID === 4 ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className={'col-2 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.PolicyOrganizationID === 5 ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className={'col-2 d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.PolicyOrganizationID === 6 ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                            </div>
                            <div className='col-5 row no-padding border-right'>
                                <div className={'col-4 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {
                                        (object.Unit === "ร้อยละ") ?
                                            (object.Unit) + " " + (object.Target ? object.Target : "")
                                            : (object.Target ? object.Target : "") + " " + (object.Unit || "")
                                    }
                                </div>
                                <div className={'col-4 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {
                                        (object.Unit === "ร้อยละ") ?
                                            (object.Unit) + " " + (object.Result ? object.Result : "")
                                            : (object.Result ? object.Result : "") + " " + (object.Unit || "")
                                    }
                                </div>
                                <div className={'col-2 border-right d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.Pass === true ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className={'col-2 d-flex justify-content-center align-items-center ' + bottomClass}>
                                    {object.Pass === false ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })

        )
    }

    render() {
        console.log(this.containerEl)
        if (!this.containerEl) {
            return null;
        }
        return ReactDOM.createPortal(
            (
                <>
                    {
                        (this.state.dataLoaded.every(x => x === true)) ?
                            <div className='p-2'>
                                <div className='' style={{ backgroundColor: 'white' }}>
                                    <div className='hide-print mb-4'>
                                        <button className='btn btn-outline-success' onClick={() => this.externalWindow.print()}>
                                            Export PDF
                                        </button>
                                    </div>
                                    <div className="border-bottom">
                                        {this.renderHeader()}
                                        {this.renderOrganization()}
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    <div className={"lds-ellipsis " + ((this.state.dataLoaded.every(x => x === true)) ? 'disabled' : '')}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={'loading-backdrop ' + ((this.state.dataLoaded.every(x => x === true)) ? 'disabled' : '')} />
                </>
            )
            , this.containerEl);
    }
}

export default MyWindowPortal;