import React from 'react';
import EvaluationManageTable from './PolicyManage_components/EvaluationManage_table';
import EvaluationManageAddModal from './PolicyManage_components/EvaluationManage_modal';
import EvaluationManageSchedule from './PolicyManage_components/EvaluationManage_schedule';
import EvaluationService from '../../Service/EvaluationService';
import KPIService from '../../Service/KPIManageService';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from '../../Redux/Action';
import Swal from 'sweetalert2';
import { renderYear } from '../../Utilities/Date';
import ScheduleService from '../../Service/ScheduleService';
import jsPDF from 'jspdf';
import html2image from 'html-to-image';
import Pdf from './PolicyManage_components/pdf';
import PdfType6 from './PolicyManage_components/pdf_type6';
import ReportService from '../../Service/ReportService';
import ExcelModal from './PolicyManage_components/excelModal';

class EvaluationManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Schedule: [],
            EvaluationData: [],
            currentEvaluationData: [],
            EvaluationDataCount: 0,
            EvaluationType: [],
            Policy: [],
            PolicyOrganization: [],
            currentPage: 1,
            limit: 5,
            EvaluationCurrentData: {},
            currentEvaluation: null,
            showAddEvaluationModal: false,
            showScheduleModal: false,
            searchYear: 0,
            searchEvaluation: 0,
            searched: {
                isSearched: false,
                searchedYear: 0,
                searchedEvaluation: 0
            },
            showPdf: false,
            pdfYear: 0,
            pdfEvaluationTypeID: 0,
            showExcel: false,
            excelYear: 0,
            excelEvaluationTypeID: 0,
            excelType: null
        }
    }

    componentDidMount() {
        this.getEvaluationType();
        this.getPolicyAll();
        this.getSchedule();
        this.searchEvaluation(null, null);
    }

    searchEvaluation = (Year, EvaluationTypeID) => {
        console.log(Year);
        console.log(this.state.searched);
        this.props.dispatch(showLoading());
        var searchService;
        if (this.state.searched.isSearched === true)
            searchService = EvaluationService.searchEvaluation(this.state.searched.searchedYear, this.state.searched.searchedEvaluation);
        else
            searchService = EvaluationService.searchEvaluation(Year, EvaluationTypeID);
        searchService
            .then(result => {
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                this.setState({
                    EvaluationData: result.data.SearchEvaluation,
                    EvaluationDataCount: (result.data.SearchEvaluation.length || 0)
                }, () => {
                    console.log(this.state.EvaluationData, this.state.EvaluationDataCount);
                })
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    searchEvaluationAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchEvaluation(this.state.searched.searchedYear, this.state.searched.searchedEvaluation)
        }
        else {
            this.searchEvaluation(null, null)
        }
    }

    getSchedule = () => {
        ScheduleService.searchSchedule()
            .then(result => {
                if (result.errors) return;
                this.setState({ Schedule: result.data.SearchSchedule });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getEvaluationType = () => {
        EvaluationService.getEvaluationType()
            .then(result => {
                if (result.errors) {
                    return;
                }
                this.setState({ EvaluationType: result.data.GetEvaluationType })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getPolicyAll = () => {
        EvaluationService.getPolicyAll()
            .then(result => {
                if (result.errors) {
                    return;
                }
                console.log(result);
                this.setState({
                    Policy: result.data.GetPolicyAll.Policy || [],
                    PolicyOrganization: result.data.GetPolicyAll.PolicyOrganization || []
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getListStrategy = (Year, EvaluationTypeID) => {
        return KPIService.getListStrategy(Year, EvaluationTypeID);
    }

    getListObjective = (StrategyID, Year, EvaluationTypeID) => {
        return KPIService.getListObjective(StrategyID, Year, EvaluationTypeID);
    }

    getReportEvaluation = (Year, EvaluationTypeID) => {
        if (EvaluationTypeID === 6) return ReportService.GetReportOrganizationType6(Year);
        else return ReportService.getReportResultByYear(EvaluationTypeID, Year);
    }

    getExcelCSV = (EvaluationTypeID, Year, ListKPI, Type) => {
        return ReportService.getExcelCSV(EvaluationTypeID, Year, ListKPI, Type)
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.getEvaluationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
        else
            this.setState({ [name]: value }, () => {
                this.getEvaluationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
    }

    getEvaluationDataTable = (Offset, Limit) => {
        var newData = [];
        var data = [...this.state.EvaluationData];
        if (data.length === 0) return
        for (var i = Offset; i < (Offset + Limit); i++) {
            newData.push(data[i]);
        }
        console.log(newData)
        return newData;
    }

    getYear = () => {
        return renderYear('EvaluationManage');
    }

    checkEvaluationType = (TypeID) => {
        TypeID = parseInt(TypeID);
        for (var i = 0; i < this.state.EvaluationType.length; i++) {
            if (TypeID === parseInt(this.state.EvaluationType[i].EvaluationTypeID)) {
                return this.state.EvaluationType[i].EvaluationTypeName;
            }
        }
    }

    hideModal = (callback, scheduleModal) => {
        if (!scheduleModal) {
            (this.state.showAddEvaluationModal === false) ?
                this.setState({
                    showAddEvaluationModal: true
                })
                : this.setState({
                    showAddEvaluationModal: false,
                    currentEvaluation: null
                }, () => {
                    if (typeof callback === 'function')
                        callback()
                });
        }
        else {
            (this.state.showScheduleModal === false) ?
                this.setState({
                    showScheduleModal: true
                })
                : this.setState({
                    showScheduleModal: false,
                    currentEvaluation: null
                }, () => {
                    if (typeof callback === 'function')
                        callback()
                });
        }
    }

    onChangeSearch = (name, value) => {
        this.setState({ [name]: value }, () => {
            console.log(this.state[name])
        });
    }

    clearSearch = () => {
        this.setState({
            searchYear: 0,
            searchEvaluation: 0,
            searched: {
                isSearched: false,
                Year: 0,
                EvaluationType: 0
            }
        }, () => {
            this.searchEvaluation(null, null);
        });
    }

    onClickSearch = () => {
        if (this.state.searchYear === 0 && this.state.searchEvaluation === 0)
            this.setState({
                searchYear: 0,
                searchEvaluation: 0,
                searched: {
                    isSearched: false,
                    searchedYear: 0,
                    searchedEvaluation: 0
                }
            }, () => {
                this.searchEvaluation(this.state.searchYear, this.state.searchEvaluation);
            })
        else
            this.setState({
                //searchYear: 0,
                //searchEvaluation: 0,
                currentPage: 1,
                searched: {
                    isSearched: true,
                    searchedYear: this.state.searchYear,
                    searchedEvaluation: this.state.searchEvaluation
                }
            }, () => {
                this.searchEvaluation(this.state.searchYear, this.state.searchEvaluation);
            });
    }

    changeCurrentEvaluation = (Year, EvaluationTypeID, scheduleModal) => {
        var newCurrentEvaluation = {
            Year: Year,
            EvaluationTypeID: EvaluationTypeID
        }
        this.setState({
            currentEvaluation: newCurrentEvaluation
        }, () => {
            console.log(this.state.currentEvaluation);
            this.hideModal(null, scheduleModal);
        })
    }

    getFormDetailModal = (currentEvaluation) => {
        this.props.dispatch(showLoading());
        return EvaluationService.getEvaluationDetail(currentEvaluation.Year, currentEvaluation.EvaluationTypeID)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    getListKPIAll = (Year, EvaluationType, HasForm) => {
        this.props.dispatch(showLoading());
        return EvaluationService.getListKPIAll(Year, EvaluationType, HasForm)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    updateSpecialSchedule = (MainKPIs, PeriodStart, PeriodEnd) => {
        this.props.dispatch(showLoading());
        return ScheduleService.updateSpecialSchedule(MainKPIs, PeriodStart, PeriodEnd)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    createEvaluation = (Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID) => {
        this.props.dispatch(showLoading());
        return EvaluationService.createEvaluation(Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID)
            .then(result => {
                this.props.dispatch(hideLoading());
                console.log(result);
                if (!result.errors) {
                    this.searchEvaluationAfterSetState();
                }
                return result;
            })
            .catch(err => {
                console.log(err);
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    updateEvaluation = (Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, DelMainKPIs, SupportID) => {
        this.props.dispatch(showLoading());
        if (DelMainKPIs.length !== 0) {
            return EvaluationService.deleteMainKPI(DelMainKPIs, EvaluationTypeID)
                .then(res => {
                    console.log(res)
                    if (res.errors) {
                        this.props.dispatch(hideLoading());
                        return res;
                    }
                    return EvaluationService.updateEvaluation(Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID)
                        .then(result => {
                            if (!result.errors) {
                                this.searchEvaluationAfterSetState();
                            }
                            this.props.dispatch(hideLoading());
                            return result;
                        })
                        .catch(err => {
                            this.props.dispatch(hideLoading());
                            return Promise.reject(err);
                        });
                })
                .catch(err => {
                    this.props.dispatch(hideLoading());
                    console.log(err);
                    return Promise.reject(err);
                })
        }
        else {
            return EvaluationService.updateEvaluation(Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID)
                .then(result => {
                    if (!result.errors) {
                        this.searchEvaluationAfterSetState();
                    }
                    this.props.dispatch(hideLoading());
                    return result;
                })
                .catch(err => {
                    this.props.dispatch(hideLoading());
                    return Promise.reject(err);
                });
        }
    }

    deleteEvaluation = (Year, EvaluationTypeID) => {
        Swal.fire({
            title: 'ลบการประเมิน',
            text: 'ต้องการลบการประเมินนี้หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    EvaluationService.deleteEvaluation(Year, EvaluationTypeID)
                        .then(result => {
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                                return;
                            }
                            Swal.fire({
                                title: 'ลบข้อมูลสำเร็จ',
                                type: 'success'
                            })
                            this.searchEvaluationAfterSetState();
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                type: 'error'
                            });
                        });
            });
    }

    convertToPDF = (el) => {
        // html2canvas(el, { letterRendering: true })
        //     .then(result => {
        //         const imgData = result.toDataURL('image/png');
        //         const pdf = new jsPDF({
        //             orientation: 'Landscape'
        //         });
        //         const imgProps = pdf.getImageProperties(imgData);
        //         const pdfWidth = pdf.internal.pageSize.getWidth();
        //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //         pdf.addImage(result, 'PNG', 0, 0, pdfWidth, pdfHeight);
        //         pdf.output("dataurlnewwindow")
        //     })

        html2image.toPng(el)
            .then(result => {
                const imgData = result
                const pdf = new jsPDF({
                    orientation: 'Landscape'
                });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                var canvas = document.createElement('canvas');
                canvas.width = imgProps.width;
                canvas.height = imgProps.height;
                var ctx = canvas.getContext('2d');
                var img = document.createElement('img');
                img.src = imgData;
                img.onload = () => {
                    ctx.drawImage(img, 0, 0);
                    pdf.addImage(canvas, 'jpeg', 0, 0, pdfWidth, pdfHeight);
                    pdf.output("dataurlnewwindow")
                }
            })
    }

    openPdf = (Year, EvaluationTypeID) => {
        this.setState({
            showPdf: false,
            pdfYear: 0,
            pdfEvaluationTypeID: 0
        }, () => {
            this.setState({
                showPdf: true,
                pdfYear: Year,
                pdfEvaluationTypeID: EvaluationTypeID
            });
        });
    }

    checkTypePdf = () => {
        switch (this.state.pdfEvaluationTypeID) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5: {
                return <Pdf
                    closeWindowPortal={this.closePdf}
                    getReportEvaluation={this.getReportEvaluation}
                    Year={this.state.pdfYear}
                    EvaluationTypeID={this.state.pdfEvaluationTypeID}
                />
            }
            case 6: {
                return <PdfType6
                    closeWindowPortal={this.closePdf}
                    getReportEvaluation={this.getReportEvaluation}
                    Year={this.state.pdfYear}
                    EvaluationTypeID={this.state.pdfEvaluationTypeID}
                />
            }
            default: return;
        }
    }

    closePdf = () => {
        this.setState({
            showPdf: false,
            pdfYear: 0,
            pdfEvaluationTypeID: 0
        });
    }

    openExcel = (Year, EvaluationTypeID, Type) => {
        this.setState({
            showExcel: true,
            excelYear: Year,
            excelEvaluationTypeID: EvaluationTypeID,
            excelType: Type
        });
    }

    closeExcel = (callback) => {
        this.setState({
            showExcel: false,
            excelYear: 0,
            excelEvaluationTypeID: 0,
            excelType: null
        }, () => {
            if (typeof callback === 'function') callback();
        });
    }

    render() {
        return (
            <div id='KPIManage'>
                <div className='custom-block mb-4'>
                    <form className='row align-items-end'>
                        <div className='form-group col-12 col-md-6'>
                            <label className='form-label'>ปีงบประมาณ</label>
                            <select
                                className='form-control'
                                name='searchYear'
                                value={this.state.searchYear}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {this.getYear()}
                            </select>
                        </div>
                        <div className='form-group col-12 col-md-6'>
                            <label className='form-label'>ประเภทการประเมิน</label>
                            <select
                                className='form-control'
                                name='searchEvaluation'
                                value={this.state.searchEvaluation}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.EvaluationType.map((obj, index) => {
                                        return (
                                            <option value={obj.EvaluationTypeID} key={'EvaluationType-option' + index}>{obj.EvaluationTypeName}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    </form>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.onClickSearch}>ค้นหา</button>
                    </div>
                </div>
                <div>
                    <EvaluationManageTable
                        data={this.getEvaluationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit)}
                        currentPage={this.state.currentPage}
                        limit={this.state.limit}
                        onChangeTableState={this.onChangeTableState}
                        searched={this.state.searched}
                        dataCount={this.state.EvaluationDataCount}
                        clickAddEvaluation={this.hideModal}
                        clickDeleteEvaluation={this.deleteEvaluation}
                        changeCurrentEvaluation={this.changeCurrentEvaluation}
                        checkEvaluationType={this.checkEvaluationType}
                        openPdf={this.openPdf}
                        openExcel={this.openExcel}
                    />
                </div>
                <EvaluationManageAddModal
                    data={this.state.currentEvaluation}
                    show={this.state.showAddEvaluationModal}
                    hide={this.hideModal}
                    evaluationType={this.state.EvaluationType}
                    getFormDetailModal={() => this.getFormDetailModal(this.state.currentEvaluation)}
                    createEvaluation={this.createEvaluation}
                    updateEvaluation={this.updateEvaluation}
                    Schedule={this.state.Schedule}
                    getListStrategy={this.getListStrategy}
                    Policy={this.state.Policy}
                    PolicyOrganization={this.state.PolicyOrganization}
                />
                <EvaluationManageSchedule
                    show={this.state.showScheduleModal}
                    hide={this.hideModal}
                    currentEvaluation={this.state.currentEvaluation}
                    getListKPIAll={this.getListKPIAll}
                    updateSpecialSchedule={this.updateSpecialSchedule}
                    getListStrategy={this.getListStrategy}
                    getListObjective={this.getListObjective}
                />

                <ExcelModal
                    show={this.state.showExcel}
                    hide={this.closeExcel}
                    currentEvaluation={this.state.excelEvaluationTypeID}
                    currentYear={this.state.excelYear}
                    type={this.state.excelType}
                    getListKPIAll={this.getListKPIAll}
                    getListStrategy={this.getListStrategy}
                    getListObjective={this.getListObjective}
                    getExcelCSV={this.getExcelCSV}
                />

                {
                    (this.state.showPdf) ?
                        this.checkTypePdf()
                        : null
                }

            </div>
        );
    }
}

export default connect(null)(EvaluationManage);