
import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';

/// onwr
// const key = 'yDC5hG4C2B9B6B5A5gwqwG-7kdpoegH4dgdvC2B1F1G1B4E4H4B1A8C7=='

/// onwr
// const key = '7MD3aC3D3B6C4A3A1xjhpzB9mdF6yfsF4I4A11C8C2B5C4B1E3H3==';

/// kl
// const key = 'yDC5hG4C2B9B6B5A5gwqwG-7kdpoegH4dgdvC2B1F1G1B4E4H4B1A8C7==';

const key = (window.location.hostname.includes("onwr.go.th")) ?
    "zEG4iH4C7D7D5B4F6g1rphrC5elF4qnH-8D1B3D3D2E6C1G1B4F4C3==" :
    (
        (window.location.hostname.includes("onwr.cloud")) ?
            "0BA3jB11A8B5F5C4A4aqqwsE5f1hjsctA2B2C2C1C5G1F1F1A3A9=="
            : "yDC5hG4C2B9B6B5A5gwqwG-7kdpoegH4dgdvC2B1F1G1B4E4H4B1A8C7=="
    )

export default (props) => {
    return (
        <FroalaEditor
            model={(props.model || '')}
            onModelChange={e => props.onModelChange(props.onchange1, e, props.onchange2, props.onchange3)}
            config={{
                key: key
            }}
        />
    )
}