import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { renderYear } from '../../../Utilities/Date';

class KPIManageAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Year: 0,
            EvaluationTypeID: 0,
            Evaluation: [],
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    resetState = () => {
        this.setState({
            Year: 0,
            EvaluationTypeID: 0,
            Evaluation: [],
            canUpdate: false
        });
    }

    onHideModal = () => {
        Swal.fire({
            title: 'ยกเลิกการสร้างตัวชี้วัด',
            text: 'ต้องการยกเลิกการสร้างตัวชี้วัดหรือไม่ ?',
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    this.props.hide(this.resetState, 0)
            })
    }

    onEnterModal = () => {
        this.setState({
            canUpdate: true
        });
    }

    checkNextStep = () => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        if (this.state.Year === 0) {
            title = 'กรุณาระบุปีงบประมาณ';
            text = 'กรุณาระบุปีงบประมาณ';
            type = 'warning';
        }
        else if (this.state.EvaluationTypeID === 0) {
            title = 'กรุณาระบุประเภทการประเมิน';
            text = 'กรุณาระบุประเภทการประเมิน';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    nextStep = () => {
        if (this.checkNextStep())
            this.props.hide(() => {
                this.setState({
                    canUpdate: true
                }, () => {
                    var Year = this.state.Year;
                    var EvaluationTypeID = this.state.EvaluationTypeID;
                    this.props.changeCreateKPI(Year, EvaluationTypeID);
                    this.props.hide(
                        null,
                        (
                            (EvaluationTypeID != 5) ?
                                3
                                : EvaluationTypeID
                        )
                    );
                    this.resetState();
                })
            }, 0)
    }

    onChangeContent = (name, value) => {
        this.setState({ [name]: value });
        if (name === 'Year') {
            if (value === 0) {
                this.setState({ Evaluation: [] });
                return;
            }
            this.props.getEvaluationByYear(value, null)
                .then(result => {
                    if (result.errors) {
                        return;
                    }
                    result = result.data.SearchEvaluation;
                    this.setState({
                        EvaluationTypeID: 0,
                        Evaluation: [...result]
                    });
                })
                .catch(err => {
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState, 0);
                    })
                })
        }
    }

    getYear = () => {
        return renderYear('KPIManage-addModal');
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='md' onEnter={this.onEnterModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            เพิ่มตัวชี้วัด
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row form-group'>
                            <div className='col-6'>
                                <label className='form-label'>ปีงบประมาณ</label>
                                <select
                                    className='form-control'
                                    name='Year'
                                    value={this.state.Year}
                                    onChange={e => this.onChangeContent(e.target.name, parseInt(e.target.value))}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    {this.getYear()}
                                </select>
                            </div>
                            <div className='col-6'>
                                <label className='form-label'>ประเภทการประเมิน</label>
                                <select
                                    className='form-control'
                                    value={this.state.EvaluationTypeID}
                                    name='EvaluationTypeID'
                                    disabled={(this.state.Year === 0) ? true : false}
                                    onChange={e => this.onChangeContent(e.target.name, parseInt(e.target.value))}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    {
                                        this.state.Evaluation.map((obj, index) => {
                                            return (
                                                <option key={'KPIManage-addModal-evaluation-option' + index} value={obj.EvaluationTypeID}>
                                                    {obj.EvaluationTypeName}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button className='btn btn-outline-primary' onClick={this.nextStep}>
                                ต่อไป
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default KPIManageAddModal;