import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

class UserInfoPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canUpdate: false,
            showPassword: [false, false, false],
            OldPassword: '',
            NewPassword: '',
            NewPasswordConfirm: ''
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        this.props.hide(this.resetState);
    }

    onEnterModal = () => {
        this.setState({ canUpdate: true });
    }

    resetState = () => {
        this.setState({
            canUpdate: false,
            showPassword: [false, false, false],
            OldPassword: '',
            NewPassword: '',
            NewPasswordConfirm: ''
        })
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    checkUpdate = () => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        if (this.state.OldPassword === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกรหัสผ่านเดิม';
            type = 'warning';
        }
        else if (this.state.NewPassword === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกรหัสผ่านใหม่';
            type = 'warning';
        }
        else if (this.state.NewPasswordConfirm === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกยืนยันรหัสผ่านใหม่';
            type = 'warning';
        }
        else if (this.state.NewPassword.length < 8) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร';
            type = 'warning';
        }
        else if (this.state.NewPasswordConfirm !== this.state.NewPassword) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณายืนยันรหัสผ่านใหม่ให้ถูกต้อง';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (!this.checkUpdate())
            return;
        this.props.updatePassword(this.state.NewPassword, this.state.OldPassword)
            .then(result => {
                console.log(result)
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                Swal.fire({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    type: 'success'
                })
                this.props.hide(this.resetState);
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='lg' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            แก้ไขรหัสผ่าน
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mt-3'>
                            <div className='row'>
                                <div className='col-1' />
                                <div className='col-4'>
                                    <label className='form-label'>รหัสผ่านเดิม</label>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex'>
                                        <div className='flex-fill'>
                                            <input
                                                value={this.state.OldPassword}
                                                className='form-control'
                                                name='OldPassword'
                                                placeholder='รหัสผ่านเดิม'
                                                onChange={e => this.onChange(e.target.name, e.target.value)}
                                                type={(this.state.showPassword[0]) ? 'text' : 'password'}
                                            />
                                        </div>
                                        <button
                                            className='btn btn-outline-secondary eye-button'
                                            onClick={() => {
                                                var newShow = [...this.state.showPassword];
                                                newShow[0] = !(newShow[0]);
                                                this.setState({ showPassword: newShow });
                                            }}
                                        >
                                            <i className={'fa fa-eye' + ((this.state.showPassword[0]) ? '-slash' : '')}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className='mt-3'>
                            <div className='row'>
                                <div className='col-1' />
                                <div className='col-4'>
                                    <label className='form-label'>รหัสผ่านใหม่</label>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex'>
                                        <div className='flex-fill'>
                                            <input
                                                value={this.state.NewPassword}
                                                className='form-control'
                                                name='NewPassword'
                                                placeholder='รหัสผ่านใหม่'
                                                onChange={e => this.onChange(e.target.name, e.target.value)}
                                                type={(this.state.showPassword[1]) ? 'text' : 'password'}
                                            />
                                        </div>
                                        <button
                                            className='btn btn-outline-secondary eye-button'
                                            onClick={() => {
                                                var newShow = [...this.state.showPassword];
                                                newShow[1] = !(newShow[1]);
                                                this.setState({ showPassword: newShow });
                                            }}
                                        >
                                            <i className={'fa fa-eye' + ((this.state.showPassword[1]) ? '-slash' : '')}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className='mt-3'>
                            <div className='row'>
                                <div className='col-1' />
                                <div className='col-4'>
                                    <label className='form-label'>ยืนยันรหัสผ่านใหม่</label>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex'>
                                        <div className='flex-fill'>
                                            <input
                                                value={this.state.NewPasswordConfirm}
                                                className='form-control'
                                                name='NewPasswordConfirm'
                                                placeholder='ยืนยันรหัสผ่านใหม่'
                                                onChange={e => this.onChange(e.target.name, e.target.value)}
                                                type={(this.state.showPassword[2]) ? 'text' : 'password'}
                                            />
                                        </div>
                                        <button
                                            className='btn btn-outline-secondary eye-button'
                                            onClick={() => {
                                                var newShow = [...this.state.showPassword];
                                                newShow[2] = !(newShow[2]);
                                                this.setState({ showPassword: newShow });
                                            }}
                                        >
                                            <i className={'fa fa-eye' + ((this.state.showPassword[2]) ? '-slash' : '')}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={this.onSave}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default UserInfoPasswordModal;
