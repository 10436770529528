import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

class AutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: null,
            selected: {},
            data: []
        }
        console.log(this.props)
    }

    componentDidMount() {
        this.getData(null);
    }

    getData = (value) => {
        clearTimeout(this.state.timeout);
        var newTimeout = setTimeout(() => {
            this.props.getData(value)
                .then(result => {
                    console.log(result);
                    result = (this.props.getDataName) ? result.data[this.props.getDataName] : result.data['Search' + this.props.dataName];
                    if (!result) return;
                    if (this.props.listName)
                        result = result[this.props.listName];
                    if (result != null) {
                        this.setState({
                            data: result
                        });
                    }
                    else {
                        this.setState({
                            data: []
                        });
                    }
                })
        }, 500);
        this.setState({
            timeout: newTimeout
        })

    }

    onChangeSelect = (value) => {
        if (value === null)
            this.props.onChangeSelect(this.props.name, {}, this.props.objectType);
        else
            this.props.onChangeSelect(this.props.name, value, this.props.objectType);
    }

    render() {
        return (
            <>
                <Autocomplete
                    options={this.state.data}
                    getOptionLabel={option => (option[this.props.dataName + 'Name'] || '')}
                    onChange={(e, value) => this.onChangeSelect(value)}
                    value={this.props.selectValue}
                    noOptionsText='ไม่มีผลการค้นหา...'
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                onChange={(this.props.searchOnChange) ? e => this.getData(e.target.value) : null}
                                placeholder={this.props.labelName}
                                className='form-control'
                                variant="outlined"
                                fullWidth
                            />
                        )
                    }
                    }
                />
            </>
        )
    }
}

export default AutoComplete;