import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Autocomplete from '../../../Utilities/Autocomplete';

class PersonManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PersonName: '',
            Phone: '',
            Remark: '',
            searchOrganization: {},
            Organization: {},
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = (this.props.currentPersonID) ? 'ยกเลิกการแก้ไขบุคคล' : 'ยกเลิกการเพิ่มบุคคล'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })

    }

    onEnterModal = () => {
        if (this.props.currentPersonID) {
            var result = this.props.getPersonDetail(this.props.currentPersonID);
            this.setState({
                PersonName: result.PersonName,
                PersonID: result.PersonID,
                Phone: result.Phone,
                Remark: result.Remark,
                Organization: {
                    OrganizationID: result.OrganizationID,
                    OrganizationName: result.OrganizationName
                },
                canUpdate: true
            });
        }
        else {
            this.setState({ canUpdate: true });
        }
    }

    checkUpdate = () => {
        var title = '';
        var text = '';
        var type = null;
        if (this.state.PersonName === '' || this.state.PersonName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อบุคคล';
            type = 'warning';
        }
        if (!this.state.Organization.OrganizationID || this.state.Organization.OrganizationID === undefined) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกระบุหน่วยงาน';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (!this.checkUpdate()) {
            return;
        }
        else {
            var PersonService;
            if (this.props.currentPersonID) {
                PersonService = this.props.updatePerson(
                    this.props.currentPersonID,
                    this.state.PersonName,
                    this.state.Organization.OrganizationID,
                    this.state.Phone,
                    this.state.Remark
                );
            }
            else {
                PersonService = this.props.createPerson(
                    this.state.PersonName,
                    this.state.Organization.OrganizationID,
                    this.state.Phone,
                    this.state.Remark
                );
            }

            PersonService
                .then(result => {
                    console.log(result);
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                        if (this.props.currentPersonID === null)
                            this.props.hide(this.resetState);
                    }
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                })
        }
    }

    resetState = () => {
        this.setState({
            PersonName: '',
            Phone: '',
            Remark: '',
            Organization: {},
            canUpdate: false
        })
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='lg' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {(this.props.currentPersonID === null) ? 'เพิ่มบุคคล' : 'แก้ไขบุคคล'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>ชื่อบุคคล</label>
                            <input
                                name='PersonName'
                                className='form-control'
                                placeholder='ชื่อบุคคล'
                                value={(this.state.PersonName || '')}
                                onChange={e => this.onChange(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>คำอธิบาย</label>
                            <input
                                name='Remark'
                                className='form-control'
                                placeholder='คำอธิบาย'
                                value={(this.state.Remark || '')}
                                onChange={e => this.onChange(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>โทรศัพท์</label>
                            <input
                                name='Phone'
                                className='form-control'
                                placeholder='โทรศัพท์'
                                value={(this.state.Phone || '')}
                                onChange={e => this.onChange(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i><label className='form-label'>หน่วยงาน</label>
                            <div className='text-primary mb-3 pt-2 custom-block '>
                                หน่วยงานปัจจุบัน : {this.state.Organization.OrganizationName}
                                {
                                    (this.state.Organization.OrganizationID) ?
                                        <span
                                            className='delete-badge badge badge-danger border border-danger ml-2'
                                            onClick={() => this.onChange('Organization', {})}
                                        >
                                            <i className='fa fa-times' />
                                        </span>
                                        : null
                                }
                            </div>
                            <div className='d-flex'>
                                <div className='flex-fill'>
                                    <Autocomplete
                                        getData={this.props.searchOrganization}
                                        name='searchOrganization'
                                        labelName='หน่วยงาน'
                                        dataName='Organization'
                                        onChangeSelect={this.onChange}
                                        selectValue={this.state.searchOrganization}
                                    />
                                </div>
                                <button
                                    className='btn btn-outline-primary ml-2'
                                    onClick={() => this.onChange('Organization', this.state.searchOrganization)}
                                >
                                    เลือก
                                </button>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={this.onSave}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default PersonManageModal;
