import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';

class PersonManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWhatsNew: false
        }
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.props.hide} onEnter={this.onEnterModal} size='md'>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            เวอร์ชัน
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (this.props.Version && this.props.Version.length) ?
                                <>
                                    <div className=''>
                                        เวอร์ชันปัจจุบัน {'v' + this.props.Version[0].VersionName}
                                    </div>
                                    <div
                                        className='p-3 position-relative noselect'
                                        onClick={() => this.setState({ showWhatsNew: !(this.state.showWhatsNew) })}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        What's New
                                    <i className={'pt-1 text-secondary fa fa-chevron-left pr-1 ' + ((this.state.showWhatsNew) ? 'hide' : '')} />
                                        <i className={'pt-1 text-secondary fa fa-chevron-down ' + ((this.state.showWhatsNew) ? '' : 'hide')} />
                                    </div>
                                    <Collapse in={this.state.showWhatsNew}>
                                        <div className='ml-4'>
                                            {
                                                this.props.Version[0].VersionDescription.map((obj, index) => {
                                                    return (
                                                        <div key={'VersionDescroption' + index}>{obj}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Collapse>
                                    <div>

                                    </div>
                                    {/* <div 
                                    className='pl-3 d-inline-block' 
                                    style={{ cursor: 'pointer' }}
                                    >Version History</div> */}
                                </>
                                : null

                        }
                    </Modal.Body>

                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default PersonManageModal;
