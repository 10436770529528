import Axios from './BaseService';

class RoleService {

    searchRole() {
        const SEARCHROLE = `
            query SearchRole{
                SearchRole{
                    RoleID
                    RoleName
                    level
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHROLE,
            variables: {},
            errorPolicy: 'all'
        });
    }
}

export default new RoleService();