import React from 'react';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canUpdate: false,
            Email: '',
            loading: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onEnterModal = () => {
        this.setState({
            canUpdate: true
        });
    }

    onHideModal = () => {
        this.props.hide(this.resetState);
    }

    resetState = () => {
        this.setState({
            canUpdate: false,
            Email: '',
            loading: false
        })
    }

    checkUpdate = () => {
        var title = '';
        var text = '';
        var type = null;
        if (!this.state.Email) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุอีเมลหรือชื่อผู้ใช้งาน';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (!this.checkUpdate()) return;
        this.setState({ loading: true }, () => {
            this.props.forgetPassword(this.state.Email)
                .then(result => {
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                    }
                    if (!result.data.Success) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'เกิดข้อผิดพลาดระหว่างการส่งอีเมล',
                            type: 'error'
                        });
                    }
                    else {
                        Swal.fire({
                            title: '',
                            text: 'ระบบได้ทำการส่งอีเมลไปยัง ' + result.data.Email + ' กรุณาตรวจสอบกล่องข้อความของท่าน',
                            type: 'success'
                        });
                    }
                    this.onHideModal();
                })
                .catch(err => {
                    this.setState({ loading: false });
                    console.log(err);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                })
        });
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={(this.state.loading) ? null : this.onHideModal} size='md' onEnter={this.onEnterModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        ลืมรหัสผ่าน
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='form-label'>Email หรือ ชื่อผู้ใช้งาน :</label>
                    <input
                        className='form-control'
                        value={this.state.Email}
                        onChange={e => this.onChange('Email', e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className='float-right'>
                        {
                            (this.state.loading) ?
                                <button className='btn btn-outline-primary' disabled>
                                    <i className='fa fa-circle-o-notch fa-spin mr-2' />
                                    กำลังทำรายการ
                                </button>
                                :
                                <>
                                    <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                                    <button className='btn btn-outline-primary' onClick={this.onSave}>ถัดไป</button>
                                </>
                        }
                    </div>

                </Modal.Footer>
            </Modal>
        )
    }

}

export default ForgetPassword