import React from 'react';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from '../../Redux/Action';
import ScheduleTable from './Schedule_component/Schedule_table';
import ScheduleService from '../../Service/ScheduleService';
import ScheduleModal from './Schedule_component/Schedule_modal';
import Swal from 'sweetalert2';

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentPage: 1,
            limit: 5,
            data: [],
            dataCount: 0,
            currentScheduleID: null,
            searchProfileName: '',
            searchMode: 0,
            searched: {
                isSearched: false,
                searchedProfileName: null,
                searchedMode: null
            }
        }
    }

    componentDidMount() {
        this.searchSchedule();
    }

    searchSchedule = (ProfileName, Mode) => {
        this.props.dispatch(showLoading());
        ScheduleService.searchSchedule(ProfileName, Mode)
            .then(result => {
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                result = result.data.SearchSchedule;
                this.setState({
                    data: result,
                    dataCount: result.length
                });
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    searchScheduleAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchSchedule(
                this.state.searched.searchedProfileName,
                this.state.searched.searchedMode
            )
        }
        else {
            this.searchSchedule(
                null,
                null
            )
        }
    }

    getScheduleDataTable = (Offset, Limit) => {
        var newData = [];
        var data = [...this.state.data];
        if (data.length === 0) return [];
        for (var i = Offset; i < (Offset + Limit); i++) {
            newData.push(data[i]);
        }
        return newData;
    }

    getScheduleDetail = (id) => {
        for (var i of this.state.data) {
            if (i.ScheduleID === id)
                return i;
        }
    }

    createSchedule = (ScheduleName, Mode, DayOfWeekStart, DayOfWeekEnd, WeekOfMonthStart, WeekOfMonthEnd) => {
        this.props.dispatch(showLoading());
        return ScheduleService.createSchedule(ScheduleName, Mode, DayOfWeekStart, DayOfWeekEnd, WeekOfMonthStart, WeekOfMonthEnd)
            .then(res => {
                this.props.dispatch(hideLoading());
                this.searchScheduleAfterSetState()
                return res;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    updateSchedule = (ScheduleID, ScheduleName, Mode, DayOfWeekStart, DayOfWeekEnd, WeekOfMonthStart, WeekOfMonthEnd) => {
        this.props.dispatch(showLoading());
        return ScheduleService.updateSchedule(ScheduleID, ScheduleName, Mode, DayOfWeekStart, DayOfWeekEnd, WeekOfMonthStart, WeekOfMonthEnd)
            .then(res => {
                this.props.dispatch(hideLoading());
                this.searchScheduleAfterSetState();
                return res;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    deleteSchedule = (FormID) => {
        Swal.fire({
            title: 'ลบช่วงเวลาประเมิน',
            text: 'ต้องการลบช่วงเวลาประเมินนี้หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    ScheduleService.deleteSchedule(FormID)
                        .then(result => {
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                                return;
                            }
                            Swal.fire({
                                title: 'ลบข้อมูลสำเร็จ',
                                type: 'success'
                            })
                            this.searchScheduleAfterSetState();
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                type: 'error'
                            });
                        });
            });
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.getScheduleDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
        else
            this.setState({ [name]: value }, () => {
                this.getScheduleDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
    }

    onChangeSearch(name, value) {
        this.setState({ [name]: value });
    }

    changeCurrentScheduleID = (index) => {
        this.setState({
            currentScheduleID: index,
            show: true
        });
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false,
                currentScheduleID: null
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    clearSearch = () => {
        this.setState({
            searchProfileName: '',
            searchMode: 0,
            searched: {
                isSearched: false,
                searchedProfileName: '',
                searchedMode: 0
            }
        }, () => {
            this.searchSchedule(null, null)
        })
    }

    onClickSearch = () => {
        if (this.state.searchProfileName === '' && this.state.searchMode === 0)
            this.setState({
                searchProfileName: '',
                searchMode: 0,
                currentPage: 1,
                searched: {
                    isSearched: false,
                    searchedProfileName: '',
                    searchedMode: 0
                }
            }, () => {
                this.searchScheduleAfterSetState();
            })
        else
            this.setState({
                //searchProfileName: '',
                //searchMode: 0,
                currentPage: 1,
                searched: {
                    isSearched: true,
                    searchedProfileName: this.state.searchProfileName,
                    searchedMode: this.state.searchMode
                }
            }, () => {
                this.searchScheduleAfterSetState();
            });
    }

    render() {
        return (
            <>
                <div className='custom-block mb-4'>
                    <div className='row align-items-end'>
                        <div className='col-12 col-md-6 col-lg-6 form-group'>
                            <label className='form-label'>ชื่อโปรไฟล์</label>
                            <input
                                className='form-control'
                                name='searchProfileName'
                                placeholder='ชื่อโปรไฟล์'
                                value={this.state.searchProfileName}
                                onChange={e => this.onChangeSearch(e.target.name, e.target.value)}
                            />
                        </div>
                        {/* <div className='col-12 col-md-6 col-lg-6 form-group'>
                            <label className='form-label'>ประเภทการใช้งาน</label>
                            <select
                                className='form-control'
                                name='searchMode'
                                value={this.state.searchMode}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                <option value={1}>รายวัน</option>
                                <option value={2}>รายสัปดาห์</option>
                                <option value={3}>รายเดือน</option>
                            </select>
                        </div> */}
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.onClickSearch}>ค้นหา</button>
                    </div>
                </div>

                <ScheduleTable
                    data={this.getScheduleDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit)}
                    currentPage={this.state.currentPage}
                    limit={this.state.limit}
                    onChangeTableState={this.onChangeTableState}
                    searched={this.state.searched}
                    dataCount={this.state.dataCount}
                    clickAddSchedule={this.hideModal}
                    clickDeleteSchedule={this.deleteSchedule}
                    changeCurrentScheduleID={this.changeCurrentScheduleID}
                />
                <ScheduleModal
                    show={this.state.show}
                    hide={this.hideModal}
                    currentScheduleID={this.state.currentScheduleID}
                    getScheduleDetail={this.getScheduleDetail}
                    createSchedule={this.createSchedule}
                    updateSchedule={this.updateSchedule}
                />
            </>
        );
    }
}

export default connect(null)(Schedule);