export const setAccountLogin = (payload) => {
    console.log(payload)
    return {
        type: 'LOGIN',
        payload: {
            email: payload.Email,
            username: payload.Username,
            accountID: payload.AccountID,
            roleID: payload.RoleID,
            token: payload.Token,
            organizationID: payload.OrganizationID
        }
    }
}

export const setAccountLogout = (payload) => {
    return {
        type: 'LOGOUT'
    }
}

export const showLoading = () => {
    return {
        type: 'SHOWLOADING'
    }
}

export const hideLoading = () => {
    return {
        type: 'HIDELOADING'
    }
}

export const setProfileImg = (payload) => {
    return {
        type: 'SETPROFILEIMG',
        payload: {
            data: payload.data
        }
    }
}

export const setRoleID = (payload) => {
    return {
        type: 'SETROLEID',
        payload: {
            roleID: payload
        }
    }
}

export const setNotification = (payload) => {
    return {
        type: 'SETNOTIFICATION',
        payload: {
            notification: (payload > 0)
        }
    }
}

export const setDataLoaded = (payload) => {
    return {
        type: 'SETDATALOADED',
        payload: {
            dataLoaded: payload
        }
    }
}