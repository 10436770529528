import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Autocomplete from '../../../Utilities/Autocomplete';
import FormService from '../../../Service/FormService';
import Swal from 'sweetalert2';

class FormManageCopyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canUpdate: false,
            type: 0,
            el: [],
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        this.props.hide(this.resetState);
    }

    onEnterModal = () => {
        this.setState({ canUpdate: true }, () => {
            var data = this.getFormtoEvaluate();
            if (!data) return;
            this.setState({
                el: ((data.el) ? data.el : []),
                type: ((data.type) ? data.type : null)
            });
        });
    }

    resetState = () => {
        this.setState({
            canUpdate: false,
            el: [],
            type: 0
        })
    }

    onChangeContent = (name, value) => {
        this.setState({ [name]: value }, () => {
            console.log(this.state)
        });
    }

    onSave = () => {
        if (this.state.type === 0) {
            Swal.fire({
                type: 'warning',
                title: 'กรุณาเลือกผลการประเมิน',
                text: 'กรุณาเลือกผลการประเมิน'
            });
            return;
        }
        Swal.fire({
            title: 'สรุปผลการประเมิน',
            text: 'ท่านต้องการสรุปผลการประเมินนี้ไม่ ?',
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.EvaluateResult(this.getScore(this.props.EvaluationTypeID));
                    this.onHideModal();
                }
            })
    }

    getScore = (EvaluationTypeID) => {
        switch (EvaluationTypeID) {
            case 6:
            case 3: {
                switch (this.state.type) {
                    case 1: return 0;
                    case 2: return this.props.EvaluationCriteria.Low.Result;
                    case 3: return this.props.EvaluationCriteria.Mid.Result;
                    case 4: return this.props.EvaluationCriteria.High.Result;
                    default: return null;
                }
            }
            default: return null;
        }
    }

    getFormtoEvaluate = () => {
        var form = this.props.getFormtoEvaluate();
        if (!form && this.props.EvaluationTypeID) {
            Swal.fire({
                type: 'error',
                title: 'ไม่สามารถสรุปผลการประเมินได้',
                text: 'ไม่สามารถสรุปผลการประเมินได้เนื่องจากฟอร์มไม่ถูกต้อง'
            });
            this.onHideModal(this.resetState);
            return null;
        }
        return form;
    }

    changeType = (index) => {
        if (this.state.type === index) index = 0;
        this.setState({ type: index });
    }

    renderButton = (EvaluationTypeID) => {
        console.log(EvaluationTypeID)
        switch (EvaluationTypeID) {
            case 6:
            case 3: {
                return (
                    <>
                        <div className='checkbox mb-3'>
                            <input
                                type="checkbox"
                                className="form-check d-inline-block ml-2"
                                checked={(this.state.type === 1)}
                                readOnly
                            />
                            <label
                                className="form-check-label noCross d-inline-block noselect"
                                onClick={() => this.changeType(1)}
                            >
                                ไม่ผ่าน
                            </label>
                        </div>
                        <div className='checkbox mb-3'>
                            <input
                                type="checkbox"
                                className="form-check d-inline-block ml-2"
                                checked={(this.state.type === 2)}
                                readOnly
                            />
                            <label
                                className="form-check-label noCross d-inline-block noselect"
                                onClick={() => this.changeType(2)}
                            >
                                ขั้นต่ำ
                            </label>
                        </div>
                        <div className='checkbox mb-3'>
                            <input
                                type="checkbox"
                                className="form-check d-inline-block ml-2"
                                checked={(this.state.type === 3)}
                                readOnly
                            />
                            <label
                                className="form-check-label noCross d-inline-block noselect"
                                onClick={() => this.changeType(3)}
                            >
                                ขั้นมาตรฐาน
                            </label>
                        </div>
                        <div className='checkbox'>
                            <input
                                type="checkbox"
                                className="form-check d-inline-block ml-2"
                                checked={(this.state.type === 4)}
                                readOnly
                            />
                            <label
                                className="form-check-label noCross d-inline-block noselect"
                                onClick={() => this.changeType(4)}
                            >
                                ขั้นสูง
                            </label>
                        </div>
                    </>
                );
            }
            default: return null;
        }
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.onHideModal}
                    size='lg' onEnter={this.onEnterModal}
                    backdropClassName='backdrop-formcopy'
                    className='dialog-formcopy'
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            สรุปผลการประเมิน
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='p-3 overflow-div'>
                            {this.state.el}
                        </div>
                        <div className='mt-3 form-group pl-3'>
                            <label className='form-label'>ผลการประเมิน</label>
                            {this.renderButton(this.props.EvaluationTypeID)}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={this.onSave}
                            >
                                ตกลง
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default FormManageCopyModal;
