import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Collapse from 'react-bootstrap/Collapse';
import { renderYear } from '../../../Utilities/Date';
import Autocomplete from '../../../Utilities/Autocomplete';
import GMap from './maps';

class KPIManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSection: 0,
            section2SubSection: 0,
            section4SubSection: 0,
            section6SubSection: null,
            sectionName: ['ตัวชี้วัด', 'ผู้กำกับดูแล', 'แผนงาน', 'เกณฑ์การประเมิน', 'แบบฟอร์ม', 'บันทึก'],
            KPIName: '',
            Year: 0,
            Description: '',
            EvaluationTypeID: 3,
            KPIMain: 0,
            KPIPersons: [],
            KPIOrganizations: [],
            DelKPIPersons: [],
            DelKPIOrganizations: [],
            searchPerson: {},
            searchOrganization: {},
            KPIMainData: [],
            StrategyID: 0,
            StrategyData: [],
            ListKPIs: [],
            searchMainKPI: 0,
            searchForm: {},
            Form: {},
            EvaluationCriteria: {
                Type: 0,
                Weight: null,
                PointFull: null,
                PointPass: null,
                Unit: '',
                Low: {
                    Target: 0,
                    Description: '',
                    Result: 0
                },
                Mid: {
                    Target: 0,
                    Description: '',
                    Result: 0
                },
                High: {
                    Target: 0,
                    Description: '',
                    Result: 0
                }
            },
            mapData: [],
            delMapData: [],
            currentMapData: null,
            canUpdate: false,
            Editable: false
        }
    }

    componentDidMount() {
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    getYear = () => {
        return renderYear('KPIManage-modal')
    }

    getMainKPIs = (Year, EvaluationTypeID, PolicyID) => {
        this.props.getMainKPI(Year, EvaluationTypeID, PolicyID)
            .then(result => {
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    this.setState({ canUpdate: true });
                    return;
                }
                result = result.data.SearchKPIMain;
                this.setState({
                    KPIMainData: (result) ? [...result] : []
                });
            })
    }

    getListKPIinKPIMain = (KPIMainID) => {
        this.props.getListKPIinKPIMain(KPIMainID)
            .then(result => {
                if (result.errors) return;
                result = result.data.GetListKPIinKPIMain;
                this.setState({
                    ListKPIs: (result) ? [...result] : []
                });
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            });
    }

    getListStrategy = (Year, EvaluationTypeID) => {
        this.props.getListStrategy(Year, EvaluationTypeID)
            .then(result => {
                if (result.errors) return;
                result = result.data.GetListStrategy;
                this.setState({
                    StrategyData: (result) ? [...result] : []
                });
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    getListObjective = (StrategyID, EvaluationTypeID) => {
        this.props.getListObjective(StrategyID, (EvaluationTypeID || null))
            .then(result => {
                if (result.errors) return;
                result = result.data.GetListObjective;
                this.setState({
                    KPIMainData: (result) ? [...result] : []
                });
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    onEnterModal = () => {
        console.log(this.props.currentKPIID);
        if (this.props.currentKPIID !== null) {
            this.props.getKPIDetail(this.props.currentKPIID)
                .then(result => {
                    console.log(result);
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                        this.setState({ canUpdate: true });
                        return;
                    }
                    result = result.data.GetKPIDetail;
                    console.log(result);
                    this.setState({
                        KPIName: result.KPIName,
                        Year: result.Year,
                        Description: result.Description,
                        KPIMain: result.KPIMain.KPIMainID,
                        StrategyID: result.StrategyID,
                        EvaluationTypeID: result.EvaluationType.EvaluationTypeID,
                        KPIOrganizations: (result.KPIOrganizations) ? [...result.KPIOrganizations] : [],
                        KPIPersons: (result.KPIPersons) ? [...result.KPIPersons] : [],
                        ListKPIs: (result.ListKPI) ? [...result.ListKPI] : [],
                        Form: { ...result.Form },
                        EvaluationCriteria: { ...result.EvaluationCriteria },
                        mapData: (result.ProjectKPI) ? [...result.ProjectKPI] : [],
                        DelKPIPersons: [],
                        DelKPIOrganizations: [],
                        delMapData: [],
                        KPIMainData: [],
                        StrategyData: [],
                        currentMapData: null,
                        canUpdate: true,
                        Editable: (result.Editable || false)
                    }, () => {
                        console.log(this.state.EvaluationTypeID)
                        if (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 4 || this.state.EvaluationTypeID === 6)
                            this.getMainKPIs(this.state.Year, this.state.EvaluationTypeID, null);
                        else {
                            this.getListStrategy(this.state.Year, this.state.EvaluationTypeID);
                            this.getListObjective(this.state.StrategyID, this.state.EvaluationTypeID);
                            this.getListKPIinKPIMain(this.state.KPIMain);
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    })
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState, 3);
                    })
                })
        }
        else
            this.setState({
                Year: this.props.createKPIYear,
                EvaluationTypeID: this.props.createEvaluationType,
                canUpdate: true,
                Editable: true,
                EvaluationCriteria: ((this.props.createEvaluationType === 3) ?
                    {
                        Type: 0,
                        Weight: null,
                        PointFull: null,
                        PointPass: null,
                        Unit: '',
                        Low: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        },
                        Mid: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        },
                        High: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        }
                    } :
                    {
                        Type: 0,
                        Weight: null,
                        PointFull: null,
                        PointPass: null,
                        Unit: '',
                        VeryLow: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        },
                        Low: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        },
                        Mid: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        },
                        High: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        },
                        VeryHigh: {
                            Target: 0,
                            Description: '',
                            Result: 0
                        }
                    }
                )
            }, () => {
                if (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 4 || this.state.EvaluationTypeID === 6)
                    this.getMainKPIs(this.state.Year, this.state.EvaluationTypeID, null);
                else {
                    this.getListStrategy(this.state.Year, this.state.EvaluationTypeID);
                }
            });
    }

    onHideModal = () => {
        var title = (this.props.currentKPIID) ? 'ยกเลิกการแก้ไขตัวชี้วัด' : 'ยกเลิกการเพิ่มตัวชี้วัด'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    this.props.hide(this.resetState, 3)
            })

    }

    checkUpdate = () => {
        var title = '';
        var text = '';
        var type = null;
        if (this.state.KPIName === '' || this.state.KPIName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อตัวชี้วัด';
            type = 'warning';
        }
        else if (this.state.KPIMain === 0) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุ' + ((this.state.EvaluationTypeID === 1 || this.state.EvaluationTypeID === 2) ? 'เป้าประสงค์' : 'ตัวชี้วัดหลัก');
            type = 'warning';
        }
        else if (this.state.KPIPersons.length === 0 && this.state.KPIOrganizations.length === 0) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุผู้กำกับดูแลหรือผู้จัดเก็บข้อมูล';
            type = 'warning';
        }
        else if ((!this.state.EvaluationCriteria.Weight || this.state.EvaluationCriteria.Weight === 0) && this.state.EvaluationTypeID === 3) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุค่าน้ำหนักของตัวชี้วัด';
            type = 'warning';
        }
        else if (this.state.EvaluationCriteria.Weight) {
            if (!this.checkWeight(this.state.EvaluationCriteria.Weight)) {
                title = 'กรุณาตรวจสอบข้อมูล';
                text = 'กรุณาตรวจสอบค่าน้ำหนักของตัวชี้วัด โดยค่าน้ำหนักรวมต้องไม่เกิน 100';
                type = 'warning';
            }
        }
        if (this.state.EvaluationTypeID === 3) {
            if (
                this.state.EvaluationCriteria.Low.Result >= this.state.EvaluationCriteria.Mid.Result ||
                this.state.EvaluationCriteria.Mid.Result >= this.state.EvaluationCriteria.High.Result
            ) {
                title = 'กรุณาตรวจสอบข้อมูล';
                text = 'กรุณาตรวจสอบเกณฑ์การประเมิน คะแนนที่จะได้รับของตัวชี้วัด';
                type = 'warning';
            }
            else if (
                this.state.EvaluationCriteria.Type === 2 &&
                (this.state.EvaluationCriteria.Low.Target >= this.state.EvaluationCriteria.Mid.Target ||
                    this.state.EvaluationCriteria.Mid.Target >= this.state.EvaluationCriteria.High.Target)
            ) {
                title = 'กรุณาตรวจสอบข้อมูล';
                text = 'กรุณาตรวจสอบเกณฑ์การประเมิน ผลการดำเนินงานของตัวชี้วัด';
                type = 'warning';
            }
            if (
                !this.state.EvaluationCriteria.Low.Result ||
                !this.state.EvaluationCriteria.Mid.Result ||
                !this.state.EvaluationCriteria.High.Result
            ) {
                Swal.fire({
                    title: 'ตัวชี้วัดนี้จะไม่สามารถคำนวณผลได้',
                    text: 'ตัวชี้วัดนี้จะไม่สามารถคำนวณผลได้ เนื่องจากท่านระบุเกณฑ์การประเมินไม่ครบถ้วน ท่านต้องการดำเนินการต่อหรือไม่ ?',
                    type: 'warning',
                    confirmButtonText: 'ตกลง',
                    showCancelButton: true,
                    cancelButtonText: 'ยกเลิก',
                    reverseButtons: true
                })
                    .then(res => {
                        if (res.value)
                            this.onSave(true);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                return false;
            }
        }
        if (this.state.mapData.length > 0) {
            this.state.mapData.forEach(obj => {
                obj.Lat = (!parseFloat(obj.Lat)) ? null : parseFloat(obj.Lat);
                obj.Lng = (!parseFloat(obj.Lng)) ? null : parseFloat(obj.Lng);
            });
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = (skipcheck) => {
        console.log(
            this.props.currentKPIID,
            this.state.KPIName,
            this.state.Description,
            this.state.Year,
            this.state.KPIMain,
            this.state.EvaluationCriteria,
            false,
            this.state.KPIPersons,
            this.state.KPIOrganizations,
            this.state.Form.FormID,
            this.state.DelKPIPersons,
            this.state.DelKPIOrganizations
        )
        if (this.checkUpdate() || skipcheck) {
            var KPIService;
            if (this.props.currentKPIID === null) {
                KPIService = this.props.createKPI(
                    this.state.KPIName,
                    this.state.Description,
                    this.state.Year,
                    this.state.KPIMain,
                    this.state.EvaluationCriteria,
                    false,
                    this.state.KPIPersons,
                    this.state.KPIOrganizations,
                    this.state.Form.FormID,
                    this.state.mapData
                )
            }
            else {
                KPIService = this.props.updateKPI(
                    this.props.currentKPIID,
                    this.state.KPIName,
                    this.state.Description,
                    this.state.Year,
                    this.state.KPIMain,
                    this.state.EvaluationCriteria,
                    false,
                    this.state.KPIPersons,
                    this.state.KPIOrganizations,
                    this.state.Form.FormID,
                    this.state.mapData,
                    this.state.DelKPIPersons,
                    this.state.DelKPIOrganizations
                )
            }

            KPIService.then(
                result => {
                    console.log(result)
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                        if (this.props.currentKPIID === null)
                            this.props.hide(this.resetState, 3);
                        else
                            this.onEnterModal();
                    }
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                        type: 'error'
                    })
                })
        }

    }

    resetState = () => {
        this.setState({
            currentSection: 0,
            section2SubSection: 0,
            section4SubSection: 0,
            section6SubSection: null,
            KPIName: '',
            Year: 0,
            Description: '',
            EvaluationTypeID: 3,
            KPIMain: 0,
            KPIPersons: [],
            KPIOrganizations: [],
            DelKPIPersons: [],
            DelKPIOrganizations: [],
            searchPerson: {},
            searchOrganization: {},
            KPIMainData: [],
            StrategyID: 0,
            StrategyData: [],
            ListKPIs: [],
            searchMainKPI: 0,
            searchForm: {},
            Form: {},
            EvaluationCriteria: {
                Type: 0,
                Weight: null,
                PointFull: null,
                PointPass: null,
                Unit: '',
                Low: {
                    Target: 0,
                    Description: '',
                    Result: 0
                },
                Mid: {
                    Target: 0,
                    Description: '',
                    Result: 0
                },
                High: {
                    Target: 0,
                    Description: '',
                    Result: 0
                }
            },
            mapData: [],
            currentMapData: null,
            canUpdate: false,
            Editable: false
        });
    }

    checkStepProgressbar = (index) => {
        if (this.state.currentSection === index) {
            return 'is-active content' + (index + 1);
        }
        else return 'content' + (index + 1);
    }

    checkWeight = (value) => {
        var totalWeight = 0;
        this.state.ListKPIs.forEach(el => {
            if (el.KPIID !== this.props.currentKPIID)
                totalWeight += el.EvaluationCriteria.Weight;
        });
        if ((value + totalWeight) > 100) return false;
        else return true;
    }

    manageCurrentSection = (index) => {
        this.setState({
            currentSection: index,
            section2SubSection: 0,
            section4SubSection: 0,
            searchPerson: {},
            searchOrganization: {},
            searchForm: {},
            currentMapData: null
        });
    }

    manageCurrentSubSection = (index, section) => {
        var name = 'section' + section + 'SubSection';
        this.setState({
            [name]: index,
            searchPerson: {},
            searchOrganization: {},
        });
    }

    manageSection6Collapse = (index) => {
        if (index === this.state.section6SubSection) {
            this.setState({ section6SubSection: null });
        }
        else {
            this.setState({ section6SubSection: index });
        }
    }

    checkEvaluationType = (TypeID) => {
        TypeID = parseInt(TypeID);
        for (var i = 0; i < this.props.EvaluationTypes.length; i++) {
            if (TypeID === parseInt(this.props.EvaluationTypes[i].EvaluationTypeID)) {
                return this.props.EvaluationTypes[i].EvaluationTypeName;
            }
        }
    }

    checkActiveTab = (index, section) => {
        var name = 'section' + section + 'SubSection';
        if (this.state[name] === index)
            return 'nav-link active';
        return 'nav-link badge-secondary';
    }

    onChangeContent = (type, value, objectType, name) => {
        console.log(type, value, objectType)
        if (objectType) {
            switch (objectType) {
                case 'Form': {
                    if (!value || value === 0) {
                        return;
                    }
                    this.setState({
                        [objectType]: {
                            FormID: value.FormID,
                            FormName: value.FormName,
                            Remark: value.Remark
                        }
                    })
                    return;
                }
                case 'EvaluationCriteria': {
                    var newObj = { ...this.state[objectType] };
                    if (name)
                        (newObj[type])[name] = value;
                    else
                        newObj[type] = value;
                    if (type === 'PointPass') {
                        if (value > this.state.EvaluationCriteria.PointFull)
                            newObj.PointPass = this.state.EvaluationCriteria.PointFull;
                    }
                    else if (type === 'PointFull') {
                        if (value < this.state.EvaluationCriteria.PointPass)
                            newObj.PointPass = value;
                    }
                    else if (type === 'Weight') {
                        //if (!this.checkWeight(value)) return;
                    }
                    else if (type === 'Type') {
                        newObj.Low.Description = '';
                        newObj.Mid.Description = '';
                        newObj.High.Description = '';
                        newObj.Low.Target = 0;
                        newObj.Mid.Target = 0;
                        newObj.High.Target = 0;
                        if (value === 1) newObj.Unit = '';
                    }
                    this.setState({ [objectType]: newObj });
                    return;
                }
                case 'KPIPersons':
                    {
                        if (!value || !value.PersonID) {
                            this.setState({
                                searchPerson: null
                            });
                            return;
                        }
                        var newObj = [...this.state[objectType]];
                        for (var i of newObj) {
                            if (i.PersonID === value.PersonID && i.PersonStatus === value.PersonStatus) {
                                return;
                            }
                        }
                        var obj = {
                            PersonID: value.PersonID,
                            PersonName: value.PersonName,
                            PersonStatus: value.PersonStatus
                        }
                        var newDel = [...this.state.DelKPIPersons];
                        var removeIndex = newDel.findIndex(x => (x.PersonID === obj.PersonID && x.PersonStatus === obj.PersonStatus))
                        console.log(removeIndex);
                        if (removeIndex !== -1) {
                            newDel.splice(removeIndex, 1);
                        }
                        newObj.push(obj);
                        this.setState({
                            KPIPersons: newObj,
                            DelKPIPersons: newDel
                        });
                        return;
                    }
                case 'KPIOrganizations': {
                    if (!value || !value.OrganizationID) {
                        this.setState({
                            searchOrganization: null
                        });
                        return;
                    }
                    var newObj = [...this.state[objectType]];
                    for (var i of newObj) {
                        if (i.OrganizationID === value.OrganizationID && i.OrganizationStatus === value.OrganizationStatus) {
                            return;
                        }
                    }
                    var obj = {
                        OrganizationID: value.OrganizationID,
                        OrganizationName: value.OrganizationName,
                        OrganizationStatus: value.OrganizationStatus
                    }
                    var newDel = [...this.state.DelKPIOrganizations];
                    var removeIndex = newDel.findIndex(x => (x.OrganizationID === obj.OrganizationID && x.OrganizationStatus === obj.OrganizationStatus))
                    if (removeIndex !== -1) {
                        newDel.splice(removeIndex, 1);
                    }
                    newObj.push(obj);
                    this.setState({
                        KPIOrganizations: newObj,
                        DelKPIOrganizations: newDel
                    });
                    return;
                }
                default: {
                    var newObj = { ...this.state[objectType] };
                    newObj[type] = value
                    this.setState({ [objectType]: newObj });
                    return;
                }
            }
        }

        if (value || value === 0 || value === '') {
            switch (type) {
                case 'searchPerson': {
                    if (value.PersonID)
                        this.setState({
                            [type]: {
                                PersonID: parseInt(value.PersonID),
                                PersonName: value.PersonName,
                                PersonStatus: (this.state.section2SubSection + 1)
                            }
                        });
                    else
                        this.setState({
                            [type]: null
                        });
                    return;
                }
                case 'searchOrganization': {
                    if (value.OrganizationID)
                        this.setState({
                            [type]: {
                                OrganizationID: parseInt(value.OrganizationID),
                                OrganizationName: value.OrganizationName,
                                OrganizationStatus: (this.state.section2SubSection + 1)
                            }
                        });
                    else
                        this.setState({
                            [type]: null
                        });
                    return;
                }
                case 'searchForm': {
                    this.setState({ [type]: value });
                    return;
                }
                case 'KPIMain': {
                    if (value === 0 || this.state.EvaluationTypeID !== 3) {
                        this.setState({ [type]: value, ListKPIs: [] });
                        if (this.state.EvaluationTypeID === 1 || this.state.EvaluationTypeID === 2)
                            this.getListKPIinKPIMain(value);
                        return;
                    }
                    this.getListKPIinKPIMain(value);
                    this.setState({ [type]: value });
                    return;
                }
                case 'StrategyID': {
                    if (value === 0) {
                        this.setState({ [type]: value, KPIMainData: [], KPIMain: 0 });
                        return;
                    }
                    this.getListObjective(value, this.state.EvaluationTypeID);
                    this.setState({ [type]: value, KPIMain: 0 });
                    return;
                }
                default: break;
            }
        }

        this.setState({ [type]: value }, () => { console.log(this.state) });
    }

    removeKPIPersons = (index, type) => {
        var newKPI = [...this.state[type]];
        var newDel = [...this.state['Del' + type]];
        var del;
        if (type === 'KPIPersons') {
            del = newDel.find(x => (x.PersonID === newKPI[index].PersonID && x.PersonStatus === newKPI[index].PersonStatus));
        }
        else {
            del = newDel.find(x => (x.OrganizationID === newKPI[index].OrganizationID && x.OrganizationStatus === newKPI[index].OrganizationStatus));
        }
        console.log(del)
        if (!del) {
            newDel.push(newKPI[index]);
        }
        newKPI.splice(index, 1);
        console.log(newDel);
        this.setState({
            [type]: newKPI,
            ['Del' + type]: newDel
        });
    }

    createProjectMapData = () => {
        return {
            ProjectName: '',
            Lat: null,
            Lng: null,
            Organization: {}
        }
    }

    addProjectMapData = () => {
        var newMapDatas = [...this.state.mapData];
        newMapDatas.push(this.createProjectMapData());
        this.setState({
            mapData: newMapDatas
        });
    }

    removeProjectMapData = (index) => {
        var newMapDatas = [...this.state.mapData];
        var newDelMapDatas = [...this.state.delMapData];
        if (newMapDatas[index].ProjectID) {
            newDelMapDatas.push({ ProjectID: newMapDatas[index].ProjectID })
        }
        newMapDatas.splice(index, 1);
        this.setState({
            mapData: newMapDatas
        });
    }

    onChangeLatLng = (lat, lng) => {
        if (this.state.currentMapData === null) return;
        var newMapDatas = [...this.state.mapData];
        var newMapData = { ...this.state.mapData[this.state.currentMapData] };
        newMapData.Lat = lat;
        newMapData.Lng = lng;
        newMapDatas[this.state.currentMapData] = newMapData
        this.setState({
            mapData: newMapDatas
        })
    }

    onChangeMapData = (name, value) => {
        if (this.state.currentMapData === null) return;
        var newMapDatas = [...this.state.mapData];
        var newMapData = { ...this.state.mapData[this.state.currentMapData] };
        newMapData[name] = value;
        newMapDatas[this.state.currentMapData] = newMapData
        console.log(newMapData)
        this.setState({ mapData: newMapDatas });
    }

    renderModalBody = (index) => {
        switch (index + 1) {
            case 1: { return this.renderSection1(); }
            case 2: { return this.renderSection2(); }
            case 3: { return this.renderSection3(); }
            case 4: { return this.renderSection4(); }
            case 5: { return this.renderSection5(); }
            case 6: { return this.renderSection6(); }
            default: return null;
        }
    }

    renderSection1 = () => {
        return (
            <>
                <div className='div-transition'>
                    <div className='form-group'>
                        <label className='form-label'>ปีงบประมาณ</label>
                        <input className='form-control' value={(this.state.Year === null || this.state.Year === 0) ? '' : (this.state.Year) + 543} disabled />
                    </div>
                    <div className='form-group'>
                        <label className='form-label'>การประเมิน</label>
                        <input className='form-control' value={this.checkEvaluationType(this.state.EvaluationTypeID)} disabled />
                    </div>
                    {
                        (this.state.EvaluationTypeID === 1 || this.state.EvaluationTypeID === 2) ?
                            <div className='form-group'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ยุทธศาสตร์</label>
                                <select
                                    className='form-control'
                                    name='StrategyID'
                                    value={this.state.StrategyID}
                                    onChange={e => this.onChangeContent(e.target.name, parseInt(e.target.value))}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    {
                                        this.state.StrategyData.map((obj, index) => {
                                            return (
                                                <option key={'KPIManage-modal-Strategy-option' + index} value={obj.StrategyID}>
                                                    {obj.StrategyName}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            : null
                    }
                    {this.renderSection1byEvaluationType(this.state.EvaluationTypeID)}
                    <div className='form-group'>
                        <label className='form-label'>คำอธิบาย</label>
                        <textarea
                            className='form-control'
                            rows='5'
                            name='Description'
                            value={(this.state.Description || '')}
                            onChange={e => this.onChangeContent(e.target.name, e.target.value)}
                        />
                    </div>
                </div>
            </>
        );
    }

    renderSection1byEvaluationType = (EvaluationType) => {
        switch (EvaluationType) {
            case 1:
            case 2: {
                return (
                    <>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>เป้าประสงค์</label>
                            <select
                                className='form-control'
                                name='KPIMain' value={this.state.KPIMain}
                                onChange={e => this.onChangeContent(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.KPIMainData.map((obj, index) => {
                                        return (
                                            <option key={'KPIManage-modal-KPIMain-option' + index} value={obj.ObjectiveID}>
                                                {obj.ObjectiveName}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            {/* <label className='form-label'>ตัวบ่งชี้</label>
                            <input
                                className='form-control'
                                placeholder='ตัวบ่งชี้'
                                name='KPIName'
                                value={(this.state.KPIName || '')}
                                onChange={e => this.onChangeContent(e.target.name, e.target.value)}
                            /> */}
                            <label className='form-label'>ตัวชี้วัด</label>
                            <input
                                className='form-control'
                                placeholder='ตัวชี้วัด'
                                name='KPIName'
                                value={(this.state.KPIName || '')}
                                onChange={e => this.onChangeContent(e.target.name, e.target.value)}
                            />
                        </div>
                    </>
                );
            }
            case 3:
            case 4:
            case 6: {
                return (
                    <>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>ตัวชี้วัดหลัก</label>
                            <select className='form-control' name='KPIMain' value={this.state.KPIMain} onChange={e => this.onChangeContent(e.target.name, parseInt(e.target.value))}>
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.KPIMainData.map((obj, index) => {
                                        return (
                                            <option key={'KPIManage-modal-KPIMain-option' + index} value={obj.KPIMainID}>
                                                {obj.KPIMainName}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <i className='text-danger mr-1'>*</i>
                            <label className='form-label'>ตัวชี้วัด</label>
                            <input
                                className='form-control'
                                placeholder='ตัวชี้วัด'
                                name='KPIName'
                                value={(this.state.KPIName || '')}
                                onChange={e => this.onChangeContent(e.target.name, e.target.value)}
                            />
                        </div>
                    </>
                );
            }
            default: return null;
        }
    }

    renderSection2 = () => {
        return (
            <>
                <div className='div-transition2'>
                    <ul className="nav nav-pills mb-3">
                        <li className="nav-item custom-nav-item" key={'KPIManage-list-nav' + 0}>
                            <div className={'mr-2 ' + this.checkActiveTab(0, 2)} onClick={() => this.manageCurrentSubSection(0, 2)}>
                                ผู้กำกับดูแล
                            </div>
                        </li>
                        <li className="nav-item custom-nav-item" key={'KPIManage-list-nav' + 1}>
                            <div className={this.checkActiveTab(1, 2)} onClick={() => this.manageCurrentSubSection(1, 2)}>
                                ผู้จัดเก็บข้อมูล
                            </div>
                        </li>
                    </ul>
                    <label className='text-primary'>
                        {
                            (this.state.section2SubSection === 0) ?
                                (
                                    <>
                                        <i className='text-danger mr-1'>*</i>ผู้กำกับดูแล
                                    </>
                                ) :
                                'ผู้จัดเก็บข้อมูล'
                        }
                    </label>
                    {
                        <>
                            <div className='form-group'>
                                <label className='form-label'>
                                    บุคคล
                                    <button className='btn btn-outline-primary btn-sm ml-3' onClick={() => window.open(window.origin + '/PMQAKPI/PersonManagement?create=true')}>
                                        เพิ่มบุคคล
                                        </button>
                                </label>
                                <div>
                                    <div className='d-flex'>
                                        <div className='flex-fill'>
                                            <Autocomplete
                                                getData={this.props.searchPerson}
                                                name='searchPerson'
                                                labelName='บุคคล'
                                                dataName='Person'
                                                onChangeSelect={this.onChangeContent}
                                                selectValue={this.state.searchPerson}
                                                searchOnChange
                                            />
                                        </div>
                                        <div className='ml-2' align='center'>
                                            <button className='btn btn-outline-primary' onClick={() => this.onChangeContent('KPIPerson', this.state.searchPerson, 'KPIPersons')}>เลือก</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='form-label'>
                                    หน่วยงาน
                                    <button className='btn btn-outline-primary btn-sm ml-3' onClick={() => window.open(window.origin + '/AdminManagement/OrgManagement?create=true')}>
                                        เพิ่มหน่วยงาน
                                    </button>
                                </label>
                                <div>
                                    <div className='d-flex'>
                                        <div className='flex-fill'>
                                            <Autocomplete
                                                getData={this.props.searchOrganization}
                                                name='searchOrganization'
                                                labelName='หน่วยงาน'
                                                dataName='Organization'
                                                onChangeSelect={this.onChangeContent}
                                                selectValue={this.state.searchOrganization}
                                                searchOnChange
                                            />
                                        </div>
                                        <div className='ml-2' align='center'>
                                            <button className='btn btn-outline-primary' onClick={() => this.onChangeContent('KPIOrganization', this.state.searchOrganization, 'KPIOrganizations')}>เลือก</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <table className='table table-custom table-striped table-bordered table-hover'>
                                    <thead align="center">
                                        <tr>
                                            <th>ลำดับ</th>
                                            <th>{(this.state.section2SubSection === 0) ? 'ผู้กำกับดูแล' : 'ผู้จัดเก็บข้อมูล'}</th>
                                            <th>ลบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderKPIPersons(this.state.section2SubSection + 1, this.state.KPIPersons, this.state.KPIOrganizations)}
                                    </tbody>
                                </table>
                            </div>
                        </>

                    }
                </div>
            </>
        )
    }

    renderKPIPersons = (type, KPIPersons, KPIOrgs) => {
        var count = 0;
        var el = [];
        KPIOrgs.map((obj, index) => {
            if (obj.OrganizationStatus !== type) return null;
            count++;
            el.push(
                <tr key={'KPIManage-modal-KPIOrg' + index} className='input-transition'>
                    <td>{count}</td>
                    <td>{obj.OrganizationName}<span className='badge badge-sm badge-primary ml-2'>หน่วยงาน</span></td>
                    <td align="center">
                        <button
                            className='btn btn-outline-danger btn-sm'
                            onClick={() => this.removeKPIPersons(index, 'KPIOrganizations')}
                        >
                            <i className='fa fa-trash'></i>
                        </button>
                    </td>
                </tr>
            );
            return null;
        })
        KPIPersons.map((obj, index) => {
            if (obj.PersonStatus !== type) return null;
            count++;
            el.push(
                <tr key={'KPIManage-modal-KPIPersons' + index} className='input-transition'>
                    <td>{count}</td>
                    <td>{obj.PersonName}<span className='badge badge-sm badge-primary ml-2'>บุคคล</span></td>
                    <td align="center">
                        <button
                            className='btn btn-outline-danger btn-sm'
                            onClick={() => this.removeKPIPersons(index, 'KPIPersons')}
                        >
                            <i className='fa fa-trash'></i>
                        </button>
                    </td>
                </tr>
            );
            return null;
        })
        return el;
    }

    renderSection3 = () => {
        return (
            <>
                <div className='div-transition div-3'>
                    <GMap
                        mapData={this.state.mapData}
                        onChangeLatLng={this.onChangeLatLng}
                    />
                    <div
                        className={
                            'mt-2 overflow-div Evaluation-div '
                            + (!(this.state.currentMapData !== null) ? '' : 'Evaluation-div-disable')
                        }
                    >
                        <button className='btn btn-outline-primary mb-2' onClick={this.addProjectMapData}>เพิ่มโครงการ</button>
                        <table className='table table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th style={{ minWidth: '60px' }}>ลำดับ</th>
                                    <th style={{ minWidth: '170px' }}>โครงการ</th>
                                    <th style={{ minWidth: '125px' }}>แก้ไข / ลบ</th>
                                </tr>
                            </thead>
                            <tbody align='center'>
                                {
                                    this.state.mapData.map((obj, index) => {
                                        return (
                                            <tr key={'mapData-' + index} className='input-transition'>
                                                <td>{index + 1}</td>
                                                <td
                                                    onClick={() => this.setState({ currentMapData: index })}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {obj.ProjectName}
                                                </td>
                                                <td>
                                                    <button
                                                        className='btn btn-outline-secondary mr-2'
                                                        onClick={() => this.setState({ currentMapData: index })}
                                                    >
                                                        <i className='fa fa-pencil' />
                                                    </button>

                                                    <button
                                                        className='btn btn-outline-danger'
                                                        onClick={() => this.removeProjectMapData(index)}
                                                    >
                                                        <i className='fa fa-trash' />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div
                        className={
                            'mt-2 Evaluation-div '
                            + ((this.state.currentMapData !== null) ? '' : 'Evaluation-div-disable')
                        }
                    >
                        {
                            (this.state.currentMapData !== null) ?
                                <>
                                    <button className='btn btn-outline-secondary' onClick={() => this.setState({ currentMapData: null })}>
                                        <i className='fa fa-arrow-left' />
                                    </button>
                                    <div className='row p-2'>
                                        <div className='form-group col-6'>
                                            <label className='form-label'>ชื่อโครงการ</label>
                                            <input
                                                className='form-control'
                                                value={this.state.mapData[this.state.currentMapData].ProjectName}
                                                name='ProjectName'
                                                onChange={e => this.onChangeMapData(e.target.name, e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group col-6'>
                                            <label className='form-label'>หน่วยงาน</label>
                                            <Autocomplete
                                                getData={this.props.searchOrganization}
                                                name='Organization'
                                                labelName='หน่วยงาน'
                                                dataName='Organization'
                                                onChangeSelect={this.onChangeMapData}
                                                selectValue={this.state.mapData[this.state.currentMapData].Organization}
                                            />
                                        </div>
                                        <div className='form-group col-6'>
                                            <label className='form-label'>Lat</label>
                                            <input
                                                className='form-control'
                                                value={(this.state.mapData[this.state.currentMapData].Lat || '')}
                                                name='Lat'
                                                onChange={e => this.onChangeMapData(e.target.name, e.target.value.replace(/[^0-9.]/g, ''))}
                                            />
                                        </div>
                                        <div className='form-group col-6'>
                                            <label className='form-label'>Lng</label>
                                            <input
                                                className='form-control'
                                                value={(this.state.mapData[this.state.currentMapData].Lng || '')}
                                                name='Lng'
                                                onChange={e => this.onChangeMapData(e.target.name, e.target.value.replace(/[^0-9.]/g, ''))}
                                            />
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    }

    renderSection4 = () => {
        return (
            <>
                <ul className="nav nav-pills mb-3 overflow-nav">
                    <li className="nav-item custom-nav-item" key={'KPIManage-list-nav' + 0}>
                        <div className={'mr-2 ' + this.checkActiveTab(0, 4)} onClick={() => this.manageCurrentSubSection(0, 4)}>
                            ข้อมูลเกณฑ์การประเมิน
                                    </div>
                    </li>
                    <li className="nav-item custom-nav-item" key={'KPIManage-list-nav' + 1}>
                        <div className={this.checkActiveTab(1, 4)} onClick={() => this.manageCurrentSubSection(1, 4)}>
                            เกณฑ์การประเมินรายปี
                                    </div>
                    </li>
                </ul>
                {this.renderSection4SubSection(this.state.section4SubSection)}
            </>
        );
    }

    renderInputWithUnit = (name) => {
        if (!this.state.EvaluationCriteria[name]) return (<td></td>);
        if (this.state.EvaluationCriteria.Unit === 'ร้อยละ') {
            return (
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='mr-3'>{this.state.EvaluationCriteria.Unit}</div>
                        <div className='flex-fill'>
                            <input
                                className='form-control'
                                value={(this.state.EvaluationCriteria[name].Target || 0)}
                                onChange={e => this.onChangeContent(name, (e.target.value.replace(/^0+(?!\.|$)/, '') || 0), 'EvaluationCriteria', 'Target')}
                                onBlur={e => this.onChangeContent(name, (parseFloat(e.target.value) || 0), 'EvaluationCriteria', 'Target')}
                            />
                        </div>
                    </div>
                </td>
            );
        }
        else
            return (
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='flex-fill'>
                            <input
                                className='form-control'
                                value={(this.state.EvaluationCriteria[name].Target || 0)}
                                onChange={e => this.onChangeContent(name, (e.target.value.replace(/^0+(?!\.|$)/, '') || 0), 'EvaluationCriteria', 'Target')}
                                onBlur={e => this.onChangeContent(name, (parseFloat(e.target.value) || 0), 'EvaluationCriteria', 'Target')}
                            />
                        </div>
                        <div className='ml-3'>{this.state.EvaluationCriteria.Unit}</div>
                    </div>
                </td>
            );
    }

    renderSection4SubSection = (index) => {
        switch (index) {
            case 0: {
                return (
                    <>
                        <label className='text-primary'>ข้อมูลเกณฑ์การประเมิน</label>
                        <div className='row form-group'>
                            <div className='col-12 col-lg-6 form-group'>
                                <label className='form-label'>ประเภทเกณฑ์การประเมิน</label>
                                <select
                                    className='form-control'
                                    name='Type'
                                    value={(this.state.EvaluationCriteria.Type || 0)}
                                    onChange={e => this.onChangeContent(e.target.name, (parseInt(e.target.value) || 0), 'EvaluationCriteria')}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    <option value={1}>เชิงคุณภาพ</option>
                                    <option value={2}>เชิงปริมาณ</option>
                                </select>
                            </div>

                            <div className='col-12 col-lg-6 form-group'>
                                {
                                    (this.state.EvaluationCriteria.Type === 2) ?
                                        <>
                                            <label className='form-label'>หน่วยการประเมิน</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='Unit'
                                                placeholder='หน่วยการประเมิน'
                                                value={(this.state.EvaluationCriteria.Unit || '')}
                                                onChange={e => this.onChangeContent(e.target.name, e.target.value, 'EvaluationCriteria')}
                                            />
                                        </>
                                        : null
                                }
                            </div>
                            <div className='col-12 col-lg-6 form-group'>
                                <label className='form-label'>คะแนนเต็ม</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='คะแนนเต็ม'
                                    name='PointFull'
                                    value={(this.state.EvaluationCriteria.PointFull || 0)}
                                    onChange={e => this.onChangeContent(e.target.name, (parseInt(e.target.value) || 0), 'EvaluationCriteria')}
                                />
                            </div>
                            <div className='col-12 col-lg-6 form-group'>
                                <label className='form-label'>คะแนนผ่าน</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='คะแนนผ่าน'
                                    name='PointPass'
                                    value={(this.state.EvaluationCriteria.PointPass || 0)}
                                    onChange={e => this.onChangeContent(e.target.name, (parseInt(e.target.value) || 0), 'EvaluationCriteria')}
                                />
                            </div>
                            <div className='col-12 col-lg-6 form-group'>
                                <label className='form-label'>ค่าน้ำหนัก</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='ค่าน้ำหนัก'
                                    name='Weight'
                                    value={(this.state.EvaluationCriteria.Weight || 0)}
                                    onChange={e => this.onChangeContent(e.target.name, (parseInt(e.target.value) || 0), 'EvaluationCriteria')}
                                />
                            </div>
                        </div>
                        <div className='form-group overflow-div'>
                            <label className='text-primary'>ตารางค่าน้ำหนักของตัวชี้วัด</label>
                            <table className='table table-custom table-striped table-bordered table-hover'>
                                <thead align='center'>
                                    <tr>
                                        <th style={{ width: '60%' }}>ตัวชี้วัด</th>
                                        <th style={{ width: '20%' }}>ค่าน้ำหนัก</th>
                                        <th style={{ width: '20%' }}>ค่าน้ำหนักสะสม</th>
                                    </tr>
                                </thead>
                                <tbody align='center'>
                                    {this.renderWeightTable()}
                                </tbody>
                            </table>
                        </div>
                    </>
                );
            }
            case 1: {
                return (
                    <>

                        <label className='text-primary'>เกณฑ์การประเมินรายปี</label>
                        <table className='table table-custom table-striped table-bordered table-hover'>
                            <thead align='center'>
                                <tr>
                                    <th style={{ minWidth: '150px', verticalAlign: 'middle' }}>หัวข้อ</th>
                                    {
                                        (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                            null :
                                            <th style={{ minWidth: '150px' }}>
                                                เป้าหมายขั้นต่ำสุด
                                                {" (" + (this.state.EvaluationCriteria.VeryLow.Result || '0') + ")"}
                                            </th>
                                    }
                                    <th style={{ minWidth: '150px' }}>
                                        เป้าหมายขั้นต่ำ
                                        {" (" + (this.state.EvaluationCriteria.Low.Result || '0') + ")"}
                                    </th>
                                    <th style={{ minWidth: '150px' }}>
                                        เป้าหมายขั้นมาตรฐาน
                                        {" (" + (this.state.EvaluationCriteria.Mid.Result || '0') + ")"}
                                    </th>
                                    <th style={{ minWidth: '150px' }}>
                                        เป้าหมายขั้นสูง
                                        {" (" + (this.state.EvaluationCriteria.High.Result || '0') + ")"}
                                    </th>
                                    {
                                        (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                            null :
                                            <th style={{ minWidth: '150px' }}>
                                                เป้าหมายขั้นสูงสุด
                                                {" (" + (this.state.EvaluationCriteria.VeryHigh.Result || '0') + ")"}
                                            </th>
                                    }
                                </tr>
                                <tr>
                                    <th style={{ minWidth: '150px', verticalAlign: 'middle' }}>คะแนนที่จะได้รับ</th>
                                    {
                                        (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                            null :
                                            <th>
                                                <input
                                                    className='form-control'
                                                    placeholder='คะแนนที่จะได้รับ'
                                                    value={(this.state.EvaluationCriteria.VeryLow.Result || '')}
                                                    onChange={e => this.onChangeContent('VeryLow', (parseInt(e.target.value) || 0), 'EvaluationCriteria', 'Result')}
                                                />
                                            </th>
                                    }
                                    <th>
                                        <input
                                            className='form-control'
                                            placeholder='คะแนนที่จะได้รับ'
                                            value={(this.state.EvaluationCriteria.Low.Result || '')}
                                            onChange={e => this.onChangeContent('Low', (parseInt(e.target.value) || 0), 'EvaluationCriteria', 'Result')}
                                        />
                                    </th>
                                    <th>
                                        <input
                                            className='form-control'
                                            placeholder='คะแนนที่จะได้รับ'
                                            value={(this.state.EvaluationCriteria.Mid.Result || '')}
                                            onChange={e => this.onChangeContent('Mid', (parseInt(e.target.value) || 0), 'EvaluationCriteria', 'Result')}
                                        />
                                    </th>
                                    <th>
                                        <input
                                            className='form-control'
                                            placeholder='คะแนนที่จะได้รับ'
                                            value={(this.state.EvaluationCriteria.High.Result || '')}
                                            onChange={e => this.onChangeContent('High', (parseInt(e.target.value) || 0), 'EvaluationCriteria', 'Result')}
                                        />
                                    </th>
                                    {
                                        (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                            null :
                                            <th>
                                                <input
                                                    className='form-control'
                                                    placeholder='คะแนนที่จะได้รับ'
                                                    value={(this.state.EvaluationCriteria.VeryHigh.Result || '')}
                                                    onChange={e => this.onChangeContent('VeryHigh', (parseInt(e.target.value) || 0), 'EvaluationCriteria', 'Result')}
                                                />
                                            </th>
                                    }
                                </tr>
                            </thead>
                            <tbody align='center'>
                                {
                                    (this.state.EvaluationCriteria.Type === 2) ?
                                        <tr>
                                            <td>ผลการดำเนินงาน</td>
                                            {
                                                (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                                    null :
                                                    this.renderInputWithUnit('VeryLow')
                                            }
                                            {this.renderInputWithUnit('Low')}
                                            {this.renderInputWithUnit('Mid')}
                                            {this.renderInputWithUnit('High')}
                                            {
                                                (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                                    null :
                                                    this.renderInputWithUnit('VeryHigh')
                                            }
                                        </tr>
                                        :
                                        <tr>
                                            <td>คำอธิบาย</td>
                                            {
                                                (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                                    null :
                                                    <td>
                                                        <textarea
                                                            className='form-control'
                                                            rows='3'
                                                            value={(this.state.EvaluationCriteria.VeryLow.Description || '')}
                                                            onChange={e => this.onChangeContent('VeryLow', e.target.value, 'EvaluationCriteria', 'Description')}
                                                        />
                                                    </td>
                                            }
                                            <td>
                                                <textarea
                                                    className='form-control'
                                                    rows='3'
                                                    value={(this.state.EvaluationCriteria.Low.Description || '')}
                                                    onChange={e => this.onChangeContent('Low', e.target.value, 'EvaluationCriteria', 'Description')}
                                                />
                                            </td>
                                            <td>
                                                <textarea
                                                    className='form-control'
                                                    rows='3'
                                                    value={(this.state.EvaluationCriteria.Mid.Description || '')}
                                                    onChange={e => this.onChangeContent('Mid', e.target.value, 'EvaluationCriteria', 'Description')}
                                                />
                                            </td>
                                            <td>
                                                <textarea
                                                    className='form-control'
                                                    rows='3'
                                                    value={(this.state.EvaluationCriteria.High.Description || '')}
                                                    onChange={e => this.onChangeContent('High', e.target.value, 'EvaluationCriteria', 'Description')}
                                                />
                                            </td>
                                            {
                                                (this.state.EvaluationTypeID === 3 || this.state.EvaluationTypeID === 6) ?
                                                    null :
                                                    <td>
                                                        <textarea
                                                            className='form-control'
                                                            rows='3'
                                                            value={(this.state.EvaluationCriteria.VeryHigh.Description || '')}
                                                            onChange={e => this.onChangeContent('VeryHigh', e.target.value, 'EvaluationCriteria', 'Description')}
                                                        />
                                                    </td>
                                            }
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </>
                );
            }
            default: return null;
        }
    }

    renderWeightTable = () => {
        var el = [];
        var totalWeight = (this.state.EvaluationCriteria.Weight || 0);
        el.push((
            <tr key='weightTable-kpi'>
                <td>{this.state.KPIName}</td>
                <td>{(this.state.EvaluationCriteria.Weight || 0)}</td>
                <td>{totalWeight}</td>
            </tr>
        ));
        this.state.ListKPIs.forEach((obj, index) => {
            if (obj.KPIID === this.props.currentKPIID) return;
            totalWeight += obj.EvaluationCriteria.Weight;
            el.push((
                <tr key={'weightTable-kpi' + index}>
                    <td>{obj.KPIName}</td>
                    <td>{(obj.EvaluationCriteria.Weight || 0)}</td>
                    <td>{totalWeight}</td>
                </tr>
            ));
        });
        return el;
    }

    renderSection5 = () => {
        return (
            <>
                <div className='form-group mt-3'>
                    <label className='form-label'>
                        แบบฟอร์ม
                        <button className='btn btn-outline-primary btn-sm ml-3' onClick={() => window.open(window.origin + '/PMQAKPI/FormManagement?create=true')}>
                            เพิ่มแบบฟอร์ม
                        </button>
                    </label>
                    <div className='text-primary custom-block pt-2 mb-3'>
                        แบบฟอร์มปัจจุบัน : {this.state.Form.FormName}
                        {
                            (this.state.Form.FormID) ?
                                <span
                                    className='delete-badge badge badge-danger border border-danger ml-2'
                                    onClick={() => this.onChangeContent('Form', {}, 'Form')}
                                >
                                    <i className='fa fa-times' />
                                </span>
                                : null
                        }
                        {
                            (this.state.Form.Remark && this.state.Form.Remark !== '') ?
                                <div>{'คำอธิบาย : ' + this.state.Form.Remark}</div>
                                : null
                        }
                    </div>
                    <div className='d-flex'>
                        <div className='flex-fill'>
                            <Autocomplete
                                getData={this.props.searchForm}
                                name='searchForm'
                                labelName='แบบฟอร์ม'
                                dataName='Form'
                                listName='ListForm'
                                onChangeSelect={this.onChangeContent}
                                selectValue={this.state.searchForm}
                                searchOnChange
                            />
                        </div>
                        <button
                            className='btn btn-outline-primary ml-2'
                            onClick={() => this.onChangeContent('Form', this.state.searchForm, 'Form')}
                        >
                            เลือกแบบฟอร์ม
                        </button>
                    </div>
                </div>
            </>
        );
    }

    renderSection6 = () => {
        return (
            <>
                {
                    this.state.sectionName.map((obj, index) => {
                        if (index === 5) return null;
                        return (
                            <div className='KPI-modal-card' key={'KPIManage-modal-section6-step' + index}>
                                <div className='KPI-modal-card-title' onClick={() => { this.manageSection6Collapse(index) }}>
                                    {obj}
                                    <div className='float-right position-relative'>
                                        <i className={'text-secondary fa fa-chevron-left pr-1 ' + ((this.state.section6SubSection === index) ? 'hide' : '')} />
                                        <i className={'text-secondary fa fa-chevron-down ' + ((this.state.section6SubSection === index) ? '' : 'hide')} />
                                    </div>
                                </div>
                                <Collapse in={(this.state.section6SubSection === index)}>
                                    <div>
                                        <div className='pt-0 pl-3 pr-3 pb-3 mt-3 position-relative'>
                                            {this.renderModalBody(index)}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        );
                    })
                }
            </>
        );
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='xl' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                (this.props.currentKPIID === null) ?
                                    'เพิ่มตัวชี้วัด' :
                                    'แก้ไขตัวชี้วัด'
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='row'>
                                <div className='col-3'>
                                    <div className='font-title'>ปีงบประมาณ :</div>
                                    {(this.state.Year === 0 || this.state.Year == null) ? '' : this.state.Year + 543}
                                </div>
                                <div className='col-3'>
                                    <div className='font-title'>การประเมิน :</div>
                                    <div className='ml-2 text-break'>{this.checkEvaluationType(this.state.EvaluationTypeID)}</div>
                                </div>
                                <div className='col-6'>
                                    <div className='font-title'>{(this.state.EvaluationTypeID === 3) ? 'ตัวชี้วัด' : 'ตัวบ่งชี้'} :</div>
                                    <div className='ml-2 text-break'>{this.state.KPIName}</div>
                                </div>
                            </div>
                            <div className='status-bar-wrapper'>
                                <ul className="status-bar form-manage-modal-status-bar ">
                                    {
                                        this.state.sectionName.map((obj, index) => {
                                            return (
                                                <li key={'KPIManage-modal-section' + index} className={this.checkStepProgressbar(index)} onClick={() => this.manageCurrentSection(index)}>
                                                    <span className='status-bar-text'>
                                                        <div>{obj}</div>
                                                    </span>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        <hr />

                        <div className='pl-2 pr-2 position-relative' style={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                            {this.renderModalBody(this.state.currentSection)}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className={'btn btn-outline-' + ((!this.state.Editable) ? 'secondary' : 'primary')}
                                onClick={() => this.onSave()}
                                disabled={(!(this.state.Editable) || false)}
                            >
                                บันทึก
                            </button>
                            <div className='ml-4 d-inline-block'>
                                {
                                    (this.state.currentSection === 0) ?
                                        null :
                                        <button
                                            className='btn btn-outline-secondary mr-2'
                                            onClick={() => this.manageCurrentSection(this.state.currentSection - 1)}>
                                            <i className='fa fa-arrow-left' />
                                        </button>
                                }
                                {
                                    (this.state.currentSection === 5) ?
                                        null :
                                        <button
                                            className='btn btn-outline-secondary'
                                            onClick={() => this.manageCurrentSection(this.state.currentSection + 1)}>
                                            <i className='fa fa-arrow-right' />
                                        </button>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default KPIManageModal;