import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { renderYear } from '../../../Utilities/Date';
import DatePicker from '../../../Utilities/Datepicker';
//import Collapse from 'react-bootstrap/Collapse';
import "react-datepicker/dist/react-datepicker.css";

const PMQASectionName = [
    'หมวด 1 การนำองค์การ',
    'หมวด 2 การวางแผนเชิงยุทธศาสตร์',
    'หมวด 3 การให้ความสำคัญกับผู้รับบริการและผู้มีส่วนได้ส่วนเสีย',
    'หมวด 4 การวัด การวิเคราะห์ และการจัดการความรู้',
    'หมวด 5 การมุ่งเน้นบุคลากร',
    'หมวด 6 การมุ่งเน้นระบบปฏิบัติการ',
    'หมวด 7 ผลลัพธ์การดำเนินการ'
]

class EvaluationManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEvaluationType: 0,
            currentYear: 0,
            periodStart: null,
            periodEnd: null,
            currentPolicy: 0,
            currentMainKPI: null,
            scheduleID: 0,
            mainKPIs: [],
            delMainKPIs: [],
            managingMainKPI: false,
            PMQASection: ['หมวด 1', 'หมวด 2', 'หมวด 3', 'หมวด 4', 'หมวด 5', 'หมวด 6', 'หมวด 7'],
            mainKPIName: ['Functional Based', 'Agenda Based', 'Area Based', 'Innovation Based', 'Potential Based'],
            policyOrganization: ['ยุทธศาสตร์', 'ม.44', 'ประเมินผู้บริหารองค์การ', 'แผนพัฒนาองค์การ', 'พ.ร.บ. งบประมาณ'],
            Strategy: [{ StrategyName: 'ยุทธศาสตร์ที่ 1', ListObjective: [] }],
            SupportID: 0,
            SupportList: [],
            canUpdate: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        var title = (this.props.data) ? 'ยกเลิกการแก้ไขการประเมิน' : 'ยกเลิกการเพิ่มการประเมิน'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.props.hide(this.resetState);
                }
            })

    }

    onEnterModal = () => {
        console.log(this.props)
        if (this.props.data === null) {
            this.setState({ canUpdate: true });
            return;
        }
        this.props.getFormDetailModal()
            .then(result => {
                console.log(result)
                if (result.error) {
                    this.setState({ canUpdate: true });
                    return;
                }
                var data = result.data.GetEvaluationDetail;
                console.log(data.Strategy);
                this.setState({
                    currentEvaluationType: data.EvaluationTypeID,
                    mainKPIs: ((data.KPIMains) ? data.KPIMains : []),
                    Strategy: ((data.Strategy) ? data.Strategy : [{ StrategyName: 'ยุทธศาสตร์ที่ 1', ListObjective: [] }]),
                    currentYear: data.Year,
                    periodStart: new Date(data.PeriodStart),
                    periodEnd: new Date(data.PeriodEnd),
                    scheduleID: data.ScheduleID,
                    delMainKPIs: [],
                    SupportID: data.SupportStrategyID,
                    SupportList: [],
                    currentPolicy: 0,
                    currentMainKPI: null,
                    managingMainKPI: false,
                    canUpdate: true
                }, () => {
                    if (this.state.currentEvaluationType === 4) {
                        this.getListSupport();
                    }
                });
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
                this.setState({ canUpdate: true }, () => {
                    this.props.hide(this.resetState);
                })
            })
    }

    resetState = () => {
        this.setState({
            currentEvaluationType: 0,
            currentYear: 0,
            periodStart: null,
            periodEnd: null,
            currentPolicy: 0,
            currentMainKPI: null,
            scheduleID: 0,
            mainKPIs: [],
            delMainKPIs: [],
            managingMainKPI: false,
            Strategy: [{ StrategyName: 'ยุทธศาสตร์ที่ 1', ListObjective: [] }],
            SupportID: 0,
            SupportList: [],
            canUpdate: false
        })
    }

    renderEvaluationType = () => {
        if (this.props.evaluationType === undefined) return null;
        return this.props.evaluationType.map((obj, index) => {
            if (obj === undefined) return null;
            return (
                <option key={'EvaluationOption' + index} value={obj.EvaluationTypeID}>
                    {obj.EvaluationTypeName}
                </option>
            );
        });
    }

    onChangeEvaluationType = (value) => {
        if (value === this.state.currentEvaluationType) return;
        this.setState({
            currentEvaluationType: value,
            mainKPIs: [],
            delMainKPIs: [],
            managingMainKPI: false,
            currentMainKPI: null,
            Strategy: [{ StrategyName: 'ยุทธศาสตร์ที่ 1', ListObjective: [] }],
            SupportID: 0,
            currentPolicy: 0
        }, () => {
            if (this.state.currentEvaluationType === 4) this.getListSupport();
        });
    }

    onChangeOption = (name, value) => {
        console.log(value)
        this.setState({ [name]: value });
    }

    createKPIObject = (index, count) => {
        return {
            PolicyID: index,
            KPIMainName: 'ตัวชี้วัดที่ ' + index + '.' + count + ' ',
            Remark: '',
            EvaluationCriteria: {
                Weight: 0,
                PointPass: 0,
                PointFull: 0,
                Low: {
                    Target: 0,
                    Result: 0
                },
                Mid: {
                    Target: 0,
                    Result: 0
                },
                High: {
                    Target: 0,
                    Result: 0
                }
            }
        }
    }

    createObjective = (StrategyIndex, index) => {
        return {
            ObjectiveName: 'เป้าประสงค์ที่ ' + StrategyIndex + '.' + index + ' '
        }
    }

    getYear = () => {
        return renderYear('EvaluationManage');
    }

    getListSupport = () => {
        this.props.getListStrategy(null, 1)
            .then(result => {
                if (result.errors) {
                    return;
                }
                result = result.data.GetListStrategy;
                this.setState({
                    SupportList: (result) ? result : []
                })
            })
            .catch(e => {
                console.log(e);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                    type: 'error'
                })
            })
    }

    addMainKPI = (index) => {
        var newMainKPIs = [...this.state.mainKPIs];
        var type = (this.props.evaluationType === 3) ? this.props.Policy[index].PolicyID : this.props.PolicyOrganization[index].PolicyOrganizationID;
        var newMainKPI = this.createKPIObject(type, ((this.state.mainKPIs.filter(x => x.PolicyID === index).length) + 1));
        newMainKPIs.push(newMainKPI);
        console.log(newMainKPI);
        this.setState({ mainKPIs: newMainKPIs });
    }

    removeMainKPI = (index) => {
        var kpiName = (this.state.mainKPIs[index].KPIMainName === '') ? 'ตัวชี้วัดหลักนี้' : '"' + this.state.mainKPIs[index].KPIMainName + '" ';
        Swal.fire({
            title: 'ยืนยันการลบตัวชี้วัดหลัก',
            text: 'ต้องการลบ' + kpiName + 'หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    var newMainKPIs = [...this.state.mainKPIs];
                    var newDelMainKPIs = [...this.state.delMainKPIs];
                    if (newMainKPIs[index].KPIMainID) {
                        newDelMainKPIs.push({ KPIMainsID: newMainKPIs[index].KPIMainID });
                    }
                    newMainKPIs.splice(index, 1);
                    this.setState({
                        mainKPIs: newMainKPIs,
                        delMainKPIs: newDelMainKPIs
                    });
                }
            });
    }

    removeObjective = (index, count) => {
        var ObjectiveName = (this.state.Strategy[index].ListObjective[count].ObjectiveName === '') ?
            'เป้าประสงค์นี้' : '"' + this.state.Strategy[index].ListObjective[count].ObjectiveName + '" ';
        Swal.fire({
            title: 'ยืนยันการลบเป้าประสงค์',
            text: 'ต้องการลบ' + ObjectiveName + 'หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    var newStrategies = [...this.state.Strategy];
                    var newStrategy = { ...newStrategies[index] };
                    var newListObjectives = [...newStrategy.ListObjective];
                    var newDelMainKPIs = [...this.state.delMainKPIs];
                    var newObjectiveID = newStrategy.ListObjective[count].ObjectiveID;

                    if (newObjectiveID) {
                        var newDelStrategy = newDelMainKPIs.find(x => x.StrategyID === newStrategy.StrategyID);
                        if (newDelStrategy) {
                            newDelStrategy.ListObjective.push({ ObjectiveID: newObjectiveID });
                        }
                        else {
                            newDelMainKPIs.push({
                                StrategyID: newStrategy.StrategyID,
                                SelfDelete: false,
                                ListObjective: [{ ObjectiveID: newObjectiveID }]
                            });
                        }
                    }

                    newStrategy.ListObjective = newListObjectives;
                    newStrategies[index] = newStrategy;
                    newListObjectives.splice(count, 1);
                    this.setState({
                        Strategy: newStrategies,
                        delMainKPIs: newDelMainKPIs
                    }, () => {
                        console.log(this.state.delMainKPIs)
                    });
                }
            });
    }

    removePMQAObjective = (index) => {
        var kpiName = (this.state.mainKPIs[index].KPIMainName === '') ?
            'วัตถุประสงค์นี้' : '"' + this.state.mainKPIs[index].KPIMainName + '" ';
        Swal.fire({
            title: 'ยืนยันการลบวัตถุประสงค์',
            text: 'ต้องการลบ' + kpiName + 'หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    var newMainKPIs = [...this.state.mainKPIs];
                    var newDelMainKPIs = [...this.state.delMainKPIs];
                    if (newMainKPIs[index].KPIMainID) {
                        newDelMainKPIs.push({ KPIMainsID: newMainKPIs[index].KPIMainID });
                    }
                    newMainKPIs.splice(index, 1);
                    this.setState({
                        mainKPIs: newMainKPIs,
                        delMainKPIs: newDelMainKPIs
                    });
                }
            });
    }

    onChangeMainKPIName = (index, value, count) => {
        var newMainKPIs = [...this.state.mainKPIs];
        var newMainKPI = { ...newMainKPIs[index] };
        newMainKPI.KPIMainName = value;
        newMainKPIs[index] = newMainKPI;
        this.setState({ mainKPIs: newMainKPIs });
    }

    addStrategy = () => {
        var newStrategy = [...this.state.Strategy];
        newStrategy.push({ StrategyName: 'ยุทธศาสตร์ที่ ' + (newStrategy.length + 1), ListObjective: [] });
        this.setState({ Strategy: newStrategy }, () => { console.log(this.state) });
    }

    addObjective = (index) => {
        var newStrategies = [...this.state.Strategy];
        var newStrategy = newStrategies[index];
        newStrategy.ListObjective.push(this.createObjective(index + 1, newStrategy.ListObjective.length + 1));
        this.setState({ Strategy: newStrategies });
    }

    removeStrategy = (index) => {
        var StrategyName = (this.state.Strategy[index].StrategyName === '') ?
            'เป้าประสงค์นี้' : '"' + this.state.Strategy[index].StrategyName + '" ';
        Swal.fire({
            title: 'ยืนยันการลบเป้าประสงค์',
            text: 'ต้องการลบ' + StrategyName + 'หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (!res.value) return;
                var newStrategy = [...this.state.Strategy];
                var newDelMainKPIs = [...this.state.delMainKPIs];
                var newStrategyID = newStrategy[index].StrategyID;
                if (newStrategyID) {
                    var newDelMainKPI = newDelMainKPIs.find(x => x.StrategyID === newStrategyID);
                    if (newDelMainKPI) {
                        newDelMainKPI.SelfDelete = true;
                        newStrategy[index].ListObjective.forEach((obj) => {
                            if (obj.ObjectiveID)
                                newDelMainKPI.ListObjective.push({ ObjectiveID: obj.ObjectiveID });
                        });
                    }
                    else {
                        newDelMainKPI = {
                            StrategyID: newStrategyID,
                            SelfDelete: true,
                            ListObjective: []
                        };
                        newStrategy[index].ListObjective.forEach((obj) => {
                            if (obj.ObjectiveID)
                                newDelMainKPI.ListObjective.push({ ObjectiveID: obj.ObjectiveID });
                        });
                        newDelMainKPIs.push(newDelMainKPI);
                    }
                }
                newStrategy.splice(index, 1);
                this.setState({
                    Strategy: newStrategy,
                    delMainKPIs: newDelMainKPIs
                });
            });
    }

    onChangeStrategyName = (index, value) => {
        var newStrategy = [...this.state.Strategy];
        newStrategy[index].StrategyName = value;
        this.setState({ Strategy: newStrategy });
    }

    onChangeObjectiveName = (index, value, count) => {
        var newStrategies = [...this.state.Strategy];
        var newStrategy = { ...newStrategies[index] };
        var newListObjectives = [...newStrategy.ListObjective];
        var newListObjective = newListObjectives[count];
        newListObjective.ObjectiveName = value;
        newListObjectives[count] = newListObjective;
        newStrategy.ListObjective = newListObjectives;
        newStrategies[index] = newStrategy;
        this.setState({ Strategy: newStrategies });
    }

    createPMQAObjective = (PMQAIndex, index) => {
        return {
            PMQASectionID: PMQAIndex,
            KPIMainName: 'วัตถุประสงค์ที่ ' + PMQAIndex + '.' + index + ' '
        }
    }

    addPMQAObjective = (index) => {
        var newMainKPIs = [...this.state.mainKPIs];
        var count = 1;
        newMainKPIs.forEach(obj => {
            if (obj.PMQASectionID === index) count++;
        });
        var newMainKPI = this.createPMQAObjective(index, count);
        newMainKPIs.push(newMainKPI);
        this.setState({ mainKPIs: newMainKPIs });
    }

    onChangePMQAObjectiveName = (index, value) => {
        var newMainKPIs = [...this.state.mainKPIs];
        var newMainKPI = { ...newMainKPIs[index] };
        newMainKPI.KPIMainName = value;
        newMainKPIs[index] = newMainKPI;
        this.setState({ mainKPIs: newMainKPIs });
    }

    onChangeValue = (index, name, value, Type) => {
        var newMainKPIs = [...this.state.mainKPIs];
        var newMainKPI = { ...newMainKPIs[index] };
        // if (name === 'Weight') {
        //     if (value > 100) return;
        //     var weight = 0;
        //     newMainKPIs.map((obj, objIndex) => {
        //         if (obj.PolicyID === newMainKPI.PolicyID && objIndex !== index) {
        //             if (obj.EvaluationCriteria.Weight)
        //                 weight += obj.EvaluationCriteria.Weight;
        //         }
        //     });
        //     console.log((weight + value));
        //     if ((weight + value) > 100) return;
        // }
        if (Type) {
            (newMainKPI.EvaluationCriteria[name])[Type] = value;
        }
        else {
            newMainKPI.EvaluationCriteria[name] = value;
            if (name === 'PointPass') {
                if (value > newMainKPI.EvaluationCriteria.PointFull)
                    newMainKPI.EvaluationCriteria.PointPass = newMainKPI.EvaluationCriteria.PointFull;
            }
            else if (name === 'PointFull') {
                if (value < newMainKPI.EvaluationCriteria.PointPass)
                    newMainKPI.EvaluationCriteria.PointPass = value;
            }
        }
        newMainKPIs[index] = newMainKPI;
        this.setState({ mainKPIs: newMainKPIs }, () => { console.log(this.state) });
    }

    checkActiveTab = (index) => {
        if (this.state.currentPolicy === index)
            return 'nav-link active';
        return 'nav-link badge-secondary';
    }

    managecurrentPolicy = (index) => {
        if (this.state.currentPolicy !== index)
            this.setState({
                currentPolicy: index,
                currentMainKPI: null,
                managingMainKPI: false
            });
    }

    managecurrentMainKPI = (index) => {
        if (this.state.currentMainKPI === index)
            this.setState({ currentMainKPI: null });
        else
            this.setState({ currentMainKPI: index });
    }

    checkUpdate = () => {
        var title = 'เกิดข้อผิดพลาด';
        var text = 'เกิดข้อผิดพลาด';
        var type = null;
        if (this.state.currentEvaluationType === 0) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาเลือกประเภทการประเมิน';
            type = 'warning';
        }
        else if (this.state.currentYear === 0) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาเลือกปีงบประมาณ';
            type = 'warning';
        }
        else if (!this.state.periodStart || !this.state.periodEnd) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาระบุช่วงเวลาในการประเมิน';
            type = 'warning';
        }
        // else if (this.state.currentEvaluationType === 3 && this.state.mainKPIs.length === 0) {
        //     title = 'กรุณาตรวจสอบข้อมูล';
        //     text = 'กรุณาสร้างองค์ประกอบอย่างน้อย 1 องค์ประกอบ';
        //     type = 'warning';
        // }
        else if (this.state.scheduleID === 0 || this.state.scheduleID === null) {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณาเลือกช่วงเวลา';
            type = 'warning';
        }
        if (this.state.currentEvaluationType === 1 || this.state.currentEvaluationType === 2) {
            for (var i = 0; i < this.state.Strategy.length; i++) {
                if (this.state.Strategy[i].StrategyName === '' || this.state.Strategy[i].StrategyName.trim() === '') {
                    title = 'กรุณาตรวจสอบข้อมูล';
                    text = 'กรุณาตรวจสอบชื่อยุทธศาสตร์';
                    type = 'warning';
                    break;
                }
                else if (this.state.Strategy[i].ListObjective.length === 0) {
                    title = 'กรุณาตรวจสอบข้อมูล';
                    text = 'กรุณาสร้างเป้าประสงค์ใน "' + this.state.Strategy[i].StrategyName + '"';
                    type = 'warning';
                    break;
                }
                for (var j = 0; j < this.state.Strategy[i].ListObjective.length; j++) {
                    if (
                        this.state.Strategy[i].ListObjective[j].ObjectiveName === '' ||
                        this.state.Strategy[i].ListObjective[j].ObjectiveName.trim() === ''
                    ) {
                        title = 'กรุณาตรวจสอบข้อมูล';
                        text = 'กรุณาระบุชื่อเป้าประสงค์ใน "' + this.state.Strategy[i].StrategyName + '"';
                        type = 'warning';
                        break;
                    }
                }
                if (type !== null) break;
            }
        }
        if (this.state.currentEvaluationType === 3) {
            var checkWeight = Array.from({ length: 5 }, () => ({ Weight: 0, KPILength: 0 }));
            for (var i of this.state.mainKPIs) {
                var errText = '';
                if (i.KPIMainName === '' || i.KPIMainName.trim() === '') {
                    errText = 'ชื่อ'
                }
                else if (i.EvaluationCriteria.PointPass > i.EvaluationCriteria.PointFull) {
                    errText = 'คะแนนผ่านและคะแนนเต็มของ'
                }
                else if (!i.EvaluationCriteria.Weight || i.EvaluationCriteria.Weight === 0) {
                    errText = 'ค่าน้ำหนักของ'
                }
                else if (!i.EvaluationCriteria.Mid.Result || !i.EvaluationCriteria.High.Result) {
                    errText = 'ค่าคะแนนที่ได้รับของ'
                }
                else if (i.EvaluationCriteria.Mid.Result > i.EvaluationCriteria.High.Result) {
                    errText = 'ค่าคะแนนที่ได้รับของ'
                }
                else if (i.EvaluationCriteria.Low.Result > i.EvaluationCriteria.Mid.Result) {
                    errText = 'ค่าคะแนนที่ได้รับของ'
                }
                else if (!i.EvaluationCriteria.Mid.Target || !i.EvaluationCriteria.High.Target) {
                    errText = 'เกณฑ์คะแนนของ'
                }
                else if (i.EvaluationCriteria.Mid.Target > i.EvaluationCriteria.High.Target) {
                    errText = 'เกณฑ์คะแนนของ'
                }
                else if (i.EvaluationCriteria.Low.Target > i.EvaluationCriteria.Mid.Target) {
                    errText = 'เกณฑ์คะแนนของ'
                }
                if (errText !== '') {
                    Swal.fire({
                        title: 'กรุณาตรวจสอบข้อมูล',
                        text: 'กรุณาตรวจสอบ' + errText + 'ตัวชี้วัดหลักในองค์ประกอบที่ ' + i.PolicyID,
                        type: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'ไปยังองค์ประกอบ',
                        cancelButtonText: 'ตกลง',
                        reverseButtons: true
                    }).then(res => {
                        if (res.value) {
                            this.setState({
                                currentPolicy: parseInt(i.PolicyID - 1)
                            });
                        }
                    });
                    return;
                }
                checkWeight[i.PolicyID - 1].Weight += ((i.EvaluationCriteria.Weight) ? i.EvaluationCriteria.Weight : 0);
                checkWeight[i.PolicyID - 1].KPILength += 1;
            }

            for (var j in checkWeight) {
                if (checkWeight[j].KPILength === 0) continue;
                console.log(checkWeight[j]);
                if (checkWeight[j].Weight > 100) {
                    Swal.fire({
                        title: 'กรุณาตรวจสอบข้อมูล',
                        text: 'กรุณาตรวจสอบค่านำหนักของชี้วัดหลักในองค์ประกอบที่ ' + (parseInt(j) + 1),
                        type: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'ไปยังองค์ประกอบ',
                        cancelButtonText: 'ตกลง',
                        reverseButtons: true
                    }).then(res => {
                        if (res.value) {
                            this.setState({
                                currentPolicy: parseInt(j)
                            });
                        }
                    });
                    return false;
                }
            }
        }
        if (this.state.currentEvaluationType === 4) {
            if (this.state.SupportID === 0) {
                title = 'กรุณาตรวจสอบข้อมูล';
                text = 'กรุณาระบุสนับสนุนยุทธศาสตร์'
                type = 'warning';
            }
            // else if (this.state.mainKPIs.length === 0) {
            //     title = 'กรุณาตรวจสอบข้อมูล';
            //     text = 'กรุณาสร้างวัตถุประสงค์'
            //     type = 'warning';
            // }
            for (var i of this.state.mainKPIs) {
                if (i.KPIMainName === '' || i.KPIMainName.trim() === '') {
                    Swal.fire({
                        title: 'กรุณาตรวจสอบข้อมูล',
                        text: 'กรุณาตรวจสอบชื่อวัตถุประสงค์ในหมวดที่ ' + i.PMQASectionID,
                        type: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'ไปยังหมวดที่ ' + i.PMQASectionID,
                        cancelButtonText: 'ตกลง',
                        reverseButtons: true
                    }).then(res => {
                        if (res.value) {
                            this.setState({
                                currentPolicy: parseInt(i.PMQASectionID - 1)
                            });
                        }
                    });
                    return;
                }
            }
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (this.checkUpdate()) {
            this.props.createEvaluation(
                this.state.currentYear,
                this.state.currentEvaluationType,
                ((this.state.currentEvaluationType === 1 || this.state.currentEvaluationType === 2) ?
                    this.state.Strategy : this.state.mainKPIs),
                this.state.periodStart.getFullYear() + '-' + (this.state.periodStart.getMonth() + 1) + '-' + this.state.periodStart.getDate(),
                this.state.periodEnd.getFullYear() + '-' + (this.state.periodEnd.getMonth() + 1) + '-' + this.state.periodEnd.getDate(),
                this.state.scheduleID,
                this.state.SupportID
            )
                .then(result => {
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                        this.props.hide(this.resetState);
                    }
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                        type: 'error'
                    })
                })
        }
    }

    updateEvaluation = () => {
        console.log(this.state)
        // if (this.state.currentEvaluationType !== 3) {
        //     this.props.hide(this.resetState)
        //     return;
        // }
        if (this.checkUpdate()) {
            this.props.updateEvaluation(
                this.state.currentYear,
                this.state.currentEvaluationType,
                ((this.state.currentEvaluationType === 1 || this.state.currentEvaluationType === 2) ?
                    this.state.Strategy : this.state.mainKPIs),
                this.state.periodStart.getFullYear() + '-' + (this.state.periodStart.getMonth() + 1) + '-' + this.state.periodStart.getDate(),
                this.state.periodEnd.getFullYear() + '-' + (this.state.periodEnd.getMonth() + 1) + '-' + this.state.periodEnd.getDate(),
                this.state.scheduleID,
                this.state.delMainKPIs,
                this.state.SupportID
            )
                .then(result => {
                    console.log(result)
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        });
                        this.onEnterModal();
                    }
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                        type: 'error'
                    });
                })
        }
    }

    renderMainKPIs = (obj) => {
        var weight = 0;
        return (
            <>
                <div
                    className={
                        'overflow-div Evaluation-div '
                        + (!(this.state.managingMainKPI) ? '' : 'Evaluation-div-disable')
                    }
                >
                    <table className='table table-custom table-striped table-bordered table-hover mb-0'>
                        <thead align='center'>
                            <tr>
                                <th style={{ width: '40%' }}>ชื่อตัวชี้วัดหลัก</th>
                                <th style={{ width: '20%' }}>ค่าน้ำหนัก</th>
                                <th style={{ width: '20%' }}>ค่าน้ำหนักสะสม</th>
                                <th style={{ width: '20%' }}>แก้ไข / ลบ</th>
                            </tr>
                        </thead>
                        <tbody align='center'>
                            {
                                this.state.mainKPIs.map((object, index) => {
                                    if (parseInt(this.state.currentPolicy) + 1 !== object.PolicyID) return null;
                                    weight += object.EvaluationCriteria.Weight;
                                    var thisWeight = weight;
                                    return (
                                        <tr key={'PolicyManage-MainKPIs' + index} className='input-transition'>
                                            <td
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    this.setState({
                                                        managingMainKPI: !(this.state.managingMainKPI),
                                                        currentMainKPI: index
                                                    });
                                                }}
                                            >
                                                {object.KPIMainName}
                                            </td>
                                            <td>{(object.EvaluationCriteria.Weight || 0)}</td>
                                            <td>{thisWeight}</td>
                                            <td>
                                                <button
                                                    className='ml-2 btn btn-outline-secondary btn-sm'
                                                    onClick={() => {
                                                        this.setState({ managingMainKPI: !(this.state.managingMainKPI), currentMainKPI: index })
                                                    }}
                                                >
                                                    <i className='fa fa-pencil' />
                                                </button>
                                                <button className='ml-2 btn btn-outline-danger btn-sm' onClick={() => this.removeMainKPI(index)}>
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className={'Evaluation-div ' + ((this.state.managingMainKPI) ? '' : 'Evaluation-div-disable')}>
                    <div>
                        <button
                            className='btn btn-outline-secondary'
                            onClick={() => { this.setState({ managingMainKPI: !(this.state.managingMainKPI) }) }}
                        >
                            <i className='fa fa-arrow-left'></i>
                        </button>
                        {
                            (obj) ?
                                <div>
                                    <div className='p-3'>
                                        <div className='form-group'>
                                            <i className='text-danger mr-1'>*</i><label className='form-label'>ชื่อตัวชี้วัด :</label>
                                            <input
                                                className='form-control'
                                                value={obj.KPIMainName}
                                                onChange={e => this.onChangeMainKPIName(this.state.currentMainKPI, e.target.value)}
                                            />
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-12 col-lg-4'>
                                                <i className='text-danger mr-1'>*</i><label className='form-label'>น้ำหนัก :</label>
                                                <input
                                                    name='Weight'
                                                    className='form-control'
                                                    value={(obj.EvaluationCriteria.Weight || 0)}
                                                    onChange={e => this.onChangeValue(this.state.currentMainKPI, e.target.name, parseInt(e.target.value || 0))}
                                                />
                                                <small className='text-danger'> *ค่าน้ำหนักรวมในองค์ประกอบต้องไม่เกิน 100</small>
                                            </div>
                                            <div className='form-group col-12 col-lg-4'>
                                                <i className='text-danger mr-1'>*</i><label className='form-label'>คะแนนเต็ม :</label>
                                                <input
                                                    name='PointFull'
                                                    className='form-control'
                                                    value={(obj.EvaluationCriteria.PointFull || 0)}
                                                    onChange={e => this.onChangeValue(this.state.currentMainKPI, e.target.name, parseInt(e.target.value || 0))}
                                                />
                                            </div>
                                            <div className='form-group col-12 col-lg-4'>
                                                <i className='text-danger mr-1'>*</i><label className='form-label'>คะแนนผ่าน :</label>
                                                <input
                                                    name='PointPass'
                                                    className='form-control'
                                                    value={(obj.EvaluationCriteria.PointPass || 0)}
                                                    onChange={e => this.onChangeValue(this.state.currentMainKPI, e.target.name, parseInt(e.target.value || 0))}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <i className='text-danger mr-1'>*</i><label className='form-label'>เกณฑ์การประเมิน</label>
                                            <table className='table mb-0 table-custom table-striped table-bordered table-hover'>
                                                <thead align='center'>
                                                    <tr>
                                                        <th>หัวข้อ</th>
                                                        <th>
                                                            <i className='text-danger mr-1'>*</i>
                                                            เป้าหมายขั้นต่ำ
                                                            {" (" + (obj.EvaluationCriteria.Low.Result || '0') + ")"}
                                                        </th>
                                                        <th>
                                                            <i className='text-danger mr-1'>*</i>
                                                            เป้าหมายขั้นมาตรฐาน
                                                            {" (" + (obj.EvaluationCriteria.Mid.Result || '0') + ")"}
                                                        </th>
                                                        <th>
                                                            <i className='text-danger mr-1'>*</i>
                                                            เป้าหมายขั้นสูง
                                                            {" (" + (obj.EvaluationCriteria.High.Result || '0') + ")"}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>คะแนนที่จะได้รับ</th>
                                                        <th>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.Low.Result || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'Low', parseInt(e.target.value || 0), 'Result')}
                                                            />
                                                        </th>
                                                        <th>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.Mid.Result || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'Mid', parseInt(e.target.value || 0), 'Result')}
                                                            />
                                                        </th>
                                                        <th>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.High.Result || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'High', parseInt(e.target.value || 0), 'Result')}
                                                            />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>เกณฑ์คะแนน</td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.Low.Target || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'Low', parseInt(e.target.value || 0), 'Target')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.Mid.Target || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'Mid', parseInt(e.target.value || 0), 'Target')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.High.Target || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'High', parseInt(e.target.value || 0), 'Target')}
                                                            />
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>คะแนนที่จะได้รับ</td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.Low.Result || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'Low', parseInt(e.target.value || 0), 'Result')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.Mid.Result || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'Mid', parseInt(e.target.value || 0), 'Result')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                className='form-control'
                                                                value={(obj.EvaluationCriteria.High.Result || 0)}
                                                                onChange={e => this.onChangeValue(this.state.currentMainKPI, 'High', parseInt(e.target.value || 0), 'Result')}
                                                            />
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                : null}
                    </div>
                </div>
            </>
        )
    }

    renderObjective = (object) => {
        var count = 0;
        if (!object) return null;
        return (
            <div>
                {
                    object.map((obj, index) => {
                        count++;
                        var thisCount = count;
                        return (
                            <div className='d-flex justify-content-center align-items-center form-group input-transition' key={'objective-' + index}>
                                <span className='badge badge-secondary mr-2'><h5 className='m-0'>{thisCount}</h5></span>
                                <input
                                    className='form-control flex-fill'
                                    value={obj.ObjectiveName}
                                    onChange={e => this.onChangeObjectiveName(this.state.currentPolicy, e.target.value, index)}
                                />
                                <button
                                    className='btn btn-outline-danger ml-2'
                                    onClick={() => this.removeObjective(this.state.currentPolicy, index)}
                                >
                                    <i className='fa fa-trash' />
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderPMQASection = (object) => {
        var count = 0;
        if (!object) return null;
        return (
            <>
                {
                    object.map((obj, index) => {
                        if ((this.state.currentPolicy + 1) !== obj.PMQASectionID) return null;
                        count++;
                        var thisCount = count;
                        return (
                            <div className='d-flex justify-content-center align-items-center form-group input-transition' key={'PMQAObjective-' + index}>
                                <span className='badge badge-secondary mr-2'><h5 className='m-0'>{thisCount}</h5></span>
                                <input
                                    className='flex-fill form-control'
                                    value={(obj.KPIMainName || '')}
                                    onChange={e => this.onChangePMQAObjectiveName(index, e.target.value)}
                                />
                                <button
                                    className='btn btn-outline-danger ml-2'
                                    onClick={() => this.removePMQAObjective(index)}
                                >
                                    <i className='fa fa-trash' />
                                </button>
                            </div>
                        );
                    })
                }
            </>
        );
    }

    checkCurrentEvaluationType = (currentEvaluationType) => {
        switch (currentEvaluationType) {
            case 1:
            case 2: {
                return (
                    <>
                        <label className='form-label mt-4'>ยุทธศาสตร์</label>
                        <div
                            className={
                                'overflow-div Evaluation-div '
                                + (!(this.state.managingMainKPI) ? '' : 'Evaluation-div-disable')
                            }
                        >
                            <button className='btn btn-outline-primary mt-2' onClick={this.addStrategy}>เพิ่มยุทธศาสตร์</button>
                            <div className='table-responsive mt-4'>
                                <table className='table table-custom table-striped table-bordered table-hover mb-0'>
                                    <thead align='center'>
                                        <tr>
                                            <th>ลำดับ</th>
                                            <th>ชื่อยุทธศาสตร์</th>
                                            <th>แก้ไข / ลบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.Strategy.map((obj, index) => {
                                                return (
                                                    <tr key={'strategy-' + index} className='input-transition'>
                                                        <td>{index + 1}</td>
                                                        <td
                                                            onClick={() => {
                                                                this.setState({
                                                                    currentPolicy: index,
                                                                    managingMainKPI: (!this.state.managingMainKPI)
                                                                })
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {obj.StrategyName}
                                                        </td>
                                                        <td align='center'>
                                                            <button
                                                                className='btn btn-outline-secondary mr-2'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        currentPolicy: index,
                                                                        managingMainKPI: (!this.state.managingMainKPI)
                                                                    })
                                                                }}
                                                            >
                                                                <i className='fa fa-pencil' />
                                                            </button>
                                                            {
                                                                (this.state.Strategy.length > 1) ?
                                                                    <button
                                                                        className='btn btn-outline-danger'
                                                                        onClick={() => this.removeStrategy(index)}
                                                                    >
                                                                        <i className='fa fa-trash' />
                                                                    </button>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            (this.state.currentPolicy !== null) ?
                                <div
                                    className={
                                        'overflow-div Evaluation-div '
                                        + ((this.state.managingMainKPI) ? '' : 'Evaluation-div-disable')
                                    }

                                >
                                    <button
                                        className='btn btn-outline-secondary'
                                        onClick={() => { this.setState({ managingMainKPI: !(this.state.managingMainKPI) }) }}
                                    >
                                        <i className='fa fa-arrow-left'></i>
                                    </button>
                                    <div className='p-3'>
                                        <div className='form-group'>
                                            <label className='form-label'>ชื่อยุทธศาสตร์</label>
                                            <input
                                                className='form-control'
                                                value={((this.state.Strategy[this.state.currentPolicy]) ? this.state.Strategy[this.state.currentPolicy].StrategyName : '')}
                                                onChange={e => this.onChangeStrategyName(this.state.currentPolicy, e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label className='form-label'>รายชื่อเป้าประสงค์</label>
                                            <button className='btn btn-outline-primary btn-sm ml-2' onClick={() => this.addObjective(this.state.currentPolicy)}>เพิ่มเป้าประสงค์</button>
                                        </div>
                                        {this.renderObjective(((this.state.Strategy[this.state.currentPolicy]) ? this.state.Strategy[this.state.currentPolicy].ListObjective : null))}
                                    </div>
                                </div>
                                : null
                        }
                    </>
                );
            }
            case 3: {
                return (
                    <>
                        <label className='form-label mt-4'>องค์ประกอบ</label>
                        <ul className="nav nav-pills overflow-nav d-flex">
                            {
                                this.props.Policy.map((obj, index) => {
                                    return (
                                        <li className="flex-fill nav-item custom-nav-item" key={'PolicyManage-list-nav' + index} align='center'>
                                            <div className={'mr-2 mt-2 ' + this.checkActiveTab(index)} onClick={() => this.managecurrentPolicy(index)}>
                                                {obj.PolicyName}
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className='pl-2 mt-4'>
                            {(!(this.state.managingMainKPI)) ?
                                <>
                                    <button className='btn btn-outline-primary btn-sm' onClick={() => this.addMainKPI(this.state.currentPolicy)}>เพิ่มตัวชี้วัดหลัก</button>
                                    <div className='d-inline-block text-primary ml-4'>{this.props.Policy[this.state.currentPolicy].PolicyName || ""}</div>
                                </>
                                : null
                            }
                            <div className='mt-4 pl-2 pr-2' style={{ maxHeight: '65vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {this.renderMainKPIs(this.state.mainKPIs[this.state.currentMainKPI])}
                            </div>
                        </div>
                    </>
                );
            }
            case 4: {
                return (
                    <>
                        <label className='form-label mt-4'>วัตถุประสงค์</label>
                        <ul className="nav nav-pills overflow-nav d-flex">
                            {
                                this.state.PMQASection.map((obj, index) => {
                                    return (
                                        <li className="flex-fill nav-item custom-nav-item" key={'PolicyManage-list-nav' + index} align='center'>
                                            <div className={'mr-2 mt-2 ' + this.checkActiveTab(index)} onClick={() => this.managecurrentPolicy(index)}>
                                                {obj}
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className='pl-2 mt-4'>
                            <button className='btn btn-outline-primary btn-sm' onClick={() => this.addPMQAObjective(this.state.currentPolicy + 1)}>เพิ่มวัตถุประสงค์</button>
                            <div className='d-inline-block text-primary ml-4'>
                                {
                                    (PMQASectionName[this.state.currentPolicy]) ?
                                        (PMQASectionName[this.state.currentPolicy]) : ''
                                }
                            </div>
                            <div className='mt-4 pl-2 pr-2' style={{ maxHeight: '65vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {this.renderPMQASection(this.state.mainKPIs)}
                            </div>
                        </div>
                    </>
                );
            }
            case 5: {
                return null;
            }
            case 6: {
                return (
                    <>
                        <label className='form-label mt-4'>องค์ประกอบ</label>
                        <ul className="nav nav-pills overflow-nav d-flex">
                            {
                                this.props.PolicyOrganization.map((obj, index) => {
                                    return (
                                        <li className="flex-fill nav-item custom-nav-item" key={'PolicyManage-list-nav' + index} align='center'>
                                            <div className={'mr-2 mt-2 ' + this.checkActiveTab(index)} onClick={() => this.managecurrentPolicy(index)}>
                                                {obj.PolicyOrganizationName}
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                        <div className='pl-2 mt-4'>
                            {(!(this.state.managingMainKPI)) ?
                                <>
                                    <button className='btn btn-outline-primary btn-sm' onClick={() => this.addMainKPI(this.state.currentPolicy)}>เพิ่มตัวชี้วัดหลัก</button>
                                    <div className='d-inline-block text-primary ml-4'>{this.state.policyOrganization[this.state.currentPolicy]}</div>
                                </>
                                : null
                            }
                            <div className='mt-4 pl-2 pr-2' style={{ maxHeight: '65vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {this.renderMainKPIs(this.state.mainKPIs[this.state.currentMainKPI])}
                            </div>
                        </div>
                    </>
                );
            }
            default: return null;
        }
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='xl' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {(this.props.data === null) ? 'เพิ่มการประเมิน' : 'แก้ไขการประเมิน'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ประเภทการประเมิน</label>
                                <select className='form-control'
                                    name='currentEvaluationType'
                                    value={this.state.currentEvaluationType}
                                    onChange={e => this.onChangeEvaluationType(parseInt(e.target.value))}
                                    disabled={(this.props.data === null) ? false : true}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    {this.renderEvaluationType()}
                                </select>
                            </div>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ปีงบประมาณ</label>
                                <select
                                    className='form-control'
                                    name='currentYear'
                                    value={this.state.currentYear} onChange={e => this.onChangeOption(e.target.name, parseInt(e.target.value))}
                                    disabled={(this.props.data === null) ? false : true}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    {this.getYear()}
                                </select>
                            </div>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ช่วงเวลาเริ่มการประเมิน</label>
                                <div>
                                    <DatePicker
                                        name='periodStart'
                                        placeholderText='ช่วงเวลาเริ่มการประเมิน'
                                        dateData={this.state.periodStart}
                                        setDate={this.onChangeOption}
                                        maxDate={this.state.periodEnd}
                                    />
                                </div>
                            </div>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ช่วงเวลาสิ้นสุดการประเมิน</label>
                                <div>
                                    <DatePicker
                                        name='periodEnd'
                                        placeholderText='ช่วงเวลาสิ้นสุดการประเมิน'
                                        dateData={this.state.periodEnd}
                                        setDate={this.onChangeOption}
                                        minDate={this.state.periodStart}
                                    />
                                </div>
                            </div>
                            <div className='form-group col-12 col-lg-6'>
                                <i className='text-danger mr-1'>*</i>
                                <label className='form-label'>ช่วงเวลา</label>
                                <select
                                    className='form-control'
                                    name='scheduleID'
                                    value={(this.state.scheduleID || 0)}
                                    onChange={e => this.onChangeOption(e.target.name, parseInt(e.target.value))}
                                >
                                    <option value={0}>กรุณาเลือก</option>
                                    {
                                        this.props.Schedule.map((obj, index) => {
                                            return (
                                                <option key={'EvaluationModal-ScheduleOption-' + index} value={obj.ScheduleID}>
                                                    {obj.ScheduleName}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            {
                                (this.state.currentEvaluationType === 4) ?
                                    <div className='form-group col-12 col-lg-6'>
                                        <i className='text-danger mr-1'>*</i>
                                        <label className='form-label'>สนับสนุนยุทธศาสตร์ สทนช.</label>
                                        <div>
                                            <select
                                                className='form-control'
                                                name='SupportID'
                                                value={(this.state.SupportID || 0)}
                                                onChange={e => this.onChangeOption(e.target.name, parseInt(e.target.value))}
                                            >
                                                <option value={0}>กรุณาเลือก</option>
                                                {
                                                    this.state.SupportList.map((obj, index) => {
                                                        return (
                                                            <option
                                                                key={'ListStrategy-' + index}
                                                                value={obj.StrategyID}
                                                            >
                                                                {obj.StrategyName}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                        {this.checkCurrentEvaluationType(this.state.currentEvaluationType)}
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className='btn btn-outline-primary'
                                onClick={(this.props.data === null) ? this.onSave : this.updateEvaluation}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default EvaluationManageModal;