import React from 'react';
import EvaluationResultmodal from './EvaluationResult_components/EvaluationResult_modal';
import EvaluationResulttable from './EvaluationResult_components/EvaluationResult_table';
import EvaluationResultService from '../../Service/EvaluationResultService';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from '../../Redux/Action';
import { renderMonth, renderYear } from '../../Utilities/Date';
import OrganizationService from '../../Service/OrganizationService';
import EvaluationService from '../../Service/EvaluationService';
import Autocomplete from '../../Utilities/Autocomplete';
import PersonService from '../../Service/PersonService';
import DatePicker from '../../Utilities/Datepicker';

class EvaluationResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentPage: 1,
            limit: 5,
            data: [],
            dataCount: 0,
            EvaluationTypes: [],
            searchEvaluationTypeID: 0,
            searchOrganization: {},
            searchYear: 0,
            searchPerson: {},
            searchStartDate: null,
            searchEndDate: null,
            searched: {
                isSearched: false,
                searchedYear: 0,
                searchedEvaluationTypeID: 0,
                searchedOrganization: {},
                searchedStatus: 0,
                searchedPerson: {},
                searchedStartDate: null,
                searchedEndDate: null,
            },
            searchStatus: 0,
            currentKPIID: null
        }
    }

    componentDidMount() {
        this.searchEvaluationResult(null, null, null, null, null, null, null, 0, 5);
        this.getEvaluationType();
        this.props.getNotification();
    }

    componentWillUnmount() {
        this.props.getNotification();
    }

    searchEvaluationResult = (EvaluationTypeID, OrganizationID, Year, Status, PersonName, StartDate, EndDate, Offset, Limit, showLoadingSearch) => {
        this.props.dispatch(showLoading());
        EvaluationResultService.searchEvaluationResult(
            EvaluationTypeID,
            OrganizationID,
            Year,
            Status,
            PersonName,
            StartDate,
            EndDate,
            Offset,
            Limit
        )
            .then(result => {
                console.log(result)
                if (!showLoadingSearch) this.props.dispatch(hideLoading());
                if (result.errors) {
                    this.props.dispatch(hideLoading());
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                result = result.data.SearchEvaluationResult;
                this.setState({
                    data: result.ListEvaluationResult,
                    dataCount: result.Count
                });
            })
            .catch(err => {
                if (!showLoadingSearch) this.props.dispatch(hideLoading());
                console.log(err)
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                })
            })
    }

    getYear = () => {
        return renderYear('EvaluationResult');
    }

    getEvaluationType = () => {
        EvaluationService.getEvaluationType()
            .then(result => {
                if (result.errors) {
                    return;
                }
                this.setState({ EvaluationTypes: result.data.GetEvaluationType }, () => {
                    console.log(this.state.EvaluationTypes);
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getEvaluationResultDetail = (KPIID, Month, Mode) => {
        this.props.dispatch(showLoading());
        return EvaluationResultService.getEvaluationResultDetail(KPIID, Month, Mode)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    searchOrganization = (Name) => {
        return OrganizationService.searchOrganization(Name);
    }

    searchPerson = (Name) => {
        return PersonService.searchPerson(Name);
    }

    updateEvaluationResult = (KPIID, FormObjectItemResults, Month, UploadFiles, UpdateFiles, DelFiles, FormID) => {
        this.props.dispatch(showLoading());
        return EvaluationResultService.updateEvaluationResult(KPIID, FormObjectItemResults, Month, UpdateFiles, DelFiles, FormID, (UploadFiles.length > 0))
            .then(result => {
                if (!result.errors) {
                    this.props.getNotification();
                    this.searchEvaluationResultAfterSetState(true);
                    if (UploadFiles.length > 0) {
                        return this.uploadFile(KPIID, Month, UploadFiles)
                            .then(res => {
                                if (res.Code < 0 ) {
                                    return Promise.reject(res);
                                    
                                }
                                this.props.dispatch(hideLoading());
                                return res;
                            })
                            .catch(err => {
                                console.log(err);
                                this.props.dispatch(hideLoading());
                                return Promise.reject(err);
                            })
                    }
                    else {
                        this.props.dispatch(hideLoading());
                    }
                }
                else {
                    this.props.dispatch(hideLoading());
                }
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    approveEvaluationResult = (FormID, KPIID, date, Status, Remark) => {
        this.props.dispatch(showLoading());
        return EvaluationResultService.approveEvaluationResult(FormID, KPIID, date, Status, Remark)
            .then(result => {
                this.props.dispatch(hideLoading());
                if (!result.errors) {
                    this.props.getNotification();
                    this.searchEvaluationResultAfterSetState();
                }
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    uploadFile = (KPIID, Month, files) => {
        //this.props.dispatch(showLoading());
        return EvaluationResultService.uploadFile(KPIID, Month, files);
        // .then(result => {
        //     this.props.dispatch(hideLoading());
        //     if (!result.errors) {
        //         this.searchEvaluationResultAfterSetState();
        //     }
        //     return result;
        // })
        // .catch(err => {
        //     this.props.dispatch(hideLoading());
        //     return Promise.reject(err);
        // });
    }

    getFile = (KPIID, Month, FileName) => {
        this.props.dispatch(showLoading());
        return EvaluationResultService.getFile(KPIID, Month, FileName)
            .then(result => {
                console.log(result);
                var name = decodeURI(result.name);
                var startFileNameIndex = name.indexOf('"') + 1
                var endFileNameIndex = name.lastIndexOf('"')
                name = name.substring(startFileNameIndex, endFileNameIndex)
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                this.props.dispatch(hideLoading());
            })
            .catch(err => {
                console.log(err);
                this.props.dispatch(hideLoading());
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่พบไฟล์',
                    type: 'error'
                })
            })
    }

    onChangeSearch = (name, value) => {
        if (name === 'searchOrganization' && value !== null && value !== undefined) {
            this.setState({
                searchOrganization: {
                    OrganizationName: value.OrganizationName,
                    OrganizationID: parseInt(value.OrganizationID)
                }
            });
        }
        else
            this.setState({ [name]: value })
    }

    checkEvaluationType = (TypeID) => {
        TypeID = parseInt(TypeID);
        for (var i = 0; i < this.state.EvaluationTypes.length; i++) {
            if (TypeID === parseInt(this.state.EvaluationTypes[i].EvaluationTypeID)) {
                return this.state.EvaluationTypes[i].EvaluationTypeName;
            }
        }
    }

    searchEvaluationResultAfterSetState = (showLoadingSearch) => {
        if (this.state.searched.isSearched === true) {
            this.searchEvaluationResult(
                this.state.searched.searchedEvaluationTypeID,
                this.state.searched.searchedOrganization.OrganizationID,
                this.state.searched.searchedYear,
                this.state.searched.searchedStatus,
                this.state.searched.searchedPerson.PersonName,
                this.state.searched.searchedStartDate,
                this.state.searched.searchedEndDate,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit,
                showLoadingSearch
            )
        }
        else {
            this.searchEvaluationResult(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit,
                showLoadingSearch
            )
        }
    }

    formatDate = (date) => {
        if (!date) return null;
        return date.getFullYear() + '-' +
            (((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1)) + '-' +
            (((date.getDate() + 1) <= 10) ? ('0' + (date.getDate())) : (date.getDate()))
    }

    clearSearch = () => {
        this.setState({
            searchYear: 0,
            searchEvaluationTypeID: 0,
            searchOrganization: {},
            searchStatus: 0,
            currentPage: 1,
            searchPerson: {},
            searchStartDate: null,
            searchEndDate: null,
            searched: {
                isSearched: false,
                searchedYear: 0,
                searchedEvaluationTypeID: 0,
                searchedOrganization: {},
                searchedStatus: 0,
                searchedPerson: {},
                searchedStartDate: null,
                searchedEndDate: null,
            }
        }, () => {
            this.searchEvaluationResult(null, null, null, null, null, null, null, 0, this.state.limit);
        });
    }

    onClickSearch = () => {
        if (
            this.state.searchYear === 0 &&
            this.state.searchEvaluationTypeID === 0 &&
            !this.state.searchOrganization.OrganizationID &&
            this.state.searchStatus === 0 &&
            !this.state.searchPerson.PersonID &&
            this.state.searchStartDate === null &&
            this.state.searchEndDate === null
        ) {
            this.clearSearch()
        }
        else {
            this.setState({
                //searchYear: 0,
                //searchEvaluationTypeID: 0,
                //searchOrganization: {},
                //searchStatus: 0,
                currentPage: 1,
                searched: {
                    isSearched: true,
                    searchedYear: this.state.searchYear,
                    searchedEvaluationTypeID: this.state.searchEvaluationTypeID,
                    searchedOrganization: this.state.searchOrganization,
                    searchedStatus: this.state.searchStatus,
                    searchedPerson: this.state.searchPerson,
                    searchedStartDate: this.state.searchStartDate,
                    searchedEndDate: this.state.searchEndDate,
                }
            }, () => {
                this.searchEvaluationResultAfterSetState();
            })
        }
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.searchEvaluationResultAfterSetState();
            });
        else
            this.setState({ [name]: value }, () => {
                this.searchEvaluationResultAfterSetState();
            });
    }

    changeCurrentKPIID = (ID) => {
        this.setState({
            currentKPIID: ID,
            show: true
        })
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({ show: true })
            : this.setState({ show: false, currentKPIID: null }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    getMonth = () => {
        return renderMonth('EvaluationResult');
    }

    render() {
        return (
            <>
                <div className="box-search custom-block mb-4 " >
                    <form id='search-kpi-report' className='row align-items-end form'>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>ปีงบประมาณ</label>
                            <select className='form-control' name='searchYear' value={this.state.searchYear} onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}>
                                <option value={0}>กรุณาเลือก</option>
                                {this.getYear()}
                            </select>
                        </div>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>ประเภทการประเมิน</label>
                            <select className='form-control' name='searchEvaluationTypeID' value={this.state.searchEvaluationTypeID} onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}>
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.EvaluationTypes.map((obj, index) => {
                                        return (
                                            <option value={obj.EvaluationTypeID} key={'EvaluationResult-EvaluationType-option' + index}>
                                                {obj.EvaluationTypeName}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>หน่วยงาน</label>
                            <Autocomplete
                                getData={this.searchOrganization}
                                name='searchOrganization'
                                labelName='หน่วยงาน'
                                dataName='Organization'
                                onChangeSelect={this.onChangeSearch}
                                selectValue={this.state.searchOrganization}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>บุคคล</label>
                            <Autocomplete
                                getData={this.searchPerson}
                                name='searchPerson'
                                labelName='บุคคล'
                                dataName='Person'
                                onChangeSelect={this.onChangeSearch}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>วันเริ่ม</label>
                            <DatePicker
                                name='searchStartDate'
                                placeholderText='วันเริ่ม'
                                dateData={this.state.searchStartDate}
                                setDate={this.onChangeSearch}
                                startDate={this.state.searchStartDate}
                                endDate={this.state.searchEndDate}
                                maxDate={this.state.searchEndDate}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>วันจบ</label>
                            <DatePicker
                                name='searchEndDate'
                                placeholderText='วันจบ'
                                dateData={this.state.searchEndDate}
                                setDate={this.onChangeSearch}
                                startDate={this.state.searchStartDate}
                                endDate={this.state.searchEndDate}
                                minDate={this.state.searchStartDate}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-6 col-xl-3'>
                            <label className='form-label'>สถานะ</label>
                            <select
                                className='form-control'
                                name='searchStatus'
                                value={this.state.searchStatus}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                <option value={1}>รายงานเรียบร้อย</option>
                                <option value={2}>ยังไม่รายงาน</option>
                                <option value={3}>รอการอนุมัติ</option>
                                <option value={4}>รายงานไม่เรียบร้อย</option>
                            </select>
                        </div>
                    </form>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.onClickSearch}>ค้นหา</button>
                    </div>
                </div>
                <div className='custom-block mb-4'>
                    <EvaluationResulttable
                        data={this.state.data}
                        dataCount={this.state.dataCount}
                        onChangeTableState={this.onChangeTableState}
                        limit={this.state.limit}
                        currentPage={this.state.currentPage}
                        changeCurrentKPIID={this.changeCurrentKPIID}
                        searched={this.state.searched}
                        checkEvaluationType={this.checkEvaluationType}
                    />
                </div>
                <EvaluationResultmodal
                    show={this.state.show}
                    currentKPIID={this.state.currentKPIID}
                    hide={this.hideModal}
                    getEvaluationResultDetail={this.getEvaluationResultDetail}
                    updateEvaluationResult={this.updateEvaluationResult}
                    approveEvaluationResult={this.approveEvaluationResult}
                    uploadFile={this.uploadFile}
                    getFile={this.getFile}
                    roleID={this.props.roleID}
                />
            </>
        );
    }
}

export default connect(null)(EvaluationResult);