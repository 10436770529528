import React from 'react';
import Tree from 'react-d3-tree';
import Modal from 'react-bootstrap/Modal';

class OrganizationManageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canUpdate: false,
            data: []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        this.props.hide(this.resetState);
    }

    onEnterModal = () => {
        this.setState({ canUpdate: true });
        if (!this.state.data.children)
            this.setState({ data: this.setData(this.props.data) });
    }

    resetState = () => {
        this.setState({
            canUpdate: false
        });
    }

    getChildren = (node) => {
        var child = [];
        this.props.data.forEach(obj => {
            if (obj.ParentOrganizationID === node.OrganizationID)
                return child.push(this.getChildren(obj));
        })
        return {
            name: node.OrganizationName,
            children: child
        }
    }

    setData = (data) => {
        var a = [];
        data.forEach(x => {
            if (x.ParentOrganizationID === null)
                return a.push(this.getChildren(x));
        })
        return {
            name: 'หน่วยงาน',
            children: a
        };
    }

    render() {
        console.log(this.state.data.children)
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='xl' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            แผนผังหน่วยงาน
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div id="treeWrapper" style={{ width: '100%', height: '70vh' }}>
                                {
                                    (this.state.data.children) ?
                                        <Tree
                                            data={this.state.data}
                                            initialDepth={0}
                                            translate={{ x: 300, y: 300 }}
                                            textLayout={{ textAnchor: "start", x: 15, y: -5, transform: 'rotate(-35)' }}//'rotate(35)' }}
                                        />
                                        : null
                                }
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ปิด</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default OrganizationManageModal;
