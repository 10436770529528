import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { checkMonth } from '../../../Utilities/Date';

class MyWindowPortal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            dataLoaded: [false, false, false, false, false, false, false],
            printed: false,
            data: {
                KPIName: '',
                KPIID: null,
                Description: '',
                Year: 0,
                Month: '',
                Status: 2,
                KPIMain: {},
                EvaluationCriteria: {},
                EvaluationType: {},
                Form: {
                    Sections: []
                },
                Policy: {},
                Schedule: {
                    Period: []
                },
                KPIPersons: [],
                KPIOrganizations: [],
                KPIFiles: [],
                FormObjectResult: {
                    KPIID: null,
                    FormObjectItemResults: []
                },
                ModifiedDateTime: '',
                ModifiedUser: '',
                ProjectKPI: [],
            }
        };
        this.containerEl = null;
        this.externalWindow = null;
    }

    componentDidMount() {
        console.log(window.location);
        this.externalWindow = window.open('');

        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.closeWindowPortal();
        });

        this.containerEl = this.externalWindow.document.createElement("div")
        this.externalWindow.document.body.appendChild(this.containerEl);

        var head = this.externalWindow.document.getElementsByTagName('head')[0];

        this.appendCSS(head, window.location.origin + '/assets/css/froala_editor.pkgd.min.css', 0);
        this.appendCSS(head, window.location.origin + "/assets/css/froala_editor.min.css", 1);
        this.appendCSS(head, "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css", 2);
        this.appendCSS(head, 'https://fonts.googleapis.com/css?family=Pridi:300&display=swap&subset=thai', 3);
        this.appendCSS(head, 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css', 4);
        this.appendCSS(head, window.location.origin + "/assets/css/pdf.css", 5);


        this.setState({ isOpened: true }, () => {
            console.log('eieiza');
            this.props.getReportEvaluation(this.props.Year, this.props.EvaluationTypeID)
                .then(result => {
                    console.log(result);
                    this.setState({
                        data: (result.data.GetReportResultByYear) ? result.data.GetReportResultByYear : {}
                    }, () => {
                        var newState = this.state.dataLoaded;
                        newState[6] = true;
                        this.setState({ dataLoaded: newState });
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                    });
                    this.props.closeWindowPortal();
                    this.externalWindow.close();
                })
        });
    }

    componentWillUnmount() {
        this.props.closeWindowPortal();
        this.externalWindow.close();
    }

    componentDidUpdate() {
        if (this.state.dataLoaded.every(x => x === true) && !this.state.printed) {
            //this.setState({ printed: true }, () => { this.externalWindow.print(); })
        }
    }

    appendCSS = (head, link, index) => {
        var cssnode = this.externalWindow.document.createElement('link');

        cssnode.type = 'text/css';
        cssnode.rel = 'stylesheet';
        cssnode.href = link;

        head.appendChild(cssnode);

        cssnode.onload = this.onloadCSS(index)
    }

    onloadCSS = (index) => {
        console.log(index)
        var newState = this.state.dataLoaded;
        newState[index] = true;
        this.setState({ dataLoaded: newState });
    }

    renderHeader = () => {
        return (
            <div>
                {
                    (this.state.data.Year) ?
                        <div className='mb-3' align='center'>
                            <h5>สรุปผลการประเมินตนเองตามมาตรการปรับปรุงประสิทธิภาพในการปฏิบัติราชการ</h5>
                            <h5>ประจำปีงบประมาน {this.state.data.Year + 543} รอบที่ 1</h5>
                        </div>
                        : null
                }
                <div className='content row table-header background-header'>
                    <div className='col-3 border-right d-flex align-items-center justify-content-center'>ตัวชี้วัด</div>
                    <div className='col-3 border-right d-flex align-items-center justify-content-center'>เป้าหมายรอบที่ {1}</div>
                    <div className='col-3 border-right d-flex align-items-center justify-content-center'>ผลการดำเนินงานรอบที่ {1}</div>
                    <div className='col-3 border-right p-0'>
                        <div className='pt-2 pb-2 border-bottom' align='center'>
                            สรุปผลการประเมิน
                    </div>
                        <div className='row' align='center'>
                            <div className='col-6 no-padding border-right'>ผ่าน</div>
                            <div className='col-6 no-padding'>ไม่ผ่าน</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPolicy = () => {
        if (!this.state.data.Policy) return;
        return (
            <>
                {
                    this.state.data.Policy.map((obj, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className='content background-hilight'>
                                    <div className='section-title'>{obj.PolicyName}</div>
                                    {obj.Remark}
                                </div>
                                {this.renderKPIMains(obj.KPIMain)}
                            </React.Fragment>
                        )
                    })
                }
            </>
        );
    }

    renderKPIMains = (obj, index) => {
        if (!obj) return null;
        return (
            obj.map((object, objectIndex) => {
                return (
                    <React.Fragment key={index + '-' + objectIndex}>
                        <div className='content'>
                            <div className='section-title pl-1'>{object.KPIMainName}</div>
                        </div>
                        {this.renderKPIs(object.KPI)}
                    </React.Fragment>
                )
            })

        )
    }

    renderKPIs = (obj, index) => {
        //if (!obj.EvaluationCriteria) return null;
        if (!obj) return null;
        return (
            obj.map((object, objectIndex) => {
                console.log(object)
                if (!object.EvaluationCriteria) return;
                return (
                    <React.Fragment key={index + '+' + objectIndex}>
                        <div className='content row no-padding'>
                            <div className='section-title col-3 border-right pt-3 pb-3 pl-4'>{object.KPIName}</div>
                            <div className='col-3 border-right pt-3 pb-3'>
                                {
                                    (object.EvaluationCriteria.Type === 1) ?
                                        ((object.EvaluationCriteria.VeryHigh) ?
                                            object.EvaluationCriteria.VeryHigh.Description
                                            : object.EvaluationCriteria.High.Description
                                            || '')
                                        :
                                        ((object.EvaluationCriteria.Unit === 'ร้อยละ') ?
                                            object.EvaluationCriteria.Unit + ' ' +
                                            (((object.EvaluationCriteria.VeryHigh) ?
                                                object.EvaluationCriteria.VeryHigh.Target
                                                : object.EvaluationCriteria.High.Target
                                            ) || 0)
                                            :
                                            (((object.EvaluationCriteria.VeryHigh) ?
                                                object.EvaluationCriteria.VeryHigh.Target
                                                : object.EvaluationCriteria.High.Target
                                            ) || 0)
                                            + ' ' + (object.EvaluationCriteria.Unit || '')
                                        )
                                }
                            </div>
                            <div className='col-3 border-right pt-3 pb-3'>
                                {
                                    (object.EvaluationCriteria.Type === 1) ?
                                        (object.ResultText || '')
                                        :
                                        ((object.EvaluationCriteria.Unit === 'ร้อยละ') ?
                                            ((object.EvaluationCriteria.Unit || '') + ' ' + (object.ResultValue || 0))
                                            : (object.ResultValue || 0) + ' ' + (object.EvaluationCriteria.Unit || '')
                                        )
                                }
                            </div>
                            <div className='col-3 row no-padding'>
                                <div className='col-6 border-right d-flex justify-content-center align-items-center'>
                                    {(object.Pass && object.Pass !== null) ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                                <div className='col-6 d-flex justify-content-center align-items-center'>
                                    {(!object.Pass && object.Pass !== null) ? <i className="fa fa-check" aria-hidden="true"></i> : null}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })

        )
    }

    render() {
        console.log(this.containerEl)
        if (!this.containerEl) {
            return null;
        }
        return ReactDOM.createPortal(
            (
                <>
                    {
                        (this.state.dataLoaded.every(x => x === true)) ?
                            <div className='p-2'>
                                <div className='' style={{ backgroundColor: 'white' }}>
                                    <div className='hide-print mb-4'>
                                        <button className='btn btn-outline-success' onClick={() => this.externalWindow.print()}>
                                            Export PDF
                                        </button>
                                    </div>
                                    {this.renderHeader()}
                                    {this.renderPolicy()}
                                </div>
                            </div>
                            : null
                    }
                    <div className={"lds-ellipsis " + ((this.state.dataLoaded.every(x => x === true)) ? 'disabled' : '')}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className={'loading-backdrop ' + ((this.state.dataLoaded.every(x => x === true)) ? 'disabled' : '')} />
                </>
            )
            , this.containerEl);
    }
}

export default MyWindowPortal;