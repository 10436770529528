import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Autocomplete from '../../../Utilities/Autocomplete';
import FormService from '../../../Service/FormService';
import Swal from 'sweetalert2';

class FormManageCopyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canUpdate: false,
            formToCopy: {},
            loading: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onHideModal = () => {
        this.props.hide(this.resetState);
    }

    onEnterModal = () => {
        this.setState({ canUpdate: true });
    }

    searchForm = (FormName) => {
        return FormService.searchForm(FormName, null, null, null, 25)
    }

    resetState = () => {
        this.setState({
            canUpdate: false,
            formToCopy: {},
            loading: false
        })
    }

    onChangeContent = (name, value) => {
        this.setState({ [name]: value }, () => {
            console.log(this.state)
        });
    }

    onSave = () => {
        if (!this.state.formToCopy || !this.state.formToCopy.FormName) {
            Swal.fire({
                type: 'warning',
                title: 'กรุณาเลือกฟอร์ม',
                text: 'กรุณาเลือกแบบฟอร์มที่ต้องการคัดลอก'
            });
            return;
        }
        Swal.fire({
            title: 'คัดลอกแบบฟอร์ม',
            text: 'ท่านต้องการคัดลอกแบบฟอร์ม "' + this.state.formToCopy.FormName + '" หรือไม่ ?',
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value) {
                    this.setState({ loading: true }, () => {
                        this.props.copyForm(this.state.formToCopy.FormID)
                            .then((val) => {
                                console.log(val);
                                this.setState({ loading: false }, () => {
                                    this.onHideModal();
                                });
                            })
                            .catch(e => {
                                console.log(e);
                                this.onHideModal();
                            })
                    })
                }
            })
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={(this.state.loading) ? null : this.onHideModal}
                    size='lg' onEnter={this.onEnterModal}
                    backdropClassName='backdrop-formcopy'
                    className='dialog-formcopy'
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            คัดลอกแบบฟอร์มการประเมิน
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Autocomplete
                            getData={this.searchForm}
                            name='formToCopy'
                            labelName='แบบฟอร์ม'
                            dataName='Form'
                            listName='ListForm'
                            onChangeSelect={this.onChangeContent}
                            selectValue={this.state.formToCopy}
                            searchOnChange
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            {
                                (this.state.loading) ?
                                    <button className='btn btn-outline-primary' disabled>
                                        <i className='fa fa-circle-o-notch fa-spin mr-2' />
                                        กำลังทำรายการ
                                    </button>
                                    :
                                    <>
                                        <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                                        <button
                                            className='btn btn-outline-primary'
                                            onClick={this.onSave}
                                        >
                                            ตกลง
                                </button>
                                    </>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default FormManageCopyModal;
