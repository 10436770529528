import React from 'react';
import KPIManagetable from './KPIManage_components/KPIManage_table';
import KPIManagemodal from './KPIManage_components/KPIManage_modal';
import KPIManageAddModal from './KPIManage_components/KPIManage_addModal';
import KPIService from '../../Service/KPIManageService';
import OrganizationService from '../../Service/OrganizationService';
import EvaluationService from '../../Service/EvaluationService';
import FormService from '../../Service/FormService';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from '../../Redux/Action';
import Swal from 'sweetalert2';
import { renderYear } from '../../Utilities/Date';
import Autocomplete from '../../Utilities/Autocomplete';
import KPIManageModalExc from './KPIManage_components/KPIManage_modal_exec';
import PersonService from '../../Service/PersonService';

class KPIManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: [false, false, false, false, false, false],
            searchYear: 0,
            searchEvaluation: 0,
            searchKPIName: '',
            searchOrganization: 0,
            data: [],
            EvaluationTypes: [],
            dataCount: 0,
            currentPage: 1,
            limit: 5,
            currentKPIID: null,
            createKPIYear: 0,
            createEvaluationType: 0,
            searched: {
                isSearched: false,
                searchedYear: 0,
                searchedKPIName: '',
                searchedEvaluation: 0,
                searchedOrganization: {}
            }
        }
    }

    componentDidMount = () => {
        this.searchKPI(null, null, null, null, 0, 5);
        this.getEvaluationType();
    }

    searchOrganization = (Name) => {
        return OrganizationService.searchOrganization(Name);
    }

    searchPerson = (Name, OrganizationID, Phone) => {
        return PersonService.searchPerson(Name, OrganizationID, Phone);
    }

    searchFormKPIBound = (FormName) => {
        return FormService.searchFormKPIBound(1, FormName);
    }


    searchKPI = (Year, KPIName, EvaluationTypeID, OrganizationID, Offset, Limit) => {
        this.props.dispatch(showLoading());
        KPIService.searchKPI(Year, KPIName, EvaluationTypeID, OrganizationID, Offset, Limit)
            .then(result => {
                this.props.dispatch(hideLoading());
                console.log(result)
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                result = result.data.SearchKPI;
                this.setState({
                    data: result.ListKPI,
                    dataCount: result.Count
                });
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            });
    }

    searchKPIAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchKPI(
                this.state.searched.searchedYear,
                this.state.searched.searchedKPIName,
                this.state.searched.searchedEvaluation,
                this.state.searched.searchedOrganization.OrganizationID,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit
            )
        }
        else {
            this.searchKPI(
                null,
                null,
                null,
                null,
                (this.state.currentPage - 1) * this.state.limit,
                this.state.limit
            )
        }
    }

    searchKPIMain = (Year, EvaluationTypeID) => {
        return KPIService.searchMainKPI(Year, EvaluationTypeID);
    }

    getEvaluationType = () => {
        EvaluationService.getEvaluationType()
            .then(result => {
                if (result.errors) {
                    return;
                }
                this.setState({ EvaluationTypes: result.data.GetEvaluationType }, () => {
                    console.log(this.state.EvaluationTypes);
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getKPIDetail = (index) => {
        this.props.dispatch(showLoading());
        return KPIService.getKPIDetail(index)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    getListKPIinKPIMain = (KPIMainID) => {
        return KPIService.getListKPIinKPIMain(KPIMainID);
    }

    getListStrategy = (Year, EvaluationTypeID) => {
        return KPIService.getListStrategy(Year, EvaluationTypeID);
    }

    getListObjective = (StrategyID, EvaluationTypeID) => {
        return KPIService.getListObjective(StrategyID, null, (EvaluationTypeID || this.state.createEvaluationType));
    }

    getEvaluationByYear = (Year) => {
        return EvaluationService.searchEvaluation(Year);
    }

    getMainKPI = (Year, EvaluationTypeID, PolicyID) => {
        return KPIService.searchMainKPI(Year, EvaluationTypeID, PolicyID);
    }

    getYear = () => {
        return renderYear('KPIManage');
    }

    checkEvaluationType = (TypeID) => {
        TypeID = parseInt(TypeID);
        for (var i = 0; i < this.state.EvaluationTypes.length; i++) {
            if (TypeID === parseInt(this.state.EvaluationTypes[i].EvaluationTypeID)) {
                return this.state.EvaluationTypes[i].EvaluationTypeName;
            }
        }
    }

    checkOrganizationName = (OrgID) => {
        OrgID = parseInt(OrgID);
        for (var i = 0; i < this.state.organization.length; i++) {
            if (OrgID === parseInt(this.state.organization[i].OrganizationID)) {
                return this.state.organization[i].OrganizationName;
            }
        }
    }

    onChangeSearch = (name, value) => {
        this.setState({ [name]: value });
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.searchKPIAfterSetState();
            });
        else
            this.setState({ [name]: value }, () => {
                this.searchKPIAfterSetState();
            });
    }

    onClickSearch = () => {
        if (
            this.state.searchYear === 0 &&
            this.state.searchKPIName === '' &&
            this.state.searchEvaluation === 0 &&
            this.state.searchOrganization.OrganizationID === undefined
        ) {
            this.clearSearch();
            return;
        }
        this.setState({
            // searchYear: 0,
            // searchKPIName: '',
            // searchEvaluation: 0,
            // searchOrganization: {},
            currentPage: 1,
            searched: {
                isSearched: true,
                searchedYear: this.state.searchYear,
                searchedKPIName: this.state.searchKPIName,
                searchedEvaluation: this.state.searchEvaluation,
                searchedOrganization: this.state.searchOrganization
            }
        }, () => {
            this.searchKPI(
                this.state.searched.searchedYear,
                this.state.searched.searchedKPIName,
                this.state.searched.searchedEvaluation,
                this.state.searched.searchedOrganization.OrganizationID,
                null,
                this.state.limit
            )
        });
    }

    clearSearch = () => {
        this.setState({
            searchYear: 0,
            searchKPIName: '',
            searchEvaluation: 0,
            searchOrganization: {},
            currentPage: 1,
            searched: {
                isSearched: false,
                searchedYear: 0,
                searchedKPIName: '',
                searchedEvaluation: 0,
                searchedOrganization: {}
            }
        }, () => {
            this.searchKPI(
                null,
                null,
                null,
                null,
                0,
                this.state.limit
            )
        })
    }

    changeCurrentKPIID = (ID, index) => {
        var newShow = [...this.state.showModal];
        if (index === 1 || index === 2 || index === 4 || index === 6)
            newShow[3] = true;
        else
            newShow[index] = true;
        this.setState({
            currentKPIID: ID,
            showModal: newShow
        });
    }

    changeCreateKPI = (Year, createEvaluationType) => {
        this.setState({ createKPIYear: Year, createEvaluationType: createEvaluationType });
    }

    hideModal = (callback, index) => {
        if (this.state.showModal[index] === false) {
            var newShow = [...this.state.showModal];
            newShow[index] = true;
            this.setState({ showModal: newShow })
            return;
        }

        this.setState({
            showModal: [false, false, false, false, false, false],
            currentKPIID: null,
            createKPIYear: 0,
            createEvaluationType: 0
        }, () => {
            if (typeof callback === 'function')
                callback();
        });
    }

    updateKPI = (
        KPIID,
        KPIName,
        Description,
        Year,
        KPIMainID,
        EvaluationCriteria,
        HasFile,
        KPIPersons,
        KPIOrganizations,
        FormID,
        MapData,
        DelKPIPersons,
        DelKPIOrganizations,
        DelMapData
    ) => {
        this.props.dispatch(showLoading());
        return KPIService.updateKPI(
            KPIID,
            KPIName,
            Description,
            Year,
            KPIMainID,
            EvaluationCriteria,
            HasFile,
            KPIPersons,
            KPIOrganizations,
            FormID,
            MapData,
            DelKPIPersons,
            DelKPIOrganizations,
            DelMapData
        ).then(result => {
            this.props.dispatch(hideLoading());
            if (!result.errors) {
                this.searchKPIAfterSetState();
            }
            return result;
        })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    createKPI = (
        KPIName,
        Description,
        Year,
        KPIMainID,
        EvaluationCriteria,
        HasFile,
        KPIPersons,
        KPIOrganizations,
        FormID,
        MapData
    ) => {
        this.props.dispatch(showLoading());
        return KPIService.createKPI(
            KPIName,
            Description,
            Year,
            KPIMainID,
            EvaluationCriteria,
            HasFile,
            KPIPersons,
            KPIOrganizations,
            FormID,
            MapData
        ).then(result => {
            this.props.dispatch(hideLoading());
            if (!result.errors) {
                this.searchKPIAfterSetState();
            }
            return result;
        })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    deleteKPI = (KPIID) => {
        Swal.fire({
            title: 'ลบตัวชี้วัด',
            text: 'ต้องการลบตัวชี้วัดนี้หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    KPIService.deleteKPI(KPIID)
                        .then(result => {
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                                return;
                            }
                            Swal.fire({
                                title: 'ลบข้อมูลสำเร็จ',
                                type: 'success'
                            })
                            this.searchKPIAfterSetState();
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                type: 'error'
                            });
                        });
            });
    }

    render() {
        return (
            <>
                <div className='custom-block mb-4 pb-3'>
                    <form className='row align-items-end'>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-3'>
                            <label className='form-label'>ปีงบประมาณ</label>
                            <select
                                className='form-control'
                                name='searchYear'
                                value={this.state.searchYear}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {this.getYear()}
                            </select>
                        </div>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-3'>
                            <label className='form-label'>ตัวชี้วัด</label>
                            <input className='form-control' type='text' placeholder='ตัวชี้วัด' name='searchKPIName' value={this.state.searchKPIName} onChange={e => this.onChangeSearch(e.target.name, e.target.value)} />
                        </div>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-3'>
                            <label className='form-label'>การประเมิน</label>
                            <select
                                className='form-control'
                                name='searchEvaluation'
                                value={this.state.searchEvaluation}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.EvaluationTypes.map((obj, index) => {
                                        return (
                                            <option value={obj.EvaluationTypeID} key={'EvaluationType-option' + index}>
                                                {obj.EvaluationTypeName}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group col-12 col-md-6 col-lg-6 col-xl-3'>
                            <label className='form-label'>หน่วยงาน</label>
                            <Autocomplete
                                getData={this.searchOrganization}
                                name='searchOrganization'
                                labelName='หน่วยงาน'
                                dataName='Organization'
                                onChangeSelect={this.onChangeSearch}
                                selectValue={this.state.searchOrganization}
                            />
                        </div>
                    </form>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.onClickSearch}>ค้นหา</button>
                    </div>
                </div>

                <div className='custom-block mb-4'>
                    <KPIManagetable
                        data={this.state.data}
                        dataCount={this.state.dataCount}
                        currentPage={this.state.currentPage}
                        limit={this.state.limit}
                        onChangeTableState={this.onChangeTableState}
                        searched={this.state.searched}
                        clickAddKPI={() => this.hideModal(null, 0)}
                        clickDeleteKPI={this.deleteKPI}
                        changeCurrentKPIID={this.changeCurrentKPIID}
                        checkEvaluationType={this.checkEvaluationType}
                    />
                </div>
                <KPIManagemodal
                    show={this.state.showModal[3]}
                    hide={this.hideModal}
                    currentKPIID={this.state.currentKPIID}
                    EvaluationTypes={this.state.EvaluationTypes}
                    getKPIDetail={this.getKPIDetail}
                    searchOrganization={this.searchOrganization}
                    searchPerson={this.searchPerson}
                    searchForm={this.searchFormKPIBound}
                    createKPIYear={this.state.createKPIYear}
                    getMainKPI={this.getMainKPI}
                    updateKPI={this.updateKPI}
                    createKPI={this.createKPI}
                    getListKPIinKPIMain={this.getListKPIinKPIMain}
                    createEvaluationType={this.state.createEvaluationType}
                    getListStrategy={this.getListStrategy}
                    getListObjective={this.getListObjective}
                />
                <KPIManageAddModal
                    show={this.state.showModal[0]}
                    hide={this.hideModal}
                    EvaluationTypes={this.state.EvaluationTypes}
                    getEvaluationByYear={this.getEvaluationByYear}
                    changeCreateKPI={this.changeCreateKPI}
                />
                <KPIManageModalExc
                    show={this.state.showModal[5]}
                    hide={this.hideModal}
                    currentKPIID={this.state.currentKPIID}
                    searchForm={this.searchFormKPIBound}
                    updateKPI={this.updateKPI}
                    createKPI={this.createKPI}
                    EvaluationTypes={this.state.EvaluationTypes}
                    createKPIYear={this.state.createKPIYear}
                    getKPIDetail={this.getKPIDetail}
                    searchKPIMain={this.searchKPIMain}
                />
            </>
        );
    }
}

export default connect(null)(KPIManage);