import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Autocomplete from '../../../Utilities/Autocomplete';

class KPIManageExcModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            KPIName: '',
            KPIID: 0,
            KPIMainID: 0,
            Year: 0,
            Description: '',
            EvaluationTypeID: 5,
            searchMainKPI: 0,
            searchForm: {},
            Form: {},
            canUpdate: false,
            Editable: false
        }
    }

    componentDidMount() {
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.show === false && this.state.canUpdate === false) {
            return false;
        }
        return true;
    }

    onEnterModal = () => {
        if (this.props.currentKPIID !== null) {
            this.props.getKPIDetail(this.props.currentKPIID)
                .then(result => {
                    console.log(result);
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                        this.setState({ canUpdate: true });
                        return;
                    }
                    result = result.data.GetKPIDetail;
                    console.log(result);
                    this.setState({
                        KPIName: result.KPIName,
                        Year: result.Year,
                        Description: result.Description,
                        KPIMainID: result.KPIMain.KPIMainID,
                        EvaluationTypeID: result.EvaluationType.EvaluationTypeID,
                        Form: { ...result.Form },
                        canUpdate: true,
                        Editable: (result.Editable || false)
                    })
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    })
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState, 5);
                    })
                })
        }
        else
            this.props.searchKPIMain(this.props.createKPIYear, 5)
                .then(result => {
                    console.log(result)
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                        this.setState({ canUpdate: true });
                        return;
                    }
                    result = result.data.SearchKPIMain[0];
                    this.setState({
                        Year: this.props.createKPIYear,
                        KPIMainID: result.KPIMainID,
                        canUpdate: true,
                        Editable: true
                    });
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    })
                    this.setState({ canUpdate: true }, () => {
                        this.props.hide(this.resetState, 5);
                    })
                })
    }

    checkEvaluationType = (TypeID) => {
        TypeID = parseInt(TypeID);
        for (var i = 0; i < this.props.EvaluationTypes.length; i++) {
            if (TypeID === parseInt(this.props.EvaluationTypes[i].EvaluationTypeID)) {
                return this.props.EvaluationTypes[i].EvaluationTypeName;
            }
        }
    }

    onChangeContent = (name, value, objectType) => {
        if (objectType === 'Form') {
            if (!value || value === 0) {
                return;
            }
            this.setState({
                searchForm: {},
                [objectType]: {
                    FormID: value.FormID,
                    FormName: value.FormName
                }
            }, () => { console.log(this.state.Form) })
            return;
        }
        else
            this.setState({ [name]: value });
    }

    resetState = () => {
        this.setState({
            KPIName: '',
            KPIID: 0,
            KPIMainID: 0,
            Year: 0,
            Description: '',
            searchMainKPI: 0,
            searchForm: {},
            Form: {},
            canUpdate: false,
            Editable: false
        });
    }

    onHideModal = () => {
        var title = (this.props.currentKPIID) ? 'ยกเลิกการแก้ไขตัวชี้วัด' : 'ยกเลิกการเพิ่มตัวชี้วัด'
        var text = 'ต้องการ' + title + 'หรือไม่ ?'
        Swal.fire({
            title: title,
            text: text,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    this.props.hide(this.resetState, 5)
            })
    }

    checkUpdate = () => {
        var title = '';
        var text = '';
        var type = null;
        if (this.state.KPIName === '' || this.state.KPIName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อตัวชี้วัด';
            type = 'warning';
        }
        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (this.checkUpdate()) {
            var KPIService
            if (this.props.currentKPIID)
                KPIService = this.props.updateKPI(
                    this.props.currentKPIID,
                    this.state.KPIName,
                    this.state.Description,
                    this.state.Year,
                    this.state.KPIMainID,
                    null,
                    false,
                    null,
                    null,
                    this.state.Form.FormID
                )
            else
                KPIService = this.props.createKPI(
                    this.state.KPIName,
                    this.state.Description,
                    this.state.Year,
                    this.state.KPIMainID,
                    null,
                    false,
                    null,
                    null,
                    this.state.Form.FormID
                )
            KPIService.then(
                result => {
                    console.log(result)
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        })
                    }
                    if (!this.props.currentKPIID)
                        this.props.hide(this.resetState);
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างเรียกข้อมูล',
                        type: 'error'
                    })
                });
        }
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.onHideModal} size='xl' onEnter={this.onEnterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                (this.props.currentKPIID === null) ?
                                    'เพิ่มตัวชี้วัด' :
                                    'แก้ไขตัวชี้วัด'
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='form-group'>
                                <label className='form-label'>ปีงบประมาณ</label>
                                <input className='form-control' value={(this.state.Year === null || this.state.Year === 0) ? '' : (this.state.Year) + 543} disabled />
                            </div>
                            <div className='form-group'>
                                <label className='form-label'>การประเมิน</label>

                                <input className='form-control' value={this.checkEvaluationType(this.state.EvaluationTypeID)} disabled />
                            </div>
                            <div className='form-group'>
                                <i className='mr-1 text-danger'>*</i><label className='form-label'>ตัวชี้วัด</label>
                                <input className='form-control' placeholder='ตัวชี้วัด' name='KPIName' value={this.state.KPIName} onChange={e => this.onChangeContent(e.target.name, e.target.value)} />
                            </div>
                            <div className='form-group'>
                                <label className='form-label'>คำอธิบาย</label>
                                <textarea
                                    className='form-control'
                                    rows='5'
                                    name='Description'
                                    value={(this.state.Description || '')}
                                    onChange={e => this.onChangeContent(e.target.name, e.target.value)}
                                />
                            </div>
                            <div className='form-group mt-3'>
                                <label className='form-label'>แบบฟอร์ม</label>
                                <div className='text-primary custom-block pt-2 mb-3'>
                                    แบบฟอร์มปัจจุบัน : {this.state.Form.FormName}
                                    {
                                        (this.state.Form.FormID) ?
                                            <span
                                                className='delete-badge badge badge-danger border border-danger ml-2'
                                                onClick={() => this.onChangeContent('Form', {}, 'Form')}
                                            >
                                                <i className='fa fa-times' />
                                            </span>
                                            : null
                                    }
                                </div>
                                <div className='d-flex'>
                                    <div className='flex-fill'>
                                        <Autocomplete
                                            getData={this.props.searchForm}
                                            name='searchForm'
                                            labelName='แบบฟอร์ม'
                                            dataName='Form'
                                            listName='ListForm'
                                            onChangeSelect={this.onChangeContent}
                                            selectValue={this.state.searchForm}
                                            searchOnChange
                                        />
                                    </div>
                                    <button
                                        className='btn btn-outline-primary ml-2'
                                        onClick={() => this.onChangeContent('Form', this.state.searchForm, 'Form')}
                                    >
                                        เลือกแบบฟอร์ม
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='float-right'>
                            <button className='btn btn-outline-danger mr-2' onClick={this.onHideModal}>ยกเลิก</button>
                            <button
                                className={'btn btn-outline-' + ((!this.state.Editable) ? 'secondary' : 'primary')}
                                onClick={this.onSave}
                                disabled={(!(this.state.Editable) || false)}
                            >
                                บันทึก
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default KPIManageExcModal;