import React, { Suspense } from 'react';
import { Route, Switch, Router } from "react-router-dom";
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar/Topbar';
import Dashboard from './Dashboard/Dashboard';
import EvaluationResult from './EvaluationResult/EvaluationResult';
import KPIManage from './KPIManage/KPIManage';
import ZuperAdminKPIManage from './ZuperAdminKPIManage/KPIManage';
import ZuperAdminFormManage from './ZuperAdminFormManage/FormManage';
import EvaluationManage from './EvaluationManage/EvaluationManage';
import UserManage from './UserManage/UserManage';
import OrgManage from './OrganizationManage/OrganizationManage';
import RoleManage from './RoleManage/RoleManage';
import History from './History/History';
import UnitManage from './UnitManage/UnitManage';
import FormManage from './FormManage/FormManage';
import Schedule from './Schedule/Schedule';
//import Result from './Result/Result';
import UserInfo from './UserInfo/UserInfo';
import PersonManage from './PersonManage/PersonManage';
import PageNotFound from './PageNotFound/PageNotFound';
import { connect } from 'react-redux';
import { setAccountLogin, setProfileImg, setRoleID, setNotification, setAccountLogout, setDataLoaded } from '../Redux/Action';
import Login from './Login/Login';
import { getUsersCookies, removeAllUsersCookies } from '../Service/CookieService';
import AccountService from '../Service/AccountService';
import LoginService from '../Service/LoginService';
//import axios from 'axios';

//-------------------- React lazy -----------------------//
// const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
// const EvaluationResult = React.lazy(() => import('./EvaluationResult/EvaluationResult'));
// const KPIManage = React.lazy(() => import('./KPIManage/KPIManage'));
// const EvaluationManage = React.lazy(() => import('./EvaluationManage/EvaluationManage'));
// const UserManage = React.lazy(() => import('./UserManage/UserManage'));
// const OrgManage = React.lazy(() => import('./OrganizationManage/OrganizationManage'));
// const RoleManage = React.lazy(() => import('./RoleManage/RoleManage'));
// const History = React.lazy(() => import('./History/History'));
// const UnitManage = React.lazy(() => import('./UnitManage/UnitManage'));
// const FormManage = React.lazy(() => import('./FormManage/FormManage'));
// const Schedule = React.lazy(() => import('./Schedule/Schedule'));
// const Result = React.lazy(() => import('./Result/Result'));
// const UserInfo = React.lazy(() => import('./UserInfo/UserInfo'));
// const PersonManage = React.lazy(() => import('./PersonManage/PersonManage'));

class App extends React.Component {
    constructor(props) {
        super(props);
        if (getUsersCookies('token')) {
            this.props.dispatch(setAccountLogin({
                Email: getUsersCookies('email'),
                Username: getUsersCookies('username'),
                AccountID: getUsersCookies('accountID'),
                Token: getUsersCookies('token')
            }));
            this.props.dispatch(setProfileImg({
                data: localStorage.getItem('profileImg')
            }));
            AccountService.getRoleID(getUsersCookies('accountID'))
                .then(result => {
                    if (result.errors) {
                        this.props.dispatch(setAccountLogout());
                        this.props.dispatch(setNotification(0))
                        removeAllUsersCookies();
                        return;
                    }
                    this.setDataLoaded(true);
                    result = result.data.GetAccountDetail;
                    this.props.dispatch(setRoleID(result.RoleID));
                })
                .then(() => {
                    this.getNotification(getUsersCookies('accountID'));
                })
                .catch(err => {
                    this.props.dispatch(setAccountLogout());
                    this.props.dispatch(setNotification(0))
                    removeAllUsersCookies();
                })
        }
        // window.addEventListener('resize', (e) => {
        //     if (window.outerWidth > 880) {
        //         document.getElementById('modal-background').style.display = 'none';
        //         document.getElementById('sidebar').style.display = 'block';
        //     }
        //     if (window.outerWidth < 880 && document.getElementById('modal-background').style.display === 'none') {
        //         document.getElementById('sidebar').style.display = 'none';
        //     }
        // })
    }

    getNotification = (AccountID) => {
        if (!AccountID && this.props.accountID === 0) return;
        if (!AccountID) AccountID = this.props.accountID;
        return AccountService.getNotification(AccountID)
            .then((result) => {
                if (result.errors) {
                    return;
                }
                result = result.data.Getnotification;
                console.log(result);
                this.props.dispatch(setNotification(result.Count));
            })
    }

    onClickModalBackground = () => {
        if (this.modalBackground.className === 'background-active') {
            this.modalBackground.className = '';
            document.getElementById('sidebar').className = '';
        }
        else {
            this.modalBackground.className = 'background-active';
            document.getElementById('sidebar').className = 'sidebar-active';

        }
    }

    setDataLoaded = (data) => {
        this.props.dispatch(setDataLoaded(data));
    }

    render() {
        return (
            <>
                {this.props.isAuth ?
                    (
                        !this.props.dataLoaded ?
                            <div className='suspense-div'>
                                <div id="bars1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            :
                            <>
                                <Route path='/' render={(props) => <Sidebar {...props} roleID={this.props.roleID} notification={this.props.notification} />} />
                                <Route path='/'
                                    render={
                                        (props) =>
                                            <Topbar
                                                {...props}
                                                email={this.props.email}
                                                username={this.props.username}
                                                profileImg={this.props.profileImg}
                                                setDataLoaded={this.setDataLoaded}
                                            />
                                    }
                                />
                                <div className='content'>
                                    <Suspense
                                        fallback={
                                            <div className='suspense-div'>
                                                <div id="bars1">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <Switch>
                                            {
                                                (this.props.roleID === 1 || this.props.roleID === 2) ?
                                                    (
                                                        [
                                                            <Route exact path='/PMQAKPI/KPIManagement' component={KPIManage} key='KPIManage' />,
                                                            <Route exact path='/PMQAKPI/EvaluationManage' component={EvaluationManage} key='EvaluationManage' />,
                                                            <Route exact path='/PMQAKPI/UnitManagement' component={UnitManage} key='UnitManage' />,
                                                            <Route exact path='/PMQAKPI/FormManagement' component={FormManage} key='FormManage' />,
                                                            <Route exact path='/PMQAKPI/Schedule' component={Schedule} key='Schedule' />,
                                                            <Route exact path='/PMQAKPI/PersonManagement' component={PersonManage} key='PersonManage' />,
                                                            <Route exact path='/AdminManagement/UserManagement' component={UserManage} key='UserManage' />,
                                                            <Route exact path='/AdminManagement/OrgManagement' component={OrgManage} key='OrgManage' />,
                                                            <Route exact path='/AdminManagement/RoleManagement' component={RoleManage} key='RoleManage' />,
                                                            <Route exact path='/ZuperAdmin/KPIManagement' component={ZuperAdminKPIManage} key='ZuperAdminKPIManage' />,
                                                            <Route exact path='/ZuperAdmin/FormManagement' component={ZuperAdminFormManage} key='ZuperAdminFormManage' />,
                                                        ]
                                                    )
                                                    : null
                                            }
                                            {/* 
                                            <Route exact path='/' component={Dashboard} />
                                            <Route exact path='/PMQAKPI/KPIManagement' component={KPIManage} />
                                            <Route exact path='/PMQAKPI/EvaluationManage' component={EvaluationManage} />
                                            <Route exact path='/PMQAKPI/UnitManagement' component={UnitManage} />
                                            <Route exact path='/PMQAKPI/FormManagement' component={FormManage} />
                                            <Route exact path='/PMQAKPI/Schedule' component={Schedule} />
                                            <Route exact path='/PMQAKPI/PersonManagement' component={PersonManage} />
                                            <Route exact path='/Evaluation/KPIReport' component={Dashboard} />
                                            <Route exact path='/Dashboard' component={Dashboard} />
                                            <Route exact path='/AdminManagement/UserManagement' component={UserManage} />
                                            <Route exact path='/AdminManagement/OrgManagement' component={OrgManage} />
                                            <Route exact path='/AdminManagement/RoleManagement' component={RoleManage} />
                                            <Route exact path='/AdminManagement/History' component={History} /> 
                                        */}
                                            <Route exact path='/' render={(props) => <Dashboard {...props} roleID={this.props.roleID} />} />
                                            <Route exact path='/Dashboard' render={(props) => <Dashboard {...props} roleID={this.props.roleID} />} />
                                            <Route exact path='/Evaluation/EvaluationResult' render={(props) => <EvaluationResult {...props} getNotification={this.getNotification} roleID={this.props.roleID} />} />
                                            <Route exact path='/UserInfo' component={UserInfo} />
                                            <Route exact path='/AdminManagement/History' component={History} />
                                            <Route path='/' component={PageNotFound} />
                                        </Switch>
                                    </Suspense>
                                </div>
                            </>
                    ) : (
                        <Route path='/' render={props => <Login {...props} getNotification={this.getNotification} setDataLoaded={this.setDataLoaded} />} />
                    )
                }
                <div id='modal-background-app' ref={el => this.modalBackground = el} onClick={this.onClickModalBackground}></div>
                <div id="Loading" className={"preloader " + this.props.showLoading}>
                    <div className="ball-rotate">
                        <div></div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
        isAuth: state.isAuth,
        email: state.email,
        accountID: state.accountID,
        username: state.username,
        showLoading: state.showLoading,
        roleID: state.roleID,
        profileImg: state.profileImg,
        notification: state.notification,
        dataLoaded: state.dataLoaded
    }
}

export default connect(mapStatetoProps)(App);
