import Axios from './BaseService';
class ReportService {

    getChartData(type, Year, month, OrganizationID, KPIID, Person) {
        OrganizationID = (OrganizationID) ? ((OrganizationID.OrganizationID) ? OrganizationID.OrganizationID : null) : null;
        KPIID = (KPIID) ? ((KPIID.KPIID) ? KPIID.KPIID : null) : null;
        Person = (Person) ? ((Person.PersonID) ? Person : null) : null;
        const GETCHARTDATA = `
            query SearchReport(
                $EvaluationTypeID: Int!
                $Year: Int!
                $Month: String
                $OrganizationID: Int
                $KPIID: Int
                $Person: InputPerson
                ){
                SearchReport(
                    EvaluationTypeID: $EvaluationTypeID
                    Year: $Year
                    Month: $Month
                    OrganizationID: $OrganizationID
                    KPIID: $KPIID
                    Person: $Person
                    ){
                    StatusReport{
                        StatusID
                        StatusName
                        Total
                    }
                    ResultReport{
                        ResultStatusID
                        ResultStatusName
                        Total
                    }
                    StatusReportByPolicy{
                        PolicyID
                        PolicyName
                        StatusReports{
                            StatusID
                            StatusName
                            Total
                        }   
                    }
                }
            }
        `;
        return Axios.post('/pmqaservice', {
            query: GETCHARTDATA,
            variables: {
                EvaluationTypeID: type,
                Year: Year,
                Month: month,
                OrganizationID: OrganizationID,
                KPIID: KPIID,
                Person: Person
            }
        })
    }

    getReportResult(type, Year, OrganizationID, KPIID, Person) {
        OrganizationID = (OrganizationID) ? ((OrganizationID.OrganizationID) ? OrganizationID.OrganizationID : null) : null;
        KPIID = (KPIID) ? ((KPIID.KPIID) ? KPIID.KPIID : null) : null;
        Person = (Person) ? ((Person.PersonID) ? Person : null) : null;
        const GETREPORTRESULT = `
            query GetReportResult(
                $Year: Int!,
                $EvaluationTypeID: Int!
                $OrganizationID: Int
                $KPIID: Int
                $Person: InputPerson
            ) {
                GetReportResult(
                    Year: $Year, 
                    EvaluationTypeID: $EvaluationTypeID
                    OrganizationID: $OrganizationID
                    KPIID: $KPIID
                    Person: $Person
                ) {
                    Total
                    EvaluationTypeID
                    EvaluationTypeName
                    ListSent {
                        KPIID
                        KPIName
                        COUNT
                        ListOrganization {
                            OrganizationID
                            OrganizationName
                            OrganizationShortName
                            COUNTReport
                        }
                    }
                    ListNoSent {
                        KPIID
                        KPIName
                        COUNT
                        ListOrganization {
                            OrganizationID
                            OrganizationName
                            OrganizationShortName
                            COUNTReport
                        }
                    }
                }
            }
        `;

        console.log({
            Year: Year,
            EvaluationTypeID: type,
            OrganizationID: OrganizationID,
            KPIID: KPIID,
            Person: Person
        })

        return Axios.post('/pmqaservice', {
            query: GETREPORTRESULT,
            variables: {
                Year: parseInt(Year),
                EvaluationTypeID: parseInt(type),
                OrganizationID: OrganizationID,
                KPIID: KPIID,
                Person: Person
            },
            errorPolicy: 'all'
        })
    }

    getReportResultByOrganization(EvaluationTypeID, Year, OrganizationID, KPIID, Person) {
        OrganizationID = (OrganizationID) ? ((OrganizationID.OrganizationID) ? OrganizationID.OrganizationID : null) : null;
        KPIID = (KPIID) ? ((KPIID.KPIID) ? KPIID.KPIID : null) : null;
        Person = (Person) ? ((Person.PersonID) ? Person : null) : null;
        const GETREPORTRESULTBYOPRGANIZATION = `
            query GetReportResultByOrganization(
                $Year: Int!
                $EvaluationTypeID: Int!
                $OrganizationID: Int
                $KPIID: Int
                $Person: InputPerson
            ) {
                GetReportResultByOrganization(
                    Year: $Year
                    EvaluationTypeID: $EvaluationTypeID
                    OrganizationID: $OrganizationID
                    KPIID: $KPIID
                    Person: $Person
                ) {
                    Total
                    EvaluationTypeID
                    EvaluationTypeName
                    ListOrganization {
                        COUNTReport
                        COUNTSent
                        COUNTNoSent
                        OrganizationID
                        OrganizationName
                        OrganizationShortName
                    }
                }
            }
        `;

        console.log({
            Year: Year,
            EvaluationTypeID: EvaluationTypeID,
            OrganizationID: OrganizationID,
            KPIID: KPIID,
            Person: Person
        })

        return Axios.post('/pmqaservice', {
            query: GETREPORTRESULTBYOPRGANIZATION,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID,
                OrganizationID: OrganizationID,
                KPIID: KPIID,
                Person: Person
            }
        });
    }

    getReportEvaluationCriteria(EvaluationTypeID, Year, Month, OrganizationID, KPIID, Person) {
        OrganizationID = (OrganizationID) ? ((OrganizationID.OrganizationID) ? OrganizationID.OrganizationID : null) : null;
        KPIID = (KPIID) ? ((KPIID.KPIID) ? KPIID.KPIID : null) : null;
        Person = (Person) ? ((Person.PersonID) ? Person : null) : null;
        const GETREPORTEVAlUATIONCRITERIA = `
            query GetReportEvaluationCriteria(
                $Year: Int!, 
                $EvaluationTypeID: Int!, 
                $Month: String!
                $OrganizationID: Int
                $KPIID: Int
                $Person: InputPerson
                ){
                GetReportEvaluationCriteria(
                    Year: $Year
                    EvaluationTypeID: $EvaluationTypeID
                    Month: $Month
                    OrganizationID: $OrganizationID
                    KPIID: $KPIID
                    Person: $Person
                ){
                    ResultName
                    ResultID
                    COUNT
                }
            }
        `;

        console.log({
            Year: Year,
            EvaluationTypeID: EvaluationTypeID,
            Month: Month,
            OrganizationID: OrganizationID,
            KPIID: KPIID,
            Person: Person
        })

        return Axios.post('/pmqaservice', {
            query: GETREPORTEVAlUATIONCRITERIA,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID,
                Month: Month,
                OrganizationID: OrganizationID,
                KPIID: KPIID,
                Person: Person
            },
            errorPolicy: 'all'
        });
    }

    getReportResultByYear(EvaluationTypeID, Year, OrgID, KPIID) {
        console.log(EvaluationTypeID, Year);
        const GETREPORTRESULTBYYEAR = `
        query GetReportResultByYear($Year: Int!, $EvaluationTypeID: Int!) {
            GetReportResultByYear(
              EvaluationTypeID: $EvaluationTypeID
              Year: $Year
            ){
              Year
              EvaluationTypeName
              SumResultScoreByKPI
              SumResultPlanByKPI
              SumResultScoreByKPIMain
              SumResultPlanByKPIMain
              Policy{
                PolicyID
                PolicyName
                Remark
                KPIMain{
                  PolicyID
                  KPIMainID
                  KPIMainName
                  ResultScore
                  ResultPlan
                  EvaluationCriteria{
                      Weight
                      VeryLow{
                          Result
                          Target
                          Description
                      }
                      Low{
                          Result
                          Target
                          Description
                      }
                      Mid{
                          Result
                          Target
                          Description
                      }
                      High{
                          Result
                          Target
                          Description
                      }
                      VeryHigh{
                          Result
                          Target
                          Description
                      }
                  }
                  KPI{
                    KPIMainID
                    KPIID
                    KPIName
                    ResultScore
                    ResultPlan
                    Pass
                    ResultText
                    ResultValue
                    EvaluationCriteria{
                        Weight
                        Type
                        Unit
                        VeryLow{
                            Result
                            Target
                            Description
                        }
                        Low{
                            Result
                            Target
                            Description
                        }
                        Mid{
                            Result
                            Target
                            Description
                        }
                        High{
                            Result
                            Target
                            Description
                        }
                        VeryHigh{
                            Result
                            Target
                            Description
                        }
                    }
                  }
                }
              }
            }
          }
        `;

        return Axios.post('/pmqaservice', {
            query: GETREPORTRESULTBYYEAR,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID
            }
        });
    }

    GetReportOrganizationType6(Year) {
        const GETREPORTORGANIZATIONTYPE6 = `
        query GetReportOrganizationType6($Year: Int!) {
            GetReportOrganizationType6(
              Year: $Year
            ){
                OrganizationName
                KPI {
                    KPIName
                    KPIID
                    PolicyOrganizationID
                    Result
                    Target
                    Type
                    Unit
                    Pass
                }
            }
        }
        `;
        return Axios.post('/pmqaservice', {
            query: GETREPORTORGANIZATIONTYPE6,
            variables: {
                Year: Year
            }
        });
    }

    reportTrendResult(EvaluationTypeID, Year, Month, OrganizationID, KPIID, Person) {
        OrganizationID = (OrganizationID) ? ((OrganizationID.OrganizationID) ? OrganizationID.OrganizationID : null) : null;
        KPIID = (KPIID) ? ((KPIID.KPIID) ? KPIID.KPIID : null) : null;
        Person = (Person) ? ((Person.PersonID) ? Person : null) : null;
        const REPORTTRENDRESULT = `
        query GetReportTrendsResultByYear($Year: Int!, $EvaluationTypeID: Int!, $OrganizationID: Int, $KPIID: Int, $Person: InputPerson) {
            GetReportTrendsResultByYear(
              EvaluationTypeID: $EvaluationTypeID
              Year: $Year
              OrganizationID: $OrganizationID
              KPIID: $KPIID
              Person: $Person
            ){
              Year
              EvaluationTypeName
              SumResultScoreByKPI
              SumResultPlanByKPI
              SumResultScoreByKPIMain
              SumResultPlanByKPIMain
              Policy{
                PolicyID
                PolicyName
                Remark
                KPIMain{
                  PolicyID
                  KPIMainID
                  KPIMainName
                  ResultScore
                  ResultPlan
                  EvaluationCriteria{
                      Weight
                      VeryLow{
                          Result
                          Target
                          Description
                      }
                      Low{
                          Result
                          Target
                          Description
                      }
                      Mid{
                          Result
                          Target
                          Description
                      }
                      High{
                          Result
                          Target
                          Description
                      }
                      VeryHigh{
                          Result
                          Target
                          Description
                      }
                  }
                  KPI{
                    KPIMainID
                    KPIID
                    KPIName
                    ResultScore
                    ResultPlan
                    Pass
                    ResultText
                    ResultValue
                    EvaluationCriteria{
                        Weight
                        Type
                        Unit
                        VeryLow{
                            Result
                            Target
                            Description
                        }
                        Low{
                            Result
                            Target
                            Description
                        }
                        Mid{
                            Result
                            Target
                            Description
                        }
                        High{
                            Result
                            Target
                            Description
                        }
                        VeryHigh{
                            Result
                            Target
                            Description
                        }
                    }
                  }
                }
              }
            }
          }
        `;

        return Axios.post('/pmqaservice', {
            query: REPORTTRENDRESULT,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID,
                OrganizationID: OrganizationID,
                KPIID: KPIID,
                Person: Person
            }
        });
    }

    createReportProfile(ProfileName, ProfileData) {
        console.log(ProfileData)
        ProfileData = (typeof ProfileData === 'object') ? JSON.stringify(ProfileData) : ProfileData;

        const CREATEREPORTPROFILE = `
            mutation CreateReportProfile($ProfileName: String!, $ProfileData: String) {
                CreateReportProfile(ProfileName: $ProfileName, ProfileData: $ProfileData) {
                    ProfileID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: CREATEREPORTPROFILE,
            variables: {
                ProfileName: ProfileName,
                ProfileData: ProfileData
            },
            errorPolicy: 'all'
        });
    }

    getReportProfile(ProfileID) {
        const GETREPORTPROFILE = `
            query GetReportProfile{
                GetReportProfile{
                    ProfileID
                    ProfileName
                    ProfileData
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETREPORTPROFILE,
            errorPolicy: 'all'
        });
    }

    deleteReportProfile(ProfileID) {
        const DELETEREPORTPROFILE = `
            mutation DeleteReportProfile($ProfileID: Int!) {
                DeleteReportProfile(ProfileID: $ProfileID) {
                    ProfileID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEREPORTPROFILE,
            variables: {
                ProfileID: ProfileID
            },
            errorPolicy: 'all'
        });
    }

    getExcelCSV(EvaluationTypeID, Year, ListKPI, Type) {
        console.log(EvaluationTypeID, Year, ListKPI, Type)
        return Axios.post('/getexcel', {
            Year: Year,
            EvaluationTypeID: EvaluationTypeID,
            ListKPI: ListKPI,
            TypeFile: Type
        }, {
            responseType: 'blob'
        });
    }
}

export default new ReportService();