import React from 'react';
import Autocomplete from '../../Utilities/Autocomplete';
import OrganizationService from '../../Service/OrganizationService';
import { showLoading, hideLoading } from '../../Redux/Action';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import OrganizationManageTable from './OrganizationManage_component/OrganizationManage_table';
import OrganizationManageModal from './OrganizationManage_component/OrganizationManage_modal';
import OrganizationChart from './OrganizationManage_component/OrganizationChart';
import queryString from 'query-string';

class OrganizationManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: [],
            dataCount: 0,
            searchOrganization: {},
            searched: {
                isSearched: false,
                searchedOrganization: {}
            },
            currentOrganizationID: null,
            currentPage: 1,
            limit: 5,
            Organization: [],
            showOU: false
        }
    }

    componentDidMount() {
        this.searchOrganizationState(null)
        this.searchOrganization()
            .then(result => {
                if (result.errors) {
                    return;
                }
                this.setState({ Organization: result.data.SearchOrganization });
            })
            .catch(err => {
                console.log(err);
            })
        if (this.props.location) {
            var search = queryString.parse(this.props.location.search);
            if (search && search.create === 'true') {
                this.setState({ show: true });
            }
        }
    }

    searchOrganization = (Name) => {
        return OrganizationService.searchOrganization(Name);
    }

    getOrganizationDetail = (OrganizationID) => {
        this.props.dispatch(showLoading());
        return OrganizationService.getOrganizationDetail(OrganizationID)
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    searchOrganizationState = (Name) => {
        this.props.dispatch(showLoading());
        this.searchOrganization(Name)
            .then(result => {
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: result.errors[0].Message,
                        type: 'error'
                    });
                    return;
                }
                console.log(result);
                this.setState({
                    data: result.data.SearchOrganization,
                    dataCount: result.data.SearchOrganization.length
                })
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            });
    }

    searchOrganizationAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchOrganizationState(
                this.state.searched.searchedOrganization.OrganizationName
            )
        }
        else {
            this.searchOrganizationState(
                null,
            )
        }
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.getOrganizationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
        else
            this.setState({ [name]: value }, () => {
                this.getOrganizationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
    }

    onChangeSearch = (name, value) => {
        this.setState({ [name]: value });
    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.getOrganizationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
        else
            this.setState({ [name]: value }, () => {
                this.getOrganizationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
    }

    changeCurrentOrganizationID = (index) => {
        this.setState({
            currentOrganizationID: index,
            show: true
        });
    }

    getOrganizationDataTable = (Offset, Limit) => {
        var newData = [];
        var data = [...this.state.data];
        if (data.length === 0) return [];
        for (var i = Offset; i < (Offset + Limit); i++) {
            if (data[i] === undefined)
                break;
            newData.push(data[i]);
        }
        return newData;
    }

    clickSearch = () => {
        if (
            this.state.searchOrganization.OrganizationID === undefined
        ) {
            this.clearSearch();
            return;
        }
        this.setState({
            //searchOrganization: {},
            currentPage: 1,
            searched: {
                isSearched: true,
                searchedOrganization: this.state.searchOrganization,
            }
        }, () => {
            this.searchOrganizationAfterSetState();
        });
    }

    clearSearch = () => {
        this.setState({
            searchOrganization: {},
            currentPage: 1,
            searched: {
                isSearched: false,
                searchedOrganization: {}
            }
        }, () => {
            this.searchOrganizationState(
                null,
            )
        })
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false,
                currentOrganizationID: null
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    createOrganization = (
        OrganizationCode,
        OrganizationName,
        ParentOrganizationID,
        OrganizationShortName,
        OrganizationLevel,
        Address,
        ZipCode,
        Tel,
        Fax,
        Email,
        Lat,
        Lng,
        Description
    ) => {
        this.props.dispatch(showLoading());
        return OrganizationService.createOrganization(
            OrganizationCode,
            OrganizationName,
            ParentOrganizationID,
            OrganizationShortName,
            OrganizationLevel,
            Address,
            ZipCode,
            Tel,
            Fax,
            Email,
            Lat,
            Lng,
            Description
        ).then(result => {
            console.log(result);
            if (result.errors) {
                this.props.dispatch(hideLoading());
            }
            else
                this.searchOrganizationAfterSetState()
            return result;
        })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    updateOrganization = (
        OrganizationID,
        OrganizationCode,
        OrganizationName,
        ParentOrganizationID,
        OrganizationShortName,
        OrganizationLevel,
        Address,
        ZipCode,
        Tel,
        Fax,
        Email,
        Lat,
        Lng,
        Description
    ) => {
        this.props.dispatch(showLoading());
        return OrganizationService.updateOrganization(
            OrganizationID,
            OrganizationCode,
            OrganizationName,
            ParentOrganizationID,
            OrganizationShortName,
            OrganizationLevel,
            Address,
            ZipCode,
            Tel,
            Fax,
            Email,
            Lat,
            Lng,
            Description
        ).then(result => {
            if (result.errors) {
                this.props.dispatch(hideLoading());
            }
            else
                this.searchOrganizationAfterSetState()
            return result;
        })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    deleteOrganization = (OrganizationID) => {
        Swal.fire({
            title: 'ลบหน่วยงาน',
            text: 'ต้องการลบหน่วยงานนี้หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    OrganizationService.deleteOrganization(OrganizationID)
                        .then(result => {
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                                return;
                            }
                            Swal.fire({
                                title: 'ลบข้อมูลสำเร็จ',
                                type: 'success'
                            })
                            this.searchOrganizationAfterSetState();
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                type: 'error'
                            });
                        });
            });
    }

    showOU = () => {
        this.setState({ showOU: true });
    }

    closeOU = () => {
        this.setState({ showOU: false });
    }

    render() {
        return (
            <>
                <div className='custom-block mb-4'>
                    <div className='row align-items-end'>
                        <div className='form-group col-12 col-lg-6'>
                            <label className='form-label'>หน่วยงาน</label>
                            <Autocomplete
                                getData={this.searchOrganization}
                                name='searchOrganization'
                                labelName='หน่วยงาน'
                                dataName='Organization'
                                onChangeSelect={this.onChangeSearch}
                                selectValue={this.state.searchOrganization}
                                searchOnChange
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.clickSearch}>ค้นหา</button>
                    </div>
                </div>

                <div className='custom-block mb-4'>
                    <OrganizationManageTable
                        data={this.getOrganizationDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit)}
                        dataCount={this.state.dataCount}
                        onChangeTableState={this.onChangeTableState}
                        searched={this.state.searched}
                        currentPage={this.state.currentPage}
                        limit={this.state.limit}
                        clickAddOrganization={this.hideModal}
                        clickDeleteOrganization={this.deleteOrganization}
                        changeCurrentOrganizationID={this.changeCurrentOrganizationID}
                        showOU={this.showOU}
                    />
                </div>
                <OrganizationManageModal
                    show={this.state.show}
                    hide={this.hideModal}
                    searchOrganization={this.searchOrganization}
                    createOrganization={this.createOrganization}
                    updateOrganization={this.updateOrganization}
                    currentOrganizationID={this.state.currentOrganizationID}
                    getOrganizationDetail={this.getOrganizationDetail}
                />
                <OrganizationChart
                    data={this.state.Organization}
                    closeWindowPortal={this.closeOU}
                    show={this.state.showOU}
                    hide={this.closeOU}
                />
            </>
        );
    }
}

export default connect(null)(OrganizationManage);