import Axios from "./BaseService";

class SecheduleService {

    searchSchedule(ScheduleName, Mode) {
        ScheduleName = (!ScheduleName) ? null : ScheduleName.trim();
        Mode = (Mode === 0) ? null : Mode;
        console.log(ScheduleName, Mode)
        const SEARCHSCHEDULE = `
        query SearchSchedule($ScheduleName: String, $Mode: Int){
            SearchSchedule(ScheduleName: $ScheduleName, Mode: $Mode){
                ScheduleID
                ScheduleName
                Mode
                DayOfWeekStart
                DayOfWeekEnd
                DayOfMonthStart
                DayOfMonthEnd
                CreatedDateTime
                ModifiedDateTime
                CreatedUsername
                ModifiedUsername
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: SEARCHSCHEDULE,
            variables: {
                ScheduleName: ScheduleName,
                Mode: parseInt(Mode)
            },
            errorPolicy: 'all'
        })
    }

    createSchedule(ScheduleName, Mode, DayOfWeekStart, DayOfWeekEnd, DayOfMonthStart, DayOfMonthEnd) {
        ScheduleName = (!ScheduleName) ? null : ScheduleName.trim();
        const CREATESCHEDULE = `
        mutation CreateSchedule(
            $ScheduleName: String!, 
            $Mode: Int!,
            $DayOfWeekStart: Int, 
            $DayOfWeekEnd: Int, 
            $DayOfMonthStart: Int, 
            $DayOfMonthEnd: Int
            ){
                CreateSchedule(
                    ScheduleName: $ScheduleName, 
                    Mode: $Mode,
                    DayOfWeekStart: $DayOfWeekStart, 
                    DayOfWeekEnd: $DayOfWeekEnd, 
                    DayOfMonthStart: $DayOfMonthStart, 
                    DayOfMonthEnd: $DayOfMonthEnd
                ) {
                    ScheduleID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: CREATESCHEDULE,
            variables: {
                ScheduleName: ScheduleName,
                Mode: Mode,
                DayOfWeekStart: DayOfWeekStart,
                DayOfWeekEnd: DayOfWeekEnd,
                DayOfMonthStart: DayOfMonthStart,
                DayOfMonthEnd: DayOfMonthEnd
            },
            errorPolicy: 'all'
        })
    }

    updateSchedule(ScheduleID, ScheduleName, Mode, DayOfWeekStart, DayOfWeekEnd, DayOfMonthStart, DayOfMonthEnd) {
        ScheduleName = (!ScheduleName) ? null : ScheduleName.trim();
        const UPDAtESCHEDULE = `
        mutation UpdateSchedule(
            $ScheduleID: Int!,
            $ScheduleName: String!, 
            $Mode: Int!,
            $DayOfWeekStart: Int, 
            $DayOfWeekEnd: Int, 
            $DayOfMonthStart: Int, 
            $DayOfMonthEnd: Int
            ){
                UpdateSchedule(
                    ScheduleID: $ScheduleID,
                    ScheduleName: $ScheduleName, 
                    Mode: $Mode,
                    DayOfWeekStart: $DayOfWeekStart, 
                    DayOfWeekEnd: $DayOfWeekEnd, 
                    DayOfMonthStart: $DayOfMonthStart, 
                    DayOfMonthEnd: $DayOfMonthEnd
                ) {
                    ScheduleID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDAtESCHEDULE,
            variables: {
                ScheduleID: ScheduleID,
                ScheduleName: ScheduleName,
                Mode: Mode,
                DayOfWeekStart: DayOfWeekStart,
                DayOfWeekEnd: DayOfWeekEnd,
                DayOfMonthStart: DayOfMonthStart,
                DayOfMonthEnd: DayOfMonthEnd
            },
            errorPolicy: 'all'
        })
    }

    deleteSchedule = (ScheduleID) => {
        const DELETESCHEDULE = `
            mutation DeleteSchedule($ScheduleID: Int!) {
                DeleteSchedule(ScheduleID : $ScheduleID) {
                    ScheduleID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETESCHEDULE,
            variables: {
                ScheduleID: ScheduleID
            },
            errorPolicy: 'all'
        });
    }

    updateSpecialSchedule = (ListKPIs, PeriodStart, PeriodEnd) => {
        const UPDATESPECIALSCHEDULE = `
            mutation UpdateSpecialSchedule($KPIID : [InputListKPIID], $DateStart: String, $DateEnd: String) {
                UpdateSpecialSchedule(KPIID : $KPIID, DateStart: $DateStart, DateEnd: $DateEnd) {
                    KPIID 
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATESPECIALSCHEDULE,
            variables: {
                KPIID: ListKPIs,
                DateStart: PeriodStart,
                DateEnd: PeriodEnd
            },
            errorPolicy: 'all'
        });
    }
}

export default new SecheduleService();