import Axios from './BaseService';

class PolicyService {

    searchEvaluation(Year, EvaluationTypeID) {
        Year = (Year === 0) ? null : Year;
        EvaluationTypeID = (EvaluationTypeID === 0) ? null : EvaluationTypeID;
        const SEARCHEVALUATION = `
            query SearchEvaluation($Year: Int, $EvaluationTypeID: Int) {
                SearchEvaluation(Year: $Year, EvaluationTypeID: $EvaluationTypeID) {
                    Year
                    EvaluationTypeID
                    EvaluationTypeName
                }
            }
        `

        return Axios.post('/pmqaservice', {
            query: SEARCHEVALUATION,
            variables: {
                Year: parseInt(Year),
                EvaluationTypeID: parseInt(EvaluationTypeID)
            },
            errorPolicy: 'all'
        });
    }

    getEvaluationType() {
        const GETEVALUATIONTYPE = `
            {
                GetEvaluationType{
                    EvaluationTypeID
                    EvaluationTypeName
                }
            }
        `

        return Axios.post('/pmqaservice', {
            query: GETEVALUATIONTYPE,
            errorPolicy: 'all'
        });
    }

    getPolicyAll() {
        const GETPOLICYALL = `
            {
                GetPolicyAll{
                    Policy {
                        PolicyName
                        PolicyID
                    }
                    PolicyOrganization {
                        PolicyOrganizationName
                        PolicyOrganizationID
                    }
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETPOLICYALL,
            errorPolicy: 'all'
        });
    }

    getEvaluationDetail(Year, EvaluationTypeID) {
        Year = (Year === 0) ? null : Year;
        EvaluationTypeID = (EvaluationTypeID === 0) ? null : EvaluationTypeID;
        const GETEVALUATIONDETAIL = `
            query GetEvaluationDetail($Year: Int!, $EvaluationTypeID: Int!){
                GetEvaluationDetail(Year: $Year, EvaluationTypeID: $EvaluationTypeID){
                    Year
                    EvaluationTypeID
                    PeriodStart
                    PeriodEnd
                    ScheduleID
                    SupportStrategyID
                    KPIMains{
                        KPIMainID
                        PolicyID
                        PMQASectionID
                        KPIMainName
                        Remark
                        ScheduleID
                        EvaluationCriteria{
                            Weight
                            PointPass
                            PointFull
                            Low{
                                Result
                                Target
                            }
                            Mid{
                                Result
                                Target
                            }
                            High{
                                Result
                                Target
                            }
                        }
                    }
                    Strategy{
                        StrategyID
                        StrategyName
                        ListObjective{
                            ObjectiveID
                            ObjectiveName
                        }
                    }
                    Policies{
                        PolicyID
                        PolicyName
                        Remark
                    }
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETEVALUATIONDETAIL,
            variables: {
                Year: parseInt(Year),
                EvaluationTypeID: parseInt(EvaluationTypeID)
            },
            errorPolicy: 'all'
        })
    }

    createEvaluation(Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID) {
        Year = (Year === 0) ? null : Year;
        EvaluationTypeID = (EvaluationTypeID === 0) ? null : EvaluationTypeID;
        SupportID = (SupportID === 0) ? null : SupportID;
        var variables = {
            Year: Year,
            EvaluationTypeID: EvaluationTypeID,
            PeriodStart: PeriodStart,
            PeriodEnd: PeriodEnd,
            ScheduleID: ScheduleID
        }
        console.log(variables)
        var CREATEEVALUATION;
        switch (EvaluationTypeID) {
            case 1:
            case 2: {
                CREATEEVALUATION = `
                    mutation CreateEvaluation(
                            $Year: Int!, 
                            $EvaluationTypeID: Int!, 
                            $Strategy: [InputStrategy], 
                            $PeriodStart: Date!,
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int
                        ){
                        CreateEvaluation(
                            Year: $Year, 
                            EvaluationTypeID: $EvaluationTypeID, 
                            Strategy: $Strategy, 
                            PeriodStart: $PeriodStart, 
                            PeriodEnd: $PeriodEnd, 
                            ScheduleID: $ScheduleID
                            ){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                variables = {
                    ...variables,
                    Strategy: MainKPIs
                }
                break;
            }
            case 3:
            case 6: {
                CREATEEVALUATION = `
                    mutation CreateEvaluation(
                            $Year: Int!,
                            $EvaluationTypeID: Int!, 
                            $KPIMains: [InputKPIMains], 
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int
                         ){
                        CreateEvaluation(Year: $Year, EvaluationTypeID: $EvaluationTypeID, KPIMains: $KPIMains, PeriodStart: $PeriodStart, PeriodEnd: $PeriodEnd, ScheduleID: $ScheduleID){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                variables = {
                    ...variables,
                    KPIMains: MainKPIs
                }
                console.log(variables)
                break;
            }
            case 4: {
                CREATEEVALUATION = `
                    mutation CreateEvaluation(
                            $Year: Int!,
                            $EvaluationTypeID: Int!, 
                            $KPIMains: [InputKPIMains], 
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int,
                            $SupportStrategy: Int
                         ){
                        CreateEvaluation(
                            Year: $Year, 
                            EvaluationTypeID: $EvaluationTypeID, 
                            KPIMains: $KPIMains, 
                            PeriodStart: $PeriodStart, 
                            PeriodEnd: $PeriodEnd, 
                            ScheduleID: $ScheduleID,
                            SupportStrategy: $SupportStrategy
                            ){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                variables = {
                    ...variables,
                    KPIMains: MainKPIs,
                    SupportStrategy: SupportID
                }
                break;
            }
            case 5: {
                CREATEEVALUATION = `
                    mutation CreateEvaluation(
                            $Year: Int!, 
                            $EvaluationTypeID: Int!
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int
                        ){
                        CreateEvaluation(Year: $Year, EvaluationTypeID: $EvaluationTypeID, PeriodStart: $PeriodStart, PeriodEnd: $PeriodEnd, ScheduleID: $ScheduleID){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                break;
            }
            default: break;
        }

        console.log(variables);
        return Axios.post('/pmqaservice', {
            query: CREATEEVALUATION,
            variables: variables,
            errorPolicy: 'all'
        });
    }

    updateEvaluation = (Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID) => {
        console.log(Year, EvaluationTypeID, MainKPIs, PeriodStart, PeriodEnd, ScheduleID, SupportID)
        Year = (Year === 0) ? null : Year;
        EvaluationTypeID = (EvaluationTypeID === 0) ? null : EvaluationTypeID;
        SupportID = (SupportID === 0) ? null : SupportID;
        var variables = {
            Year: parseInt(Year),
            EvaluationTypeID: parseInt(EvaluationTypeID),
            PeriodStart: PeriodStart,
            PeriodEnd: PeriodEnd,
            ScheduleID: ScheduleID
        }
        var UPDATEEVALUATION;
        switch (EvaluationTypeID) {
            case 1:
            case 2: {
                UPDATEEVALUATION = `
                mutation UpdateEvaluation(
                            $Year: Int!, 
                            $EvaluationTypeID: Int!, 
                            $Strategy: [InputStrategy],
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int!
                        ){
                        UpdateEvaluation(
                            Year: $Year, 
                            EvaluationTypeID: $EvaluationTypeID, 
                            Strategy: $Strategy, 
                            PeriodStart: $PeriodStart, 
                            PeriodEnd: $PeriodEnd, 
                            ScheduleID: $ScheduleID){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;

                variables = {
                    ...variables,
                    Strategy: MainKPIs
                }
                break;
            }
            case 3:
            case 6: {
                UPDATEEVALUATION = `
                    mutation UpdateEvaluation(
                            $Year: Int!, 
                            $EvaluationTypeID: Int!, 
                            $KPIMains: [InputKPIMains],
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int!
                        ){
                        UpdateEvaluation(
                            Year: $Year, 
                            EvaluationTypeID: $EvaluationTypeID, 
                            KPIMains: $KPIMains, 
                            PeriodStart: $PeriodStart, 
                            PeriodEnd: $PeriodEnd, 
                            ScheduleID: $ScheduleID){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                variables = {
                    ...variables,
                    KPIMains: MainKPIs
                }
                console.log(variables)
                break;
            }
            case 4: {
                UPDATEEVALUATION = `
                    mutation UpdateEvaluation(
                            $Year: Int!, 
                            $EvaluationTypeID: Int!, 
                            $KPIMains: [InputKPIMains],
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int!
                            $SupportStrategy: Int
                        ){
                        UpdateEvaluation(
                            Year: $Year, 
                            EvaluationTypeID: $EvaluationTypeID, 
                            KPIMains: $KPIMains, 
                            PeriodStart: $PeriodStart, 
                            PeriodEnd: $PeriodEnd, 
                            ScheduleID: $ScheduleID,
                            SupportStrategy: $SupportStrategy
                            ){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                variables = {
                    ...variables,
                    KPIMains: MainKPIs,
                    SupportStrategy: SupportID
                }
                break;
            }
            case 5: {
                UPDATEEVALUATION = `
                    mutation UpdateEvaluation(
                            $Year: Int!, 
                            $EvaluationTypeID: Int!, 
                            $KPIMains: [InputKPIMains],
                            $PeriodStart: Date!, 
                            $PeriodEnd:Date!, 
                            $ScheduleID: Int!
                        ){
                        UpdateEvaluation(
                            Year: $Year, 
                            EvaluationTypeID: $EvaluationTypeID, 
                            KPIMains: $KPIMains, 
                            PeriodStart: $PeriodStart, 
                            PeriodEnd: $PeriodEnd, 
                            ScheduleID: $ScheduleID){
                            Year
                            EvaluationTypeID
                        }
                    }
                `;
                variables = {
                    ...variables,
                    KPIMains: MainKPIs
                }
                break;
            }
            default: break;
        }

        return Axios.post('/pmqaservice', {
            query: UPDATEEVALUATION,
            variables: variables,
            errorPolicy: 'all'
        });
    }

    deleteMainKPI(KPIMains, EvaluationTypeID) {
        var DELETEMAINKPI;
        var variables;
        switch (EvaluationTypeID) {
            case 1:
            case 2: {
                DELETEMAINKPI = `
                    mutation DeleteEvaluationKPIMain($Strategy: [DelStrategy]!) {
                        DeleteEvaluationKPIMain(DelStrategy: $Strategy) {
                            KPIMainID
                        }
                    }
                `;
                variables = {
                    Strategy: KPIMains
                }
                break;
            }
            case 3:
            case 6: {
                DELETEMAINKPI = `
                    mutation DeleteEvaluationKPIMain($KPIMains: [DelKPIMains]!) {
                        DeleteEvaluationKPIMain(KPIMainID: $KPIMains) {
                            KPIMainID
                        }
                    }
                `;
                variables = {
                    KPIMains: KPIMains
                }
                console.log(variables)
                break;
            }
            case 4: {
                DELETEMAINKPI = `
                    mutation DeleteEvaluationKPIMain($KPIMains: [DelKPIMains]!) {
                        DeleteEvaluationKPIMain(KPIMainID: $KPIMains) {
                            KPIMainID
                        }
                    }
                `;
                variables = {
                    KPIMains: KPIMains
                }
                console.log(variables)
                break;
            }
            case 5: {
                break;
            }
            default: break;
        }


        return Axios.post('/pmqaservice', {
            query: DELETEMAINKPI,
            variables: variables,
            errorPolicy: 'all'
        });
    }

    deleteEvaluation(Year, EvaluationTypeID) {
        const DELETEEVALUATION = `
            mutation DeleteEvaluation($Year: Int!, $EvaluationTypeID: Int!) {
                DeleteEvaluation(Year: $Year, EvaluationTypeID: $EvaluationTypeID) {
                    Year
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: DELETEEVALUATION,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationTypeID
            },
            errorPolicy: 'all'
        });
    }

    getListKPIAll = (Year, EvaluationType, HasForm) => {
        HasForm = (HasForm) ? true : false;
        const GETLISTKPIALL = `
            query GetListKPIAll($Year: Int!, $EvaluationTypeID: Int! , $HasForm: Boolean) {
                GetListKPIAll(Year: $Year, EvaluationTypeID: $EvaluationTypeID, HasForm: $HasForm) {
                    ListKPIMain {
                        KPIMainID
                        KPIMainName
                    }
                    ListKPI {
                        KPIMainID
                        KPIID
                        KPIName
                        DateStart
                        DateEnd
                    }
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: GETLISTKPIALL,
            variables: {
                Year: Year,
                EvaluationTypeID: EvaluationType,
                HasForm: HasForm
            },
            errorPolicy: 'all'
        });
    }
}

export default new PolicyService();