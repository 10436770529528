import React from 'react';
import { Card, Pagination } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';


class EvaluationManageTable extends React.Component {
    componentDidMount() {
        console.log('didmount')
        ReactTooltip.rebuild();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.data !== nextProps.data) {
            return true;
        }
        return false;
    }

    renderData = (data) => {
        if (data === undefined) return;
        ReactTooltip.rebuild();
        return data.map((obj, index) => {
            if (index >= this.props.limit || obj === undefined) return false;
            return (
                <tr key={index}>
                    <td>{((this.props.currentPage - 1) * this.props.limit) + (index + 1)}</td>
                    <td>{parseInt(obj.Year) + 543}</td>
                    <td>{obj.EvaluationTypeName}</td>
                    <td align="center">
                        <button onClick={() => this.props.changeCurrentEvaluation(obj.Year, obj.EvaluationTypeID)} className='btn btn-sm btn-outline-secondary mr-1'><i className='fa fa-pencil'></i></button>
                        <button onClick={() => this.props.clickDeleteEvaluation(obj.Year, obj.EvaluationTypeID)} className='btn btn-sm btn-outline-danger'><i className='fa fa-trash'></i></button>
                    </td>
                    <td align="center">
                        <button onClick={() => this.props.changeCurrentEvaluation(obj.Year, obj.EvaluationTypeID, true)} className='btn btn-sm btn-outline-primary mr-1'><i className='fa fa-pencil'></i></button>
                    </td>
                    <td align="center">
                        <button
                            className='btn btn-outline-success btn-sm'
                            onClick={() => this.props.openPdf(obj.Year, obj.EvaluationTypeID)}
                            data-for='EvaluationTooltip'
                            data-tip='Export PDF'
                        >
                            <i className='fa fa-file-pdf-o' />
                        </button>
                    </td>
                    <td align="center">
                        <button
                            className='btn btn-outline-success btn-sm'
                            onClick={() => this.props.openExcel(obj.Year, obj.EvaluationTypeID, 'Excel')}
                            data-for='EvaluationTooltip'
                            data-tip='Export Excel'
                        >
                            <i className='fa fa-file-excel-o' />
                        </button>
                        <button
                            className='btn btn-outline-success btn-sm ml-2'
                            onClick={() => this.props.openExcel(obj.Year, obj.EvaluationTypeID, 'CSV')}
                            data-for='EvaluationTooltip'
                            data-tip='Export CSV'
                        >
                            <i className='fa fa-file-o' />
                        </button>
                    </td>
                </tr>
            );
        })
    }

    showCurrentItem = () => {
        var number = ((this.props.currentPage - 1) * this.props.limit) + 1;
        var numberlast = (number + this.props.limit) - 1;
        if (numberlast > this.props.dataCount) numberlast = this.props.dataCount;
        if (this.props.dataCount === 0) {
            number = '0';
            numberlast = '0';
        }
        return (
            <div style={{ display: 'inline-block' }}>
                {'รายการที่แสดง : ' + number + ' - ' + numberlast + ' จาก ' + this.props.dataCount + ' รายการ'}
            </div>
        );
    }

    changeCurrentpage = (index) => {
        var pagenum = Math.floor(this.props.dataCount / this.props.limit) + 1;
        if (this.props.dataCount % this.props.limit === 0) pagenum -= 1;
        if (index === 0 || index > pagenum || index === this.props.currentPage) return
        this.props.onChangeTableState('currentPage', index);
    }

    createPagination = () => {
        var item = [];
        var pagenum = Math.floor(this.props.dataCount / this.props.limit) + 1;
        if (this.props.dataCount % this.props.limit === 0) pagenum -= 1;
        if (this.props.currentPage !== 1) {
            item.push(
                <React.Fragment key='PageStart'>
                    <Pagination.First onClick={() => { this.changeCurrentpage(1) }} />
                    <Pagination.Prev onClick={() => { this.changeCurrentpage(this.props.currentPage - 1) }} />
                </React.Fragment>
            );
            if (this.props.currentPage > 3) {
                item.push(
                    <Pagination.Ellipsis key={'ellipsisStart'} />
                );
            }
        }
        for (let i = 1; i <= pagenum; i++) {
            if (i >= this.props.currentPage - 2)
                item.push(
                    <Pagination.Item key={i} active={i === this.props.currentPage} onClick={() => { this.changeCurrentpage(i) }}>
                        {i}
                    </Pagination.Item>
                );
            if (i === this.props.currentPage + 2) {
                item.push(<Pagination.Ellipsis key={'ellipsisEnd'} />);
                break;
            }
        }
        if (pagenum !== 1 && pagenum !== this.props.currentPage)
            item.push(
                <React.Fragment key={'PageEnd'}>
                    <Pagination.Next onClick={() => { this.changeCurrentpage(this.props.currentPage + 1) }} />
                    <Pagination.Last onClick={() => { this.changeCurrentpage(pagenum) }} />
                </React.Fragment>
            );
        return item;
    }

    changeLimit = (e) => {
        var newLimit = parseInt(e.target.value);
        var oldLimit = this.props.limit;
        var newCurrentPage = Math.floor(((this.props.currentPage - 1) * oldLimit) / newLimit) + 1;
        this.props.onChangeTableState('', newCurrentPage, newLimit);
    }

    renderSearched = (searched) => {
        var el = [];
        if (searched.searchedYear !== 0) {
            el.push(
                <div className='search-pill' key='EvaluationManage-searched1'>
                    ปีงบประมาณ : {searched.searchedYear + 543}
                </div>
            );
        }
        if (searched.searchedEvaluation !== 0) {
            el.push(
                <div className='search-pill' key='EvaluationManage-searched2'>
                    ประเภทการประเมิน: {this.props.checkEvaluationType(searched.searchedEvaluation)}
                </div>
            );
        }

        return el;
    }

    render() {
        console.log('render');
        return (
            <div className='custom-block mb-4'>
                {
                    (this.props.searched.isSearched !== false) ?
                        <div>
                            ผลการค้นหา : {this.renderSearched(this.props.searched)}
                        </div>
                        : null
                }
                <Card className="card-custom-table">
                    <Card.Header>
                        <button type='button' className='btn btn-outline-primary d-inline-block' onClick={this.props.clickAddEvaluation}>เพิ่มการประเมิน</button>
                        <div className="float-right">
                            <div className="d-inline-block mr-1">แสดง </div>
                            <select className="form-control d-inline-block" onChange={this.changeLimit} style={{ width: '75px' }}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                            <div className="d-inline-block ml-1">รายการ</div>
                        </div>
                    </Card.Header>
                    <Card.Body className='table-responsive-xl mb-2'>
                        <table className="table mb-0 table-custom table-striped table-bordered table-hover">
                            <thead>
                                <tr align='center'>
                                    <th style={{ minWidth: '60px' }}>ลำดับ</th>
                                    <th style={{ minWidth: '75px' }}>ปีงบประมาณ</th>
                                    <th style={{ minWidth: '275px' }}>ชื่อการประเมิน</th>
                                    <th style={{ minWidth: '125px' }}>แก้ไข/ลบ</th>
                                    <th style={{ minWidth: '90px' }}>ช่วงเวลาการประเมินพิเศษ</th>
                                    <th>PDF</th>
                                    <th>Excel, CSV</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderData(this.props.data)}
                            </tbody>
                        </table>
                    </Card.Body>
                    <Card.Footer>
                        {this.showCurrentItem()}
                        <Pagination className="mb-0 float-right">
                            {this.createPagination()}
                        </Pagination>
                    </Card.Footer>
                </Card>

                <ReactTooltip
                    id='EvaluationTooltip'
                    delayShow={400}
                />
            </div>
        );
    }
}

export default EvaluationManageTable;