import axios from 'axios';
import store from '../Redux/Store';
import { setAccountLogout } from '../Redux/Action';
import { removeAllUsersCookies } from '../Service/CookieService'
import Swal from 'sweetalert2';

const baseURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : window.location.origin;
var AlertTimeout = null;

const Axios = axios.create({
    baseURL: baseURL
})

Axios.interceptors.request.use((request) => {
    console.log(store.getState().token)
    request.headers.Authorization = store.getState().token;
    return request;
})

Axios.interceptors.response.use(
    (response) => {
        console.log('axios Response Interceptor');
        if (response.headers['content-disposition'])
            return {
                name: response.headers['content-disposition'],
                data: response.data
            };
        return response.data;
    },
    (err) => {
        console.log(err);
        if (err.response) {
            if (err.response.status === 404) {
                return Promise.reject(err);
            }
            else if (err.response.data.errors[0].Code === -1001) {
                store.dispatch(setAccountLogout());
                removeAllUsersCookies();
                clearTimeout(AlertTimeout);
                AlertTimeout = setTimeout(function () {
                    Swal.fire({
                        type: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: 'กรุณาล็อคอินใหม่'
                    });
                }, 750);
                // Swal.fire({
                //     type: 'error',
                //     title: 'เกิดข้อผิดพลาด',
                //     text: 'กรุณาล็อคอินใหม่'
                // });
            }
        }
        else if (err.message === 'Network Error') {
            clearTimeout(AlertTimeout);
            AlertTimeout = setTimeout(function () {
                Swal.fire({
                    type: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
                })
            }, 750);
        }
        else {
            // Swal.fire({
            //     type: 'error',
            //     title: 'เกิดข้อผิดพลาด',
            //     text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล'
            // })
        }
        return Promise.reject(err);
    }
)

Axios.defaults.timeout = 20000;

export default Axios;