import React from 'react';
import { connect } from 'react-redux';
import { showLoading, hideLoading, setProfileImg, setAccountLogin } from '../../Redux/Action';
import { setUsersCookies, getUsersCookies } from '../../Service/CookieService';
import AccountService from '../../Service/AccountService';
import PasswordModal from './UserInfo_component/UserInfo_modal_password';
import Swal from 'sweetalert2';
import Jimp from 'jimp';

class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            class: '',
            show: false,
            AccountID: parseInt(this.props.AccountID),
            Username: '',
            FirstName: '',
            LastName: '',
            Email: '',
            OrganizationID: 0,
            OrganizationName: '',
            OrganizationShortName: '',
            edit: false,
            file: {},
            editedValue: {},
            loading: false,
        }
    }

    componentDidMount() {
        this.getAccountDetail();
    }

    getAccountDetail = () => {
        this.props.dispatch(showLoading());
        AccountService.getAccountDetail(this.state.AccountID)
            .then(result => {
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    this.goBack();
                }
                else {
                    result = result.data.GetAccountDetail;
                    this.setState({
                        Username: result.Username,
                        FirstName: result.FirstName,
                        LastName: result.LastName,
                        Email: result.Email,
                        OrganizationID: result.OrganizationID,
                        OrganizationName: result.OrganizationName,
                        OrganizationShortName: result.OrganizationShortName
                    })
                }
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                })
                    .then(() => {
                        this.goBack()
                    });
            });
    }

    goBack = () => {
        this.setState({
            class: 'user-loader-in'
        }, () => {
            setTimeout(
                () => {
                    this.setState({
                        class: ''
                    });
                    this.props.history.goBack()
                }, 300)
        })
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    updatePassword = (NewpassWord, Oldpassword) => {
        this.props.dispatch(showLoading());
        return AccountService.updateAccount(
            this.state.AccountID,
            null,
            null,
            null,
            NewpassWord,
            Oldpassword,
            null,
            null,
            null
        )
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    changeEdit = (name) => {
        this.setState({
            editedValue: {
                Username: this.state.Username,
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                Email: this.state.Email
            },
            edit: !this.state.edit
        })
    }

    updateUser = (Email, Username, FirstName, LastName) => {
        this.props.dispatch(showLoading());
        return AccountService.updateAccount(
            this.state.AccountID,
            null,
            null,
            Email,
            null,
            null,
            Username,
            FirstName,
            LastName
        )
            .then(result => {
                this.props.dispatch(hideLoading());
                return result;
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                return Promise.reject(err);
            });
    }

    onChange = (name, value) => {
        var newEditedValue = { ...this.state.editedValue };
        newEditedValue[name] = value;
        this.setState({ editedValue: newEditedValue });
    }

    toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({
                FileName: file.name,
                Description: '',
                FileData: reader.result
            });
            reader.onerror = error => reject(error);
        })
    }

    handleFileChoose = (e) => {
        var file = e.target.files;
        if (!file[0]) return;
        if (!file[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
            console.log('kwai');
            Swal.fire({
                title: 'กรุณาเลือกไฟล์ให้ถูกต้อง',
                text: 'รูปโปรไฟล์ต้องเป็นไฟล์ที่มีนามสกุลไฟล์ png, jpg, jpeg และ gif เท่านั้น',
                type: 'warning'
            })
            return;
        }
        //console.log(file[0].size / 1024 + 'KB');
        var newFile = this.state.file;
        this.toBase64(file[0])
            .then((File) => {
                if (file[0].size / 1024 > 100) {
                    // Swal.fire({
                    //     title: 'ขนาดไฟล์เกินกำหนด',
                    //     text: 'กรุณาเลือกไฟล์ที่มีขนาดต่ำกว่า 200 KB',
                    //     type: 'warning'
                    // });
                    // return;

                    Jimp.read(File.FileData)
                        .then((img) => {
                            console.log(img)
                            var newHeight = (img.bitmap.width > img.bitmap.height) ? img.bitmap.height : img.bitmap.width;
                            var newWidth = (img.bitmap.width > img.bitmap.height) ? img.bitmap.height : img.bitmap.width;
                            return img
                                .crop(0, 0, newWidth, newHeight)
                                .resize(150, 150)
                        })
                        .then(processedImg => {
                            console.log(processedImg)
                            processedImg.getBase64('image/jpeg', (err, base64) => {
                                console.log(base64);
                                File.FileData = base64;
                                newFile = File;
                                //this.profileImg.src = newFile.FileData;
                                //this.profileImgDiv.style.backgroundImage = 'url(' + newFile.FileData + ')'
                                console.log(newFile)
                                this.setState({ file: newFile });
                            })
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    return;
                }
                //console.log(File)
                newFile = File;
                //this.profileImg.src = newFile.FileData;
                //this.profileImgDiv.style.backgroundImage = 'url(' + newFile.FileData + ')'
                this.setState({ file: newFile });
            })
    }

    cancelChange = () => {
        //this.profileImg.src = this.props.profileImg;
        //this.profileImgDiv.style.backgroundImage = 'url(' + this.props.profileImg + ')'
        this.setState({
            edit: false,
            editedValue: {},
            file: {}
        })
    }

    checkUpdate = () => {
        var title = '';
        var text = '';
        var type = null;
        if (this.state.editedValue.Email === '' || this.state.editedValue.Email.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกอีเมล';
            type = 'warning';
        }
        else if (this.state.editedValue.Username === '' || this.state.editedValue.Username.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อผู้ใช้งาน';
            type = 'warning';
        }
        else if (this.state.editedValue.FirstName === '' || this.state.editedValue.FirstName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกชื่อ';
            type = 'warning';
        }
        else if (this.state.editedValue.LastName === '' || this.state.editedValue.LastName.trim() === '') {
            title = 'กรุณาตรวจสอบข้อมูล';
            text = 'กรุณากรอกนามสกุล';
            type = 'warning';
        }

        if (type === null)
            return true;
        else {
            Swal.fire({
                title: title,
                text: text,
                type: type
            });
            return false;
        }
    }

    onSave = () => {
        if (this.checkUpdate())
            this.updateUser(
                this.state.editedValue.Email,
                this.state.editedValue.Username,
                this.state.editedValue.FirstName,
                this.state.editedValue.LastName,
            )
                .then(result => {
                    if (result.errors) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: result.errors[0].Message,
                            type: 'error'
                        });
                        return;
                    }
                    this.getAccountDetail();
                    console.log(this.state.editedValue.Email, this.state.editedValue.Username)
                    setUsersCookies(this.state.editedValue.Email, this.state.editedValue.Username, getUsersCookies('accountID'), getUsersCookies('token'));
                    this.props.dispatch(setAccountLogin({
                        Email: getUsersCookies('email'),
                        Username: getUsersCookies('username'),
                        AccountID: getUsersCookies('accountID'),
                        Token: getUsersCookies('token')
                    }));
                    if (this.state.file.FileData) {
                        AccountService.updateProfileAccount(this.state.AccountID, this.state.file.FileData)
                            .then(resultProfile => {
                                if (resultProfile.errors && resultProfile !== null) {
                                    Swal.fire({
                                        title: 'เกิดข้อผิดพลาด',
                                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                        type: 'error'
                                    });
                                    return;
                                }
                                Swal.fire({
                                    title: 'บันทึกข้อมูลสำเร็จ',
                                    type: 'success'
                                }).then(() => {
                                    this.setState({ edit: false }, () => {
                                        localStorage.setItem('profileImg', this.state.file.FileData)
                                        this.props.dispatch(setProfileImg({
                                            data: localStorage.getItem('profileImg')
                                        }));
                                    });
                                })
                            })
                            .catch(err => {
                                console.log(err);
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                    type: 'error'
                                });
                            })
                        return;
                    }
                    else {
                        Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            type: 'success'
                        }).then(() => {
                            this.setState({ edit: false }, () => {
                                this.getAccountDetail()
                            });
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                })
    }

    render() {
        return (
            <>
                <div>
                    <button
                        className='btn btn-outline-secondary'
                        onClick={this.goBack}
                    >
                        <i className='fa fa-arrow-left'></i>
                    </button>
                </div>
                <div className='custom-block mt-4 mb-4'>
                    <div className='d-flex align-items-center'>
                        <div className='d-inline-block'>
                            <h4>โปรไฟล์</h4>
                            <small>จัดการข้อมูลผู้ใช้งาน</small>
                        </div>
                        {
                            (this.state.edit === false) ?
                                <button className='btn btn-outline-secondary ml-3' onClick={this.changeEdit}>
                                    <i className='fa fa-pencil' />
                                </button>
                                : null
                        }
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4'>
                                <label className='form-label'>ชื่อผู้ใช้งาน</label>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    {
                                        (this.state.edit === true) ?
                                            <input
                                                className='form-control'
                                                name='Username'
                                                value={this.state.editedValue.Username}
                                                onChange={e => this.onChange(e.target.name, e.target.value.trim())}
                                            />
                                            :
                                            this.state.Username
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4 d-flex align-items-center'>
                                <label className='form-label'>รูปผู้ใช้งาน</label>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='d-flex align-items-end'>
                                    {/* <div className='position-absolute' style={{ width: '150px', height: '150px' }}>
                                        <div className='suspense-div'>
                                            <div id="bars1">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div> */}
                                    <img
                                        src={
                                            (this.state.edit === true) ?
                                                ((this.state.file.FileData) ?
                                                    this.state.file.FileData : ((this.props.profileImg) ? this.props.profileImg : '/assets/fa-user.png'))
                                                : ((this.props.profileImg === '') ? '/assets/fa-user.png' : this.props.profileImg)
                                        }
                                        className='border rounded-circle profile-img'
                                        ref={el => this.profileImg = el}
                                        alt=''
                                    />
                                    {/* <div
                                        className='border rounded-circle profile-img profile-img-div'
                                        ref={el => this.profileImgDiv = el}
                                        style={{ backgroundImage: 'url(' + ((this.props.profileImg === '') ? '/assets/fa-user.png' : this.props.profileImg) + ')' }}
                                    /> */}
                                    {
                                        (this.state.edit === true) ?
                                            <>
                                                <label htmlFor="kpireport-modal-files" className="btn btn-sm btn-outline-secondary rounded-circle">
                                                    <i className='fa fa-pencil'></i>
                                                </label>
                                                <input
                                                    type='file'
                                                    accept="image/*"
                                                    id='kpireport-modal-files'
                                                    onChange={this.handleFileChoose}
                                                    style={{ display: 'none' }}
                                                />
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    {/* <div className='row'>
                        <div className='col-2'>
                            <div id="bars1">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div> */}
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4'>
                                <label className='form-label'>ชื่อ</label>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    {
                                        (this.state.edit === true) ?
                                            <input
                                                className='form-control'
                                                name='FirstName'
                                                value={this.state.editedValue.FirstName}
                                                onChange={e => this.onChange(e.target.name, e.target.value)}
                                            />
                                            :
                                            this.state.FirstName
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4'>
                                <label className='form-label'>นามสกุล</label>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    {
                                        (this.state.edit === true) ?
                                            <input
                                                className='form-control'
                                                name='LastName'
                                                value={this.state.editedValue.LastName}
                                                onChange={e => this.onChange(e.target.name, e.target.value)}
                                            />
                                            :
                                            this.state.LastName
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4'>
                                <label className='form-label'>Email</label>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    {
                                        (this.state.edit === true) ?
                                            <input
                                                className='form-control'
                                                name='Email'
                                                value={this.state.editedValue.Email}
                                                onChange={e => this.onChange(e.target.name, e.target.value.trim())}
                                            />
                                            :
                                            this.state.Email
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4'>
                                <label className='form-label'>หน่วยงาน</label>
                            </div>
                            <div className='col-6'>
                                {this.state.OrganizationName + ((this.state.OrganizationShortName) ? (' (' + this.state.OrganizationShortName + ')') : '')}
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1' />
                            <div className='col-4'>
                                <label className='form-label'>รหัสผ่าน</label>
                            </div>
                            <div className='col-6'>
                                <button
                                    className='btn btn-outline-primary'
                                    onClick={this.hideModal}
                                >
                                    เปลี่ยนรหัสผ่าน
                                    </button>
                            </div>
                        </div>
                        <hr />
                    </div>
                    {
                        (this.state.edit === true) ?
                            <div className='mt-3'>
                                <div className='d-flex justify-content-end'>
                                    <button className='btn btn-outline-danger' onClick={this.cancelChange}>ยกเลิก</button>
                                    <button className='btn btn-outline-primary ml-2' onClick={this.onSave}>บันทึก</button>
                                </div>
                            </div>
                            : null
                    }

                </div>
                <div className={"user-loader " + this.state.class}>
                </div>
                <PasswordModal
                    show={this.state.show}
                    hide={this.hideModal}
                    updatePassword={this.updatePassword}
                />
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
        AccountID: state.accountID,
        profileImg: state.profileImg
    }
}

export default connect(mapStatetoProps)(UserInfo);