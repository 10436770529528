import React from 'react';
import GoogleMapReact from 'google-map-react';
import Collapse from 'react-bootstrap/Collapse';

const Marker = ({ index }) => <div><div className={'pin pin' + (index + 1)}></div><div className='pulse'></div></div>;

class GMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            google: {
                map: {},
                maps: {}
            },
            geoList: [],
            marker: {
                lat: null,
                lng: null,
            },
            openTools: false,
            InfoWindow: {},
            InfoWindowIndex: null
        }
    }

    fitZoomThailand = () => {
        console.log("fitzoom");
        var center = {
            lat: 13.736717,
            lng: 100.523186
        };

        this.state.google.map.panTo(center);
        this.state.google.map.setCenter(center);
        this.state.google.map.setZoom(6);
    }

    panTo = (lat, lng) => {
        if (!lat || !lng) return;
        var center = {
            lat: lat,
            lng: lng
        };
        this.state.google.map.panTo(center);
    }

    GeoLayer = () => {
        return {
            mode: -1,
            url: "",
            layerName: "",
            imageMap: null
        }
    }

    overerlayGeoShape = (mode) => {
        var isEnabled = this.state.geoList.find(x => x.mode === mode);
        isEnabled = (isEnabled) ? false : true;

        var geoObj = this.GeoLayer();
        geoObj.url = "http://twp_geo.onwr.go.th/geoserver/onwr/wms?";
        //corrections for the slight shift of the SLP (mapserver)
        //  var deltaX = 0.0013;
        //  var deltaY = 0.00058;
        var deltaX = 0.0;
        var deltaY = 0.0;

        switch (mode) {
            case 0:
                geoObj.mode = 0;
                geoObj.layerName = "onwr:province";
                break;
            case 1:
                geoObj.mode = 1;
                geoObj.layerName = "onwr:district";
                break;
            case 2:
                geoObj.mode = 2;
                geoObj.layerName = "onwr:subdistrict";
                break;
            case 3:
                geoObj.mode = 3;
                geoObj.layerName = "onwr:area-based";
                break;
            case 4:
                geoObj.mode = 4;
                geoObj.layerName = "onwr:region_thailand";
                break;
            default:
                alert("Unknown GeoServer Layer!!: " + mode);
                break;
        }

        if (isEnabled) {
            //WMS map
            var areaGEO = new this.state.google.maps.ImageMapType({
                getTileUrl: (coord, zoom) => {
                    var proj = this.state.google.map.getProjection();
                    var zfactor = Math.pow(2, zoom);
                    // get Long Lat coordinates
                    var top = proj.fromPointToLatLng(new this.state.google.maps.Point(coord.x *
                        256 / zfactor, coord.y * 256 / zfactor));
                    var bot = proj.fromPointToLatLng(new this.state.google.maps.Point((coord.x + 1) *
                        256 / zfactor, (coord.y + 1) * 256 / zfactor));

                    //create the Bounding box string
                    var bbox = (top.lng() + deltaX) + "," +
                        (bot.lat() + deltaY) + "," +
                        (bot.lng() + deltaX) + "," +
                        (top.lat() + deltaY);

                    var url = geoObj.url;
                    url += "request=GetMap";
                    url += "&service=WMS";
                    url += "&version=1.1.1";
                    url += "&layers=" + geoObj.layerName;
                    url += "&styles=";
                    url += "&SRS=EPSG:4326"; //set WGS84
                    url += "&BBOX=" + bbox;
                    url += "&TRANSPARENT=TRUE";
                    url += "&width=256";
                    url += "&height=256";
                    url += "&FORMAT=image/png"

                    return url; // return URL for the tile

                },
                tileSize: new this.state.google.maps.Size(256, 256),
                isPng: true
            });

            //add WMS layer
            geoObj.imageMap = areaGEO;
            var newGeoList = [...this.state.geoList];
            newGeoList.push(geoObj);
            this.setState({
                geoList: newGeoList
            })
            this.state.google.map.overlayMapTypes.push(areaGEO);

        } else {
            var geoIndex = this.state.geoList.findIndex(x => x.mode === mode);
            console.log(geoIndex)
            if (geoIndex !== -1) {
                var olIndex = this.state.google.map.overlayMapTypes.indexOf(this.state.geoList[geoIndex].imageMap);
                if (olIndex !== -1) {
                    this.state.google.map.overlayMapTypes.removeAt(olIndex);
                    this.state.geoList.splice(geoIndex, 1);
                }
            }
        }
    }

    handleApiLoaded = (map, maps) => {
        console.log(map, maps)
        this.setState({
            google: {
                map: map,
                maps: maps
            }
        }, () => {
            this.fitZoomThailand();
            var newInfoWindow = new this.state.google.maps.InfoWindow({
                content: ''
            });
            this.setState({
                InfoWindow: newInfoWindow
            }, () => {
                console.log(this.state.InfoWindow)
            })
        });
    }

    onClickButtonOverlay = (index) => {
        console.log('click')
        this.overerlayGeoShape(index);
    }

    checkToggle = (index) => {
        return (this.state.geoList.findIndex(x => x.mode === index) !== -1) ? 'true' : 'false';
    }

    onClickMap = (e) => {
        console.log(!parseFloat(this.props.mapData.lat) || !parseFloat(this.props.mapData.lng))
        this.props.onChangeLatLng(parseFloat(e.lat.toFixed(5)), parseFloat(e.lng.toFixed(5)));
        this.state.InfoWindow.close();
    }

    showInfoWindow = (e, pos) => {
        e = e.split('-');
        e = e[e.length - 1];
        console.log(e);
        this.state.InfoWindow.setPosition(new this.state.google.maps.LatLng(pos.lat, pos.lng));
        if (e !== null && e !== undefined) {
            this.state.InfoWindow.setContent(this.props.mapData[e].ProjectName);
        }
        else {
            this.state.InfoWindow.setContent('');
        }
        this.panTo(pos.lat, pos.lng);
        this.state.InfoWindow.open(this.state.google.map);
    }

    render() {
        return (
            <>
                <div className='map-wrapper'>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyBe4jDd6zA-v_hnLwYnZiOQT6iCKQN-uJM', language: 'th', region: 'th' }}
                        defaultCenter={{
                            lat: 13.736717,
                            lng: 100.523186
                        }}
                        defaultZoom={6}
                        options={{ fullscreenControl: false }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                        onClick={e => this.onClickMap(e)}
                        onChildClick={this.showInfoWindow}
                    >
                        {
                            this.props.mapData.map((obj, index) => {
                                return (
                                    (parseFloat(obj.Lat) || parseFloat(obj.Lng)) ?
                                        <Marker
                                            key={'marker-' + index}
                                            style={{ backgroundColor: 'red' }}
                                            lat={obj.Lat}
                                            lng={obj.Lng}
                                            index={index}
                                        />
                                        : null
                                )
                            })
                        }
                    </GoogleMapReact>
                </div>
                <div className='position-absolute map-tools-wrapper' style={{ top: '0' }}>
                    <div className='d-flex border-bottom'>
                        <button className='btn w-100' onClick={() => this.setState({ openTools: !this.state.openTools })}>
                            <i className='fa fa-bars' />
                        </button>
                    </div>
                    <Collapse in={this.state.openTools} className='pl-2 pr-2'>
                        <div>
                            <div>
                                <label className="switch mr-2 mt-3" >
                                    <input
                                        type="checkbox"
                                        check={this.checkToggle(0)}
                                    />
                                    <span className="slider" onClick={() => this.onClickButtonOverlay(0)}></span>

                                </label>
                                <div className='d-inline-block'>จังหวัด</div>
                            </div>
                            <div>
                                <label className="switch mr-2 mt-3" >
                                    <input
                                        type="checkbox"
                                    />
                                    <span className="slider" onClick={() => this.onClickButtonOverlay(4)}></span>

                                </label>
                                <div className='d-inline-block'>ภาค</div>
                            </div>
                            <div>
                                <label className="switch mr-2 mt-3" >
                                    <input
                                        type="checkbox"
                                        check={this.checkToggle(1)}
                                    />
                                    <span className="slider" onClick={() => this.onClickButtonOverlay(1)}></span>

                                </label>
                                <div className='d-inline-block'>อำเภอ</div>
                            </div>
                            <div>
                                <label className="switch mr-2 mt-3" >
                                    <input
                                        type="checkbox"
                                        check={this.checkToggle(2)}
                                    />
                                    <span className="slider" onClick={() => this.onClickButtonOverlay(2)}></span>

                                </label>
                                <div className='d-inline-block'>ตำบล</div>
                            </div>
                            <div>
                                <label className="switch mr-2 mt-3" >
                                    <input
                                        type="checkbox"
                                        check={this.checkToggle(3)}
                                    />
                                    <span className="slider" onClick={() => this.onClickButtonOverlay(3)}></span>

                                </label>
                                <div className='d-inline-block'>area-based</div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </>
        );
    }
}

export default GMap;