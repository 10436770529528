import React from 'react';
import Autocomplete from '../../Utilities/Autocomplete';
import OrganizationService from '../../Service/OrganizationService';
import AccountService from '../../Service/AccountService';
import { showLoading, hideLoading } from '../../Redux/Action';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import UserManageTable from './UserManage_component/UserManage_table';
import UserManageModal from './UserManage_component/UserManage_modal';
import RoleService from '../../Service/RoleService';
class UserManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: [],
            dataCount: 0,
            searchUsername: '',
            searchRoleID: 0,
            searchOrganization: {},
            searched: {
                isSearched: false,
                searchedUsername: '',
                searchedRoleID: 0,
                searchedOrganization: {}
            },
            Role: [],
            currentPage: 1,
            limit: 5,
            currentAccountID: null
        }
    }

    componentDidMount() {
        this.searchAccount(null, null, null, null, null, null, null);
        this.searchRole();
    }

    searchOrganization = (Name) => {
        return OrganizationService.searchOrganization(Name);
    }

    searchAccount = (AccountID, RoleID, OrganizationID, Username, FirstName, LastName, Email) => {
        this.props.dispatch(showLoading());
        var accountService = AccountService.searchAccount(AccountID, RoleID, OrganizationID, Username, FirstName, LastName, Email);
        accountService
            .then(result => {
                this.props.dispatch(hideLoading());
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    return;
                }
                console.log(result);
                this.setState({
                    data: result.data.SearchAccount,
                    dataCount: result.data.SearchAccount.length
                })
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    searchAccountAfterSetState = () => {
        if (this.state.searched.isSearched === true) {
            this.searchAccount(
                null,
                this.state.searched.searchedRoleID,
                this.state.searched.searchedOrganization.OrganizationID,
                this.state.searched.searchedUsername,
                null,
                null,
                null
            )
        }
        else {
            this.searchAccount(
                null,
                null,
                null
            )
        }
    }

    searchRole = () => {
        RoleService.searchRole()
            .then(result => {
                if (result.errors) {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                        type: 'error'
                    });
                    return;
                }
                console.log(result);
                this.setState({
                    Role: result.data.SearchRole
                });
            })
            .catch(err => {
                this.props.dispatch(hideLoading());
                console.log(err);
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                    type: 'error'
                });
            })
    }

    getAccountDetail = (AccountID) => {
        return AccountService.getAccountDetail(AccountID);
    }

    createAccount = (RoleID, OrganizationID, Email, Password, Username, FirstName, LastName) => {
        return AccountService.createAccount(RoleID, OrganizationID, Email, Password, Username, FirstName, LastName)
            .then(result => {
                if (!result.errors)
                    this.searchAccountAfterSetState();
                return result;
            });
    }

    updateAccount = (AccountID, RoleID, OrganizationID, Email, Username, FirstName, LastName, NewPassword) => {
        return AccountService.updateAccount(AccountID, RoleID, OrganizationID, Email, (NewPassword || null), null, Username, FirstName, LastName)
            .then(result => {
                if (!result.errors)
                    this.searchAccountAfterSetState();
                return result;
            });
    }

    deleteAccount = (AccountID) => {
        Swal.fire({
            title: 'ลบผู้ใช้งาน',
            text: 'ต้องการลบผู้ใช้งานนี้หรือไม่ ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            reverseButtons: true
        })
            .then(res => {
                if (res.value)
                    AccountService.deleteAccount(AccountID)
                        .then(result => {
                            if (result.errors) {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: result.errors[0].Message,
                                    type: 'error'
                                })
                                return;
                            }
                            Swal.fire({
                                title: 'ลบข้อมูลสำเร็จ',
                                type: 'success'
                            })
                            this.searchAccountAfterSetState();
                        })
                        .catch(err => {
                            console.log(err);
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'เกิดข้อผิดพลาดระหว่างการเรียกข้อมูล',
                                type: 'error'
                            });
                        });
            })

    }

    onChangeTableState = (name, value, value2) => {
        if (name === '')
            this.setState({
                currentPage: value,
                limit: value2
            }, () => {
                this.getAccountDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
        else
            this.setState({ [name]: value }, () => {
                this.getAccountDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit);
            });
    }

    onChangeSearch = (name, value) => {
        this.setState({ [name]: value });
    }

    changeCurrentAccountID = (index) => {
        this.setState({ currentAccountID: index }, () => {
            this.hideModal();
        })
    }

    checkRoleName = (RoleID) => {
        for (var i of this.state.Role) {
            if (i.RoleID === RoleID)
                return i.RoleName;
        }
    }

    getAccountDataTable = (Offset, Limit) => {
        var newData = [];
        var data = [...this.state.data];
        if (data.length === 0) return [];
        for (var i = Offset; i < (Offset + Limit); i++) {
            if (data[i] === undefined)
                break;
            newData.push(data[i]);
        }
        return newData;
    }

    clickSearch = () => {
        if (
            this.state.searchUsername === '' &&
            this.state.searchRoleID === 0 &&
            this.state.searchOrganization.OrganizationID === undefined
        ) {
            this.clearSearch();
            return;
        }
        this.setState({
            //searchUsername: '',
            //searchRoleID: 0,
            //searchOrganization: {},
            currentPage: 1,
            searched: {
                isSearched: true,
                searchedUsername: this.state.searchUsername,
                searchedRoleID: this.state.searchRoleID,
                searchedOrganization: this.state.searchOrganization,
            }
        }, () => {
            this.searchAccountAfterSetState();
        });
    }

    clearSearch = () => {
        this.setState({
            searchPersonName: '',
            searchRoleID: 0,
            searchOrganization: {},
            currentPage: 1,
            searched: {
                isSearched: false,
                searchedUsername: '',
                searchedRoleID: 0,
                searchedOrganization: {}
            }
        }, () => {
            this.searchAccount(null, null, null, null, null, null, null)
        })
    }

    hideModal = (callback) => {
        (this.state.show === false) ?
            this.setState({
                show: true
            })
            : this.setState({
                show: false,
                currentAccountID: null
            }, () => {
                if (typeof callback === 'function')
                    callback()
            });
    }

    render() {
        return (
            <>
                <div className='custom-block mb-4'>
                    <div className='row align-items-end'>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>ชื่อผู้ใช้งาน</label>
                            <input
                                className='form-control'
                                name='searchUsername'
                                placeholder='ชื่อผู้ใช้งาน'
                                value={this.state.searchUsername}
                                onChange={e => this.onChangeSearch(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>สิทธิ์การใช้งาน</label>
                            <select
                                className='form-control'
                                name='searchRoleID'
                                value={this.state.searchRoleID}
                                onChange={e => this.onChangeSearch(e.target.name, parseInt(e.target.value))}
                            >
                                <option value={0}>กรุณาเลือก</option>
                                {
                                    this.state.Role.map((obj, index) => {
                                        return (
                                            <option key={'Role-option' + index} value={obj.RoleID} >
                                                {obj.RoleName}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group col-12 col-lg-4'>
                            <label className='form-label'>หน่วยงาน</label>
                            <Autocomplete
                                getData={this.searchOrganization}
                                name='searchOrganization'
                                labelName='หน่วยงาน'
                                dataName='Organization'
                                onChangeSelect={this.onChangeSearch}
                                selectValue={this.state.searchOrganization}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='mr-2 btn btn-outline-danger' onClick={this.clearSearch}>ยกเลิก</button>
                        <button type='button' className='btn btn-outline-primary' onClick={this.clickSearch}>ค้นหา</button>
                    </div>
                </div>

                <div className='custom-block mb-4'>
                    <UserManageTable
                        data={this.getAccountDataTable((this.state.currentPage - 1) * this.state.limit, this.state.limit)}
                        dataCount={this.state.dataCount}
                        onChangeTableState={this.onChangeTableState}
                        searched={this.state.searched}
                        currentPage={this.state.currentPage}
                        limit={this.state.limit}
                        clickAddAccount={this.hideModal}
                        clickDeleteAccount={this.deleteAccount}
                        changeCurrentAccountID={this.changeCurrentAccountID}
                        checkRoleName={this.checkRoleName}
                    />
                </div>

                <UserManageModal
                    show={this.state.show}
                    hide={this.hideModal}
                    currentAccountID={this.state.currentAccountID}
                    getData={this.searchOrganization}
                    getAccountDetail={this.getAccountDetail}
                    Role={this.state.Role}
                    createAccount={this.createAccount}
                    updateAccount={this.updateAccount}
                />
            </>
        );
    }
}

export default connect(null)(UserManage);