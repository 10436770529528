import Axios from './BaseService';

class EvaluationResultService {

    searchEvaluationResult(EvaluationTypeID, OrganizationID, Year, Status, PersonName, StartDate, EndDate, Offset, Limit) {
        console.log(EvaluationTypeID, OrganizationID, Year, Status, PersonName, Offset, Limit)
        EvaluationTypeID = (EvaluationTypeID === 0 || EvaluationTypeID === undefined) ? null : EvaluationTypeID;
        OrganizationID = (OrganizationID === 0 || OrganizationID === undefined) ? null : OrganizationID;
        Year = (Year === 0 || Year === undefined) ? null : Year;
        Status = (Status === 0 || Status === undefined) ? null : Status;
        PersonName = (!PersonName) ? null : PersonName;
        if (StartDate) {
            EndDate = (EndDate) ? EndDate : StartDate;
        }
        if (EndDate) {
            StartDate = (StartDate) ? StartDate : EndDate;
        }
        console.log({
            EvaluationTypeID: parseInt(EvaluationTypeID),
            OrganizationID: parseInt(OrganizationID),
            Year: parseInt(Year),
            Status: parseInt(Status),
            PersonName: PersonName,
            StartDate: StartDate,
            EndDate: EndDate,
            Offset: parseInt(Offset),
            Limit: parseInt(Limit)
        })
        const SEARCHEVALUATIONRESULT = `
            query SearchEvaluationResut(
                $EvaluationTypeID: Int
                $OrganizationID: Int
                $Year: Int
                $Status: Int
                $PersonName: String
                $StartDate: String
                $EndDate: String
                $Offset: Int
                $Limit: Int
            ){
                SearchEvaluationResult(
                    EvaluationTypeID: $EvaluationTypeID
                    OrganizationID: $OrganizationID
                    Year: $Year
                    Status: $Status
                    PersonName: $PersonName
                    StartDate: $StartDate
                    EndDate: $EndDate
                    Offset: $Offset
                    Limit: $Limit
                ){
                ListEvaluationResult{
                    KPIID
                    KPIMainID
                    ScheduleID
                    FormID
                    Year
                    EvaluationTypeID
                    OrganizationIDs
                    KPIName
                    KPIMainName
                    EvaluationTypeName
                    OrganizationNames
                    OrganizationShortNames
                    Status
                }
                Count
                }
            }
        `

        return Axios.post('/pmqaservice', {
            query: SEARCHEVALUATIONRESULT,
            variables: {
                EvaluationTypeID: parseInt(EvaluationTypeID),
                OrganizationID: parseInt(OrganizationID),
                Year: parseInt(Year),
                Status: parseInt(Status),
                PersonName: PersonName,
                StartDate: StartDate,
                EndDate: EndDate,
                Offset: parseInt(Offset),
                Limit: parseInt(Limit)
            },
            errorPolicy: 'all'
        });
    }

    getEvaluationResultDetail(KPIID, Month, Mode) {
        const GETEVALUATIONRESULTDETAIL = `
            query GetEvaluationResultDetail($KPIID : Int!, $Month: String, $Mode: Int){
                GetEvaluationResultDetail(KPIID: $KPIID, Month: $Month, Mode: $Mode){
                KPIID
                KPIName
                Description
                Year
                KPIMainID
                Month
                CanReport
                Status
                RemarkReject
                Modified {
                    Username
                    FirstName
                    LastName
                    ModifiedDateTime
                }
                Strategy {
                    StrategyID
                    StrategyName
                }
                ProjectKPI {
                    ProjectID
                    ProjectName
                    Organization{
                        OrganizationID
                        OrganizationName
                    }
                }
                EvaluationCriteria{
                    Type
                    Weight
                    PointFull
                    PointPass
                    Unit
                    Low{
                        Target
                        Result
                        Description
                    }
                    Mid{
                        Target
                        Result
                        Description
                    }
                    High{
                        Target
                        Result
                        Description
                    }
                }
                HasFile
                KPIPersons {
                    PersonID
                    PersonName
                    PersonStatus
                    Phone
                    Remark
                    OrganizationID
                    OrganizationName
                    OrganizationCode
                    OrganizationShortName
                    }
                KPIOrganizations {
                    OrganizationID
                    OrganizationStatus
                    OrganizationName
                    OrganizationShortName
                }
                EvaluationType {
                    EvaluationTypeID
                    EvaluationTypeName
                    Remark
                    }
                Schedule {
                    ScheduleID
                    ScheduleName
                    Mode
                    DayOfWeekStart
                    DayOfWeekEnd
                    WeekOfMonthStart
                    WeekOfMonthEnd
                    Period {
                        Month
                        Week
                        Date
                    }
                }
                Policy{
                    PolicyID
                    PolicyName
                    Remark
                }
                KPIMain{
                    KPIMainID
                    KPIMainName
                    Remark
                }
                KPIFiles {
                    FileID
                    FileName
                    Description
                }
                Form {
                    FormID
                    KPIID
                    FormName
                    Remark
                    Sections{
                        SectionID
                        SectionName
                        FormObjects{
                            FormObjectID
                            Order
                            FormTypeID
                            HasMultipleItem
                            HasResult
                            Computable
                            Header
                            Footer
                            Content
                            FormObjectItems{
                                FormObjectItemID
                                FormObjectItemName
                                FormObjectItemResultID
                                Order
                                TargetText
                                TargetValue
                                SubmitDate
                                ResultText
                                ResultValue
                                Remark
                            }
                        }
                    }
                }
            }
        }
        `;

        return Axios.post('/pmqaservice', {
            query: GETEVALUATIONRESULTDETAIL,
            variables: {
                KPIID: parseInt(KPIID),
                Month: Month,
                Mode: Mode
            },
            errorPolicy: 'all'
        });
    }

    updateEvaluationResult(KPIID, FormObjectItemResults, Month, Files, DelFiles, FormID, HasFile) {
        console.log(
            {
                KPIID: parseInt(KPIID),
                FormObjectItemResults: FormObjectItemResults,
                Month: Month,
                Files: Files,
                DelFiles: DelFiles,
                FormID: FormID,
                HasFile: HasFile
            }
        )
        const UPDATEEVALUATIONRESULT = `
            mutation UpdateEvaluationResult(
                $KPIID:Int!, 
                $FormObjectItemResults: [InputFormObjectItemResults], 
                $Month: String, 
                $Files: [Files], 
                $DelFiles: [DelFiles],
                $FormID: Int,
                $HasFile: Boolean
                ){
                UpdateEvaluationResult(
                    KPIID: $KPIID, 
                    FormObjectItemResults: $FormObjectItemResults, 
                    Month: $Month, 
                    Files:$Files, 
                    DelFiles:$DelFiles,
                    FormID: $FormID,
                    HasFile: $HasFile
                    ){
                    KPIID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: UPDATEEVALUATIONRESULT,
            variables: {
                KPIID: parseInt(KPIID),
                FormObjectItemResults: FormObjectItemResults,
                Month: Month,
                Files: Files,
                DelFiles: DelFiles,
                FormID: FormID,
                HasFile: HasFile
            }
        })
    }

    approveEvaluationResult(FormID, KPIID, date, SubmitStatus, RemarkReject) {
        RemarkReject = (!RemarkReject || RemarkReject === true) ? null : RemarkReject;
        const APPROVEEVALUATIONRESULT = `
            mutation AprroveEvaluationResult($FormID: Int!, $KPIID: Int!, $Date: String!, $SubmitStatus: Int!, $RemarkReject: String){
                ApproveEvaluationResult(FormID: $FormID, KPIID: $KPIID, Date: $Date, SubmitStatus: $SubmitStatus, RemarkReject: $RemarkReject) {
                    FormID
                }
            }
        `;

        return Axios.post('/pmqaservice', {
            query: APPROVEEVALUATIONRESULT,
            variables: {
                FormID: parseInt(FormID),
                KPIID: parseInt(KPIID),
                Date: date,
                SubmitStatus: parseInt(SubmitStatus),
                RemarkReject: RemarkReject
            },
            errorPolicy: 'all'
        });
    }

    uploadFile(KPIID, Month, files) {
        console.log(KPIID, Month, files);
        var formData = new FormData();
        for (var i of files) {
            console.log(i)
            if (!i.FileData || i.FileData === '')
                continue;
            formData.append('KPIID', KPIID);
            formData.append('Month', Month);
            formData.append('FileUpload', i.FileData);
            formData.append('Description', i.Description);
        }

        return Axios.post('/uploads', formData, { timeout: 0 });
    }

    getFile(KPIID, Month, FileName) {

        return Axios.post('/getFile', {
            KPIID: KPIID,
            Month: Month,
            FileName: FileName
        }, {
            responseType: 'blob',
            timeout: 0
        });
    }
}

export default new EvaluationResultService();